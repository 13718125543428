import { useEffect, useState } from "react";
import axios from "axios";

const useGetData = ({
  initialData,
  dataPromise = Promise.resolve(initialData),
  cancelMessage = "Provider Unmounted",
  getResponseData = (response) => response?.data?.data, // this is a common pattern
  dependencies = [],
}) => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(true);
  const [abortController, setAbortController] = useState(new AbortController());
  const [error, setError] = useState(null);

  let isMounted = true;
  useEffect(() => {
    const newAbortController = new AbortController();
    // cancel previous request if it exists
    abortController?.abort?.();
    setAbortController(newAbortController);
    setLoading(true);
    dataPromise({ signal: newAbortController?.signal })
      .then((response) => {
        const formattedData = getResponseData(response);
        if (isMounted) {
          setData(formattedData);
        }
      })
      .catch((e) => {
        setError(e);
        if (axios.isCancel(e)) {
          // continue regardless of error
          // console.debug(
          //   "useGetData request cancelled",
          //   dataPromise
          //     .toString()
          //     .replace(/(\n[\w\W]+)/, "")
          //     .replace(/[(){}=>\s]+/g, "")
          // );
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });

    return () => {
      abortController.abort(cancelMessage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isMounted = false;
    };
  }, dependencies);

  return { data, loading, abortController, error };
};

export default useGetData;
