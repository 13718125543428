import { useMemo } from "react";
import { useLocation as useOriginalLocation } from "react-router-dom";

// authors note: please remove this once ISSUE: https://github.com/ReactTraining/react-router/issues/7059 is fixed

export const useLocation = () => {
  const location = useOriginalLocation();
  const { pathname, search, hash, state, key } = location;
  return useMemo(() => {
    return { pathname, search, hash, state, key };
  }, [pathname, search, hash, state, key]);
};

// authors note: useHistory is actually stable so no stablization is required there

export default useLocation;
