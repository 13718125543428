import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import Route from "core/shared/presenter/Route";
import NoMatch from "core/shared/presenter/NoMatch";

const SmartSwitch = ({ children }) => {
  return (
    <Switch>
      {children}
      <Route component={NoMatch} />
    </Switch>
  );
};

SmartSwitch.propTypes = {
  children: PropTypes.node,
};

export default SmartSwitch;
