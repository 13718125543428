import { isEmpty } from "lodash";
import {
  FILTER_EQUAL_TO,
  SYMBOLS,
} from "core/pattern/filter/util/FilterHelper";

export default {
  ACTIVE: "active",
  SPEND_ISSUE: "spend_issue",
  PACING_NOSPEND: "pacing_nospend",
  PACING_VERY_LOW: "pacing_very_low",
  PACING_LOW: "pacing_low",
  PACING_ONTARGET: "pacing_ontarget",
  PACING_OVER: "pacing_over",
  CREATIVES: "creatives",
  DEALS: "deals",
  PIXELS: "pixels",
  TARGETING: "targeting",
  STRATEGY_SETUP: "strategySetup",
};

const SEARCH_EXACTLY = "exactly";
const SEARCH_CONTAIN = "contain";

export const FilterConstants = {
  SEARCH_EXACTLY,
  SEARCH_CONTAIN,
};

export const getFilterBy = (filters) => {
  if (isEmpty(filters)) {
    return undefined;
  }
  const filterBy = {};
  Object.keys(filters).forEach((key) => {
    filterBy[key] = {
      value: filters[key].value,
      type: filters[key].type,
      operator:
        filters[key].operator === FILTER_EQUAL_TO
          ? "=="
          : SYMBOLS[filters[key].operator],
    };
  });
  return JSON.stringify(filterBy);
};

export const getSearchBy = (searchValue, searchType) => {
  switch (searchType) {
    case SEARCH_CONTAIN:
      return { searchContains: searchValue };
    case SEARCH_EXACTLY:
      return { searchExact: searchValue };
    default:
      return {};
  }
};
