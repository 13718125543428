import React, { useEffect, useState, useRef } from "react";
import { IntlProvider } from "react-intl";
import PropTypes from "prop-types";
import { getSelectedLanguage } from "settings/util/localizationSettings";

const IntlWrapper = ({ children }) => {
  const selectedLanguage = getSelectedLanguage();
  const languageToUse = selectedLanguage || "en"; // || browserLanguage; // TODO Replace once translations are in
  const [messages, setMessages] = useState({});
  const isMounted = useRef(true);

  const handleInlError = (e) => {
    if (e.code === "MISSING_TRANSLATION") {
      // console.warn("Missing translation", e.message);
      return;
    }

    if (JSON.stringify(messages) !== "{}") {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    const fetchLocaleMessages = async () => {
      return import(`../../compiled-lang/${languageToUse}.json`);
    };
    fetchLocaleMessages().then((msg) => {
      if (isMounted.current) {
        setMessages(msg);
      }
    });
    return () => {
      isMounted.current = false;
    };
  }, [languageToUse]);

  return (
    <IntlProvider
      locale={languageToUse}
      defaultLocale="en"
      messages={messages}
      onError={handleInlError}
    >
      {children}
    </IntlProvider>
  );
};

IntlWrapper.propTypes = {
  children: PropTypes.node,
};

IntlWrapper.defaultProps = {
  children: null,
};

export default IntlWrapper;
