import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";

const FormPatternMessages = defineMessages({
  archived: {
    id: "form.pattern.archived",
    defaultMessage: "Archived",
  },
  create: {
    id: "form.pattern.create",
    defaultMessage: "Create",
  },
  save: {
    id: "form.pattern.save",
    defaultMessage: "Save",
  },
  saveWithQuantity: {
    id: "form.pattern.saveWithQuantity",
    defaultMessage:
      "{count, plural, =0 {Save} one {Save field} other {Save # fields}}",
    parameterized: true,
  },
  submit: {
    id: "form.pattern.submit",
    defaultMessage: "Submit",
  },
  cancel: {
    id: "form.pattern.cancel",
    defaultMessage: "Cancel",
  },
  splitButton: {
    id: "form.pattern.split.button",
    defaultMessage: "Split Button",
  },
  previous: {
    id: "form.pattern.previous",
    defaultMessage: "Previous",
  },
  next: {
    id: "form.pattern.next",
    defaultMessage: "Next",
  },
  upload: {
    id: "form.pattern.upload",
    defaultMessage: "Upload",
  },
  active: {
    id: "form.pattern.active",
    defaultMessage: "Active",
  },
  inactive: {
    id: "form.pattern.inactive",
    defaultMessage: "Inactive",
  },
  all: {
    id: "form.pattern.all",
    defaultMessage: "View All",
  },
  unsavedTitle: {
    id: "form.pattern.unsaved.title",
    defaultMessage: "Cancel",
  },
  unsavedContent: {
    id: "form.pattern.unsaved.content",
    defaultMessage:
      "You have unsaved changes, are you sure you want to navigate away?",
  },
  unsavedCancel: {
    id: "deal.createedit.unsaved.cancel",
    defaultMessage: "Keep Editing",
  },
  unsavedConfirm: {
    id: "deal.createedit.unsaved.confirm",
    defaultMessage: "Yes, Cancel",
  },
  errorMessage: {
    id: "form.pattern.errorMessage",
    defaultMessage: "Something went wrong",
  },
  successMessage: {
    id: "form.pattern.successMessage",
    defaultMessage: "Submitted Successfully",
  },
  enabled: {
    id: "form.pattern.enabled",
    defaultMessage: "Enabled",
  },
  disabled: {
    id: "form.pattern.disabled",
    defaultMessage: "Disabled",
  },
  internalServerError: {
    id: "form.pattern.internalServerError",
    defaultMessage: "There was a problem while saving. Please try again.",
  },
  badRequestError: {
    id: "form.pattern.badRequestError",
    defaultMessage:
      "Cannot save due to an issue with the platform. Please contact Support for more details.",
  },
  optional: {
    id: "form.pattern.optional",
    defaultMessage: " (Optional)",
  },
});

i18nFormat(FormPatternMessages);

export default FormPatternMessages;
