import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";

const AppMessages = defineMessages({
  applySelections: {
    id: "app.header.apply.selections",
    defaultMessage: "Apply Selections",
  },
  clearSelections: {
    id: "app.header.clear.selections",
    defaultMessage: "Clear Selections",
  },
  logout: {
    id: "app.header.logout",
    defaultMessage: "Logout",
  },
  idLookup: {
    id: "app.header.idlookup",
    defaultMessage: "Lookup IDs",
  },
  support: {
    id: "app.header.support",
    defaultMessage: "Contact Support",
  },
  mmAcademy: {
    id: "app.header.mmacademy",
    defaultMessage: "MediaMath Academy",
  },
  kb: {
    id: "app.header.kb",
    defaultMessage: "Knowledge Base",
  },
  userTimezone: {
    id: "app.header.user.timezone",
    defaultMessage: "User Time Zone",
  },
  userTimezoneTooltip: {
    id: "app.header.user.timezone.tooltip",
    defaultMessage:
      "Due to time zone differences, data may vary from user to user.",
  },
  organization: {
    id: "app.account.selector.organization",
    defaultMessage: "Organization",
  },
  agency: {
    id: "app.account.selector.agency",
    defaultMessage: "Agency",
  },
  noAgency: {
    id: "app.account.selector.no.agency",
    defaultMessage: "No agencies",
  },
  noAgenciesFound: {
    id: "app.account.selector.search.no.agencies",
    defaultMessage: "No agencies found",
  },
  advertiser: {
    id: "app.account.selector.advertiser",
    defaultMessage: "Advertiser",
  },
  noAdvertisers: {
    id: "app.account.selector.no.advertisers",
    defaultMessage: "No advertisers",
  },
  searchPlaceholder: {
    id: "app.account.selector.search.placeholder",
    defaultMessage: "Search organization, agency, or advertiser by name or id",
  },
  noOrganizationsFound: {
    id: "app.account.selector.search.no.organizations",
    defaultMessage: "No organizations found",
  },
  noAdvertisersFound: {
    id: "app.account.selector.search.no.advertisers",
    defaultMessage: "No advertisers found",
  },
  all: {
    id: "app.account.selector.search.all",
    defaultMessage: "ALL",
  },
  noMatch: {
    id: "app.account.selector.search.no.match",
    defaultMessage: "No match found",
  },
  selectionRequired: {
    id: "app.account.selector.selection.required",
    defaultMessage:
      "Please select an Agency or Advertiser before applying the selections",
  },
  beta: {
    id: "app.nav.beta.label",
    defaultMessage: "beta",
  },
  poc: {
    id: "app.nav.poc.label",
    defaultMessage: "poc",
  },
  legacy: {
    id: "app.nav.legacy.label",
    defaultMessage: "legacy",
  },
  lightTheme: {
    id: "app.nav.light.theme",
    defaultMessage: "Light Theme",
  },
  darkTheme: {
    id: "app.nav.dark.theme",
    defaultMessage: "Dark Theme",
  },
  chooseDate: {
    id: "app.date.choose.date",
    defaultMessage: "Choose Date",
  },
  fromDate: {
    id: "app.date.from.date.placeholder",
    defaultMessage: "From",
  },
  toDate: {
    id: "app.date.to.date.placeholder",
    defaultMessage: "To",
  },
  noDateSet: {
    id: "app.date.no.date.set",
    defaultMessage: "No date set",
  },
  am: {
    id: "app.time.am",
    defaultMessage: "AM",
  },
  pm: {
    id: "app.time.pm",
    defaultMessage: "PM",
  },
  legacyPlatform: {
    id: "app.header.legacyplatform",
    defaultMessage: "Legacy Platform",
  },
  disabled: {
    id: "app.header.disabled",
    defaultMessage:
      "The Account Selector is disabled when editing any entity that is specific to an organization, agency, or advertiser.",
  },
});

i18nFormat(AppMessages);

export default AppMessages;
