import { defineMessages } from "react-intl";

const AudienceMessages = defineMessages({
  AudiencesHeader: {
    id: "audience.header",
    defaultMessage: "Audiences",
  },
  OrganizationInfoMessage: {
    id: "audience.firstpartydata.orgMessage",
    defaultMessage: `Data shown only for "{ organizationName }".`,
  },
  AudiencesTabFirstPartyData: {
    id: "audience.tab.firstPartyTabLink",
    defaultMessage: "1st Party",
  },
  FirstPartyDataIdHeader: {
    id: "audience.firstpartydata.list.id",
    defaultMessage: "ID",
  },
  FirstPartyDataNameHeader: {
    id: "audience.firstpartydata.list.name",
    defaultMessage: "Name",
  },
  FirstPartyDataLoadsHeader: {
    id: "audience.firstpartydata.list.loads",
    defaultMessage: "Loads YDY",
  },
  FirstPartyDataUniquesHeader: {
    id: "audience.firstpartydata.list.uniques",
    defaultMessage: "Uniques YDY",
  },
  FirstPartyDataDateModifiedHeader: {
    id: "audience.firstpartydata.list.datemodified",
    defaultMessage: "Date Modified",
  },
  FirstPartyDataStrategiesTargeted: {
    id: "audience.firstpartydata.list.strategiestargeted",
    defaultMessage: "Strategies Targeted",
  },
  ViewAttributes: {
    id: "audience.firstpartydata.list.viewattributes",
    defaultMessage: "View Attributes",
  },
  AttributesSidebarHeader: {
    id: "audience.attributedata.healthStatus.sidebarHeader",
    defaultMessage: "Web Event Attributes",
  },
  AttributesAdvertiserId: {
    id: "audience.attributes.name.advertiserId",
    defaultMessage: "Advertiser ID",
  },
  AttributesTotalLoads: {
    id: "audience.attributes.name.totalLoads",
    defaultMessage: "Total Loads",
  },
  AttributesNameHeader: {
    id: "audience.attributes.list.name",
    defaultMessage: "Attribute",
  },
  AttributesLoadsHeader: {
    id: "audience.attributes.list.estimatedLoads",
    defaultMessage: "Estimated Loads",
  },
  AttributesTotalHeader: {
    id: "audience.attributes.list.total",
    defaultMessage: "% Total",
  },
  AttributesDataTypeHeader: {
    id: "audience.attributes.list.datatype",
    defaultMessage: "Data Type",
  },
});

export default AudienceMessages;
