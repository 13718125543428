// NOTE: flopflip library converts - to camel casing which is super annoying so polaris-test would read as polarisTest
export default {
  ACCOUNT_SELECTOR_CONFIG: "accountSelectorConfig",
  ACCOUNT_SELECTOR: "accountSelector",
  ADMIN_MODULE_COMPASS: "adminModuleCompass",
  ADMIN_MODULE: "adminModule",
  AUDIENCE_MODULE_COMPASS: "audiencesModuleCompass",
  AUDIENCE_MODULE: "audiencesModule",
  CAMPAIGNS_MODULE_COMPASS: "campaignsModuleCompass",
  CAMPAIGNS_MODULE: "campaignsModule",
  CREATIVES_MODULE_COMPASS: "creativesModuleCompass",
  CREATIVES_MODULE: "creativesModule",
  DARK_MODE: "darkMode",
  ENTERPRISE_CONTROLS_MODULE: "enterpriseControlsModule",
  LEGACY_PLATFORM: "legacyPlatform",
  MEDIA_MODULE_COMPASS: "mediaModuleCompass",
  PLANS_MODULE_V2: "plansV2",
  PLANS_MODULE: "plansModule",
  REPORTS_MODULE_POC: "reportsModulePoc",
  REPORTS: "reportsModuleCompass",
  SIDE_NAV: "sideNav",
};
