import { defineMessages } from "react-intl";

const SectionNameMessages = defineMessages({
  appstore: {
    id: "app.appstore.name",
    defaultMessage: "App Store",
  },
  inventory: {
    id: "app.inventory.name",
    defaultMessage: "Inventory",
  },
  manage: {
    id: "app.manage.name",
    defaultMessage: "Manage",
  },
  bulkManage: {
    id: "app.bulkmanage.name",
    defaultMessage: "Bulk Manage",
  },
  reports: {
    id: "app.reports.name",
    defaultMessage: "Reports",
  },
  creatives: {
    id: "app.creatives.name",
    defaultMessage: "Creatives",
  },
  creativesVideo: {
    id: "app.creatives.video.name",
    defaultMessage: "Creatives",
  },
  audiences: {
    id: "app.audiences.name",
    defaultMessage: "Audiences",
  },
  campaigns: {
    id: "app.campaigns.name",
    defaultMessage: "Campaigns",
  },
  enterprise_controls: {
    id: "app.enterprise_controls.name",
    defaultMessage: "Enterprise Controls",
  },
  plans: {
    id: "app.plans.name",
    defaultMessage: "Plans",
  },
  plansV2: {
    id: "app.plans.v2.name",
    defaultMessage: "Plans (v2)",
  },
  admin: {
    id: "app.admin.name",
    defaultMessage: "Admin",
  },
  appStore: {
    id: "app.appstore.name.nospace",
    defaultMessage: "AppStore",
  },
  troubleshooting: {
    id: "app.troubleshooting.name",
    defaultMessage: "Troubleshooting",
  },
  settings: {
    id: "app.settings.name",
    defaultMessage: "Settings",
  },
  help: {
    id: "app.help.name",
    defaultMessage: "Help & Support",
  },
});

export default SectionNameMessages;
