import { create } from "zustand";
import produce from "immer";
import diff from "variable-diff";

const colorMap = {
  modified: "background: #EFF0C2",
  added: "background: #C6F0C2",
  removed: "background: #F0C2C2",
};

export const log = (config) => (set, get, api) => {
  // chance the flag(on web console) to TRUE, if you want to see the zustand logs in development environment
  window.showZustandLogs = false;
  return config(
    (args) => {
      const old = get();
      set(args);
      if (process.env.NODE_ENV === "development" && window.showZustandLogs) {
        const colors = [];
        const d = diff(old, get(), {
          color: true,
          wrap: (type, text) => {
            colors.push(colorMap[type]);
            colors.push("background:white");
            return `%c${text}%c`;
          },
        });
        // eslint-disable-next-line no-console
        console.group("[state]");
        if (d.changed) {
          // eslint-disable-next-line no-console
          console.log(d.text, ...colors);
        } else {
          // eslint-disable-next-line no-console
          console.log("no change");
        }
        // eslint-disable-next-line no-console
        console.groupEnd();
      }
    },
    get,
    api
  );
};

// Turn the set method into an immer proxy
export const immer = (config) => (set, get, api) =>
  config((fn) => set(produce(fn)), get, api);

export const createStoreHook = (config) => create(immer(log(config)));

export default createStoreHook;
