import React from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { API_URL } from "core/shared/presenter/AuthProvider";
import permissionsPerResource from "core/shared/util/permissions.json";
import { userRole } from "core/shared/util/users";

export const UserPermissionsContext = React.createContext({});

const roleIdMap = {
  // 1: "globalAdmin",
  // 2: "admin",
  // 3: "manager",
  // 4: "reporter",
  // 5: "internal",
  // 6: "agency",
  7: "editDataDefinition",
  8: "viewDataDefinition",
  9: "editSegments",
  10: "viewSegments",
  11: "editCampaigns",
  12: "accessInternalFees",
  13: "editMarginsAndPerformance",
  14: "viewOrganizations",
  15: "viewDmpReports",
  16: "manageContracts",
  17: "viewContracts",
};

// By default all permissions set to false
const initialSettings = Object.values(roleIdMap).reduce((map, permission) => {
  // eslint-disable-next-line no-param-reassign
  map[permission] = false;
  return map;
}, {});

function buildResourcesPermissions({ scope, type, role, id, settings }) {
  const perms = permissionsPerResource;
  if (scope && type && role && id) {
    const defaultPermissions = Object.assign(
      {
        defaultValues: {
          userObj: {},
          organization: {},
          contract: {},
          siteList: {},
        },
      },
      perms[`${scope}-${type}-${role}`]
    );

    defaultPermissions.defaultValues.userObj = { scope, type, role, id };

    defaultPermissions.defaultValues.organization.view =
      settings.viewOrganizations === true;

    defaultPermissions.defaultValues.contract.view =
      (role !== userRole.reporter && settings.viewOrganizations) ||
      // Contract Manage (id=16) - allows users full access to view, edit, and create contracts, regardless of the user's Adama role (i.e., Admin, Manager, Reporter) or the status of their view_organizations setting
      // Contract View (id=17) - allows users to view contracts, regardless of the user's Adama permissions/settings
      settings.manageContracts ||
      settings.viewContracts;
    defaultPermissions.defaultValues.contract.edit =
      defaultPermissions.defaultValues.contract.edit ||
      settings.manageContracts;
    defaultPermissions.defaultValues.contract.create =
      defaultPermissions.defaultValues.contract.create ||
      settings.manageContracts;

    defaultPermissions.defaultValues.siteList.create =
      settings.editCampaigns === true;

    return defaultPermissions.defaultValues;
  }
  return {};
}

/**
   Construct the settings object from the list of roles e.g.
   {
      "editDataDefinition": true,
      "viewDataDefinition": true,
      "editSegments": true,
      "viewSegments": true,
      "editCampaigns": true,
      "accessInternalFees": false,
      "editMarginsAndPerformance": true,
      "viewOrganizations": true,
      "viewDmpReports": true,
      "manageContracts": false,
      "viewContracts": false
  }
 */
function getSettings(roles = []) {
  const settings = { ...initialSettings };
  roles.forEach((roleId) => {
    if (roleIdMap[roleId]) {
      settings[roleIdMap[roleId]] = true;
    }
  });
  return settings;
}

const UserPermissionsProvider = ({ children }) => {
  const { user } = useAuth0();
  const { type, scope, roles, role, id } = user?.[API_URL] || {};
  const settings = getSettings(roles);
  const permissions = buildResourcesPermissions({
    scope,
    type,
    role,
    id,
    settings,
  });

  return (
    <UserPermissionsContext.Provider value={permissions}>
      {children}
    </UserPermissionsContext.Provider>
  );
};
UserPermissionsProvider.propTypes = {
  children: PropTypes.node,
};
UserPermissionsProvider.defaultProps = {
  children: null,
};

export default UserPermissionsProvider;
