import React from "react";
import styled from "@emotion/styled";
import {
  StFlexDiv,
  StDiv,
  Button,
  StHeadline,
  StMarginDiv,
} from "@mediamath/design-system-eng";
import { useHistory } from "react-router-dom";
import useFormMessages from "core/pattern/form-hooks/hooks/useFormMessages";
import AccessDeniedMessages from "core/shared/intl/AccessDeniedMessages";

const AccessDeniedError = () => {
  const history = useHistory();
  const { msgs } = useFormMessages(AccessDeniedMessages);

  const StError = styled(StFlexDiv)`
    align-items: center;
    justify-content: center;
  `;

  return (
    <StError>
      <StDiv>
        <StHeadline>{msgs.title}</StHeadline>
        <StMarginDiv>{msgs.text1}</StMarginDiv>
        <StMarginDiv>{msgs.text2}</StMarginDiv>
        <StMarginDiv>
          <Button onClick={() => history.goBack()}>{msgs.back}</Button>
        </StMarginDiv>
      </StDiv>
    </StError>
  );
};

export default AccessDeniedError;
