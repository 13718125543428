import React, { useEffect, useRef } from "react";
import { get } from "lodash";
import { ConfigureFlopFlip } from "@flopflip/react-broadcast";
import adapter from "@flopflip/launchdarkly-adapter";
import { useAuth0 } from "@auth0/auth0-react";

import PropTypes from "prop-types";
import { Loader } from "@mediamath/design-system-eng";
import isProd from "core/shared/util/getIsProd";
import { useAccountSelectorEntities } from "core/app/hooks/useAccountSelectorEntities";

const CLIENT_ID = isProd()
  ? "5ad652d01569692cbd401a8c"
  : "5ad652d01569692cbd401a8b";

export const getPath = (key) => `https://api.mediamath.com/${key}`;

export const mapUserToLD = (user, selectedEntities) => {
  const sub = get(user, "sub"); // ad hash
  const key = get(user, getPath("user_id"), sub); // default to sub if no id
  const email = get(user, "email");
  const fname = get(user, "given_name");
  const lname = get(user, "family_name");
  const nickname = get(user, "nickname");
  const name = fname && lname ? `${fname} ${lname}` : nickname;
  const permissionScope = get(user, [getPath("t1"), "scope"]);
  const permissionRole = get(user, [getPath("t1"), "role"]);
  const permissionType = get(user, [getPath("t1"), "type"]);
  const { organizationId, agencyId, advertiserId } = selectedEntities;

  return {
    key,
    name,
    email,
    custom: {
      permissionScope,
      permissionRole,
      permissionType,
      organizationId: organizationId ? String(organizationId) : null,
      agencyId: agencyId ? String(agencyId) : null,
      advertiserId: advertiserId ? String(advertiserId) : null,
    },
  };
};

// TODO: (dlasky) rename+move this since its not really a container anymore
const FeatureFlagContainer = (props) => {
  const { user: authUser, isLoading } = useAuth0();
  const { children } = props;
  const {
    advertiserId,
    agencyId,
    organizationId,
  } = useAccountSelectorEntities();

  const configurationStatusRef = useRef(false);

  useEffect(() => {
    const user = mapUserToLD(authUser, {
      advertiserId,
      agencyId,
      organizationId,
    });

    if (configurationStatusRef.current) {
      adapter.reconfigure({ user });
    }

    return () => {
      configurationStatusRef.current = false;
    };
  }, [advertiserId, agencyId, organizationId, authUser]);

  if (isLoading) {
    return <Loader active />;
  }

  if (authUser) {
    return (
      <ConfigureFlopFlip
        adapter={adapter}
        adapterArgs={{
          sdk: { clientSideId: CLIENT_ID },
          user: mapUserToLD(authUser, {
            advertiserId,
            agencyId,
            organizationId,
          }),
        }}
      >
        {({ isAdapterConfigured }) => {
          configurationStatusRef.current = isAdapterConfigured;
          return isAdapterConfigured ? children : <Loader active />;
        }}
      </ConfigureFlopFlip>
    );
  }
  return children;
};

FeatureFlagContainer.defaultProps = {
  user: {},
  children: null,
};

FeatureFlagContainer.propTypes = {
  user: PropTypes.shape({}),
  children: PropTypes.node,
};

export default FeatureFlagContainer;
