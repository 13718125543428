const CreativesConstants = {
  AD_SERVER_MEDIAMATH: "MediaMath",
  ACTIVE_STATUS: "active",
  INACTIVE_STATUS: "inactive",
  CHANNEL_TYPE: {
    DISPLAY: "Display",
    VIDEO: "Video",
    AUDIO: "Audio",
    NATIVE: "Native",
  },
  MAXGIGABYTESINBYTES: 1073741824,
  FOURGIGABYTESINBYTES: 4294967296,
  MAXMEGABYTESINBYTES: 110000,
  MAXDISPLAYKILOBYTESINBYTES: 500000,
  SIDE_PANEL_MIN_WIDTH: 100,
  SIDE_PANEL_CONTENT_WITH: 800,
  LIMIT_ROWS_SELECTED: 100,
  DEFAULT_DATE_FORMAT: "yyyy-LL-dd",
  HOUR_MINUTE_SECOND_DATEFORMAT: "HH:mm:ss",
  TIME_WINDOW_LAST7: "last_7_days",
  TIME_WINDOW_LAST30: "last_30_days",
  TIME_WINDOW_CUSTOM: "custom",
  CHANGELOG_DATEFORMAT: "LL-dd-yyyy",
  CHANGE_LOG_FIELDS: {
    ADD: "add",
    UPDATE: "update",
    DELETE: "delete",
    NAME: "name",
    AD_FORMAT: "ad_format",
    GROUP_ID: "concept_id",
    CUSTOM_VAST: "custom_vast",
    CUSTOM_VAST_URL: "custom_vast_url",
    TRACKING_PIXELS: "tracking_pixels",
    THREE_PAS_ID: "external_identifier",
    START_DATE: "start_date",
    END_DATE: "end_date",
    STATUS: "status",
    CLOCK_NUMBER: "clock_number",
    LANDING_PAGE_URL: "landing_page",
    CLICKTHROUGH_URL: "clickthrough_url",
  },
  VAST_TYPE_URL: "URL",
  VAST_TYPE_XML: "XML",
  DISPLAY_DIMENSIONS: {
    DEFAULT: "default_dimension",
    CUSTOM: "custom_dimension",
  },
  DISPLAY_AD_FORMAT: {
    DISPLAY: "Display",
    MOBILE: "Mobile",
    EXPANDABLE: "Expandable",
  },
  EXPANSION_TRIGGER: {
    AUTOMATIC: "Automatic",
    MOUSEOVER: "Mouseover",
    CLICK: "Click",
  },
  EXPANSION_DIRECTION: {
    L: "Left",
    R: "Right",
    U: "Up",
    D: "Down",
    LU: "UpLeft",
    RU: "UpRight",
    LD: "DownLeft",
    RD: "DownRight",
  },
  DISPLAY_AD_TAG_TYPE: {
    JAVASCRIPT: "Javascript",
    IFRAME: "Iframe",
    IMAGE: "Image",
  },
};

export default CreativesConstants;
