import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";

const StrategyMessages = defineMessages({
  NoStrategies: {
    id: "campaign.troubleshooting.strategies.noactive",
    defaultMessage: "No strategies match what you are looking for",
  },
  NotRunning: {
    id: "campaign.troubleshooting.strategies.notrunning",
    defaultMessage: "Not Live",
  },
  NoStrategiesFiltered: {
    id: "campaign.troubleshooting.strategies.noactivefilter",
    defaultMessage: "No strategies meet your filter criteria",
  },
  NoAdvertiser: {
    id: "campaign.troubleshooting.strategies.noAdvertiser",
    defaultMessage: "Advertiser not loaded",
  },
  NoCampaign: {
    id: "campaign.troubleshooting.strategies.noCampaign",
    defaultMessage: "Campaign not loaded",
  },
  StrategyIdHeader: {
    id: "campaign.troubleshooting.strategies.advertiserId",
    defaultMessage: "ID",
  },
  StrategyAdvertiserHeader: {
    id: "campaign.troubleshooting.strategies.advertiserName",
    defaultMessage: "ADVERTISER",
  },
  StrategyCampaignHeader: {
    id: "campaign.troubleshooting.strategies.campaignName",
    defaultMessage: "CAMPAIGN",
  },
  StrategyIssuesHeader: {
    id: "campaign.troubleshooting.strategies.issuesHeader",
    defaultMessage: "Strategy Issues",
  },
  StrategiesHeader: {
    id: "campaign.troubleshooting.strategies.strategiesHeader",
    defaultMessage: "Strategies",
  },
  StrategyNameHeader: {
    id: "campaign.troubleshooting.strategies.strategyName",
    defaultMessage: "Strategy Name",
  },
  HealthCheckHeader: {
    id: "campaign.troubleshooting.strategies.healthCheck",
    defaultMessage: "Health Check",
  },
  DaysLeftHeader: {
    id: "campaign.troubleshooting.strategies.daysLeft",
    defaultMessage: "Days Left",
  },
  SpendYesterdayHeader: {
    id: "campaign.troubleshooting.strategies.spendYesterday",
    defaultMessage: "Spend Yesterday",
  },
  FrequencyOptimizationHeader: {
    id: "campaign.troubleshooting.strategies.header.frequencyAmount",
    defaultMessage: "Frequency Cap",
  },
  FrequencyTypeHeader: {
    id: "campaign.troubleshooting.strategies.frequencyType",
    defaultMessage: "Frequency Type",
  },
  FrequencyAmountHeader: {
    id: "campaign.troubleshooting.strategies.frequencyAmount",
    defaultMessage: "Frequency Amount",
  },
  FrequencyIntervalHeader: {
    id: "campaign.troubleshooting.strategies.frequencyInterval",
    defaultMessage: "Frequency Interval",
  },
  CampaignRow: {
    id: "campaign.strategies.campaignRow",
    defaultMessage: "CAMPAIGN",
  },
  FrequencyType_even: {
    id: "campaign.troubleshooting.strategies.frequencyType.even",
    defaultMessage: "Even",
  },
  FrequencyInterval_na: {
    id: "campaign.troubleshooting.strategies.frequencyInterval.na",
    defaultMessage: "N/A",
  },
  FrequencyType_asap: {
    id: "campaign.troubleshooting.strategies.frequencyType.asap",
    defaultMessage: "ASAP",
  },
  FrequencyType_nolimit: {
    id: "campaign.troubleshooting.strategies.frequencyType.nolimit",
    defaultMessage: "No Cap",
  },
  FrequencyInterval_day: {
    id: "campaign.troubleshooting.strategies.frequencyInterval.day",
    defaultMessage: "Day",
  },
  FrequencyInterval_hour: {
    id: "campaign.troubleshooting.strategies.frequencyInterval.hour",
    defaultMessage: "Hour",
  },
  FrequencyInterval_week: {
    id: "campaign.troubleshooting.strategies.frequencyInterval.week",
    defaultMessage: "7 Days",
  },
  FrequencyInterval_month: {
    id: "campaign.troubleshooting.strategies.frequencyInterval.month",
    defaultMessage: "30 Days",
  },
  FrequencyOptimization_on: {
    id: "campaign.troubleshooting.strategies.frequencyOptimization.on",
    defaultMessage: "T1-Optimized",
  },
  PacingRatioHeader: {
    id: "campaign.troubleshooting.strategies.pacingRatio",
    defaultMessage: "Pacing Ratio",
  },
  PacingAt: {
    id: "campaign.strategies.popup.pacingat",
    defaultMessage: "Pacing at {value}",
    parameterized: true,
  },
  PacingAmountOf: {
    id: "campaign.strategies.popup.pacingamountof",
    defaultMessage: "Pacing Amount of {value}",
    parameterized: true,
  },
  Underspend: {
    id: "campaign.strategies.popup.underspend",
    defaultMessage: "Underspend ",
  },
  Overspend: {
    id: "campaign.strategies.popup.overspend",
    defaultMessage: "Overspend ",
  },
  PacingTypeHeader: {
    id: "campaign.troubleshooting.strategies.pacingType",
    defaultMessage: "Pacing Type",
  },
  PacingAmountHeader: {
    id: "campaign.troubleshooting.strategies.pacingAmount",
    defaultMessage: "Pacing Amount",
  },
  PacingIntervalHeader: {
    id: "campaign.troubleshooting.strategies.pacingInterval",
    defaultMessage: "Pacing Interval",
  },
  PacingType_even: {
    id: "campaign.troubleshooting.strategies.pacingType.even",
    defaultMessage: "Even",
  },
  PacingType_asap: {
    id: "campaign.troubleshooting.strategies.pacingType.asap",
    defaultMessage: "ASAP",
  },
  PacingInterval_day: {
    id: "campaign.troubleshooting.strategies.pacingInterval.day",
    defaultMessage: "Day",
  },
  PacingInterval_hour: {
    id: "campaign.troubleshooting.strategies.pacingInterval.hour",
    defaultMessage: "Hour",
  },
  RecommendedPacingAmountHeader: {
    id: "campaign.troubleshooting.strategies.RecommendedPacingAmount",
    defaultMessage: "Recommended Pacing Amount",
  },
  HealthStatusIssueType_creatives: {
    id: "campaign.troubleshooting.strategies.healthStatus.issueTypeCreatives",
    defaultMessage: "Creatives",
  },
  HealthStatusIssueType_deals: {
    id: "campaign.troubleshooting.strategies.healthStatus.issueTypeDeals",
    defaultMessage: "Deals",
  },
  HealthStatusIssueType_targeting: {
    id: "campaign.troubleshooting.strategies.healthStatus.issueTypeTargeting",
    defaultMessage: "Targeting",
  },
  HealthStatusIssueType_pixels: {
    id: "campaign.troubleshooting.strategies.healthStatus.issueTypePixels",
    defaultMessage: "Pixels",
  },
  HealthStatusIssueType_strategySetup: {
    id:
      "campaign.troubleshooting.strategies.healthStatus.issueTypeStrategySetup",
    defaultMessage: "Setup",
  },
  HealthStatusIssueType_unknown: {
    id: "campaign.troubleshooting.strategies.healthStatus.issueTypeUnknown",
    defaultMessage: "Unknown type",
  },
  HealthStatusSidebarHeader: {
    id: "campaign.troubleshooting.strategies.healthStatus.sidebarHeader",
    defaultMessage: "Strategy Health Issues",
  },
  HealthStatusIssuesCount: {
    id: "campaign.troubleshooting.strategies.healthStatus.issuesCount",
    defaultMessage:
      "{issuesCount, number} {issuesCount, plural, one { issue } other { issues }}",
    parameterized: true,
  },
  HealthStatusStrategiesAffected: {
    id: "campaign.troubleshooting.strategies.healthStatus.strategiesAffected",
    defaultMessage:
      "{strategiesAffected, number} {strategiesAffected, plural, one { strategy } other { strategies }} affected",
    parameterized: true,
  },
  HealthStatusDealName: {
    id: "campaign.troubleshooting.strategies.healthStatus.dealName",
    defaultMessage: "Name:",
  },
  HealthStatusDealId: {
    id: "campaign.troubleshooting.strategies.healthStatus.dealId",
    defaultMessage: "ID:",
  },
  HealthStatusDealLink: {
    id: "campaign.troubleshooting.strategies.healthStatus.dealLink",
    defaultMessage: "Jump to the deal detail page",
  },
  HealthStatusMultipleIdsAffected: {
    id: "campaign.troubleshooting.strategies.healthStatus.multipleIdsAffected",
    defaultMessage: "Multiple IDs",
  },
  HealthStatusMultipleImpacts: {
    id: "campaign.troubleshooting.strategies.healthStatus.multipleImpacts",
    defaultMessage: "Multiple { issueTypeMsg } ({ numOfIssues, number })",
  },
  HealthStatusImpactsTooltip: {
    id: "campaign.troubleshooting.strategies.healthStatus.impactsTooltip",
    defaultMessage: "Open { issueTypeMsg } in new tab",
  },
  HealthCheckIconTooltip: {
    id: "campaign.troubleshooting.strategies.healthcheckIcon.tooltip",
    defaultMessage: "View all issues",
  },
  GoalValueHeader: {
    id: "campaign.troubleshooting.strategies.list.goalBidPrice",
    defaultMessage: "Goal/ Bid Price",
  },
  GoalActualHeader: {
    id: "campaign.troubleshooting.strategies.list.goalactual",
    defaultMessage: "Goal Actual",
  },
  GoalActualHeaderFTD: {
    id: "campaign.troubleshooting.strategies.list.goalactualftd",
    defaultMessage: "Goal Actual (FTD)",
  },
  GoalTypeHeader: {
    id: "campaign.troubleshooting.strategies.list.goaltype",
    defaultMessage: "Goal Type",
  },
  InlineEditSuccess: {
    id: "campaign.troubleshooting.strategies.inline-edit.success",
    defaultMessage: "Successfully Updated",
  },
  ImpressionPacing: {
    id: "strategy.impressionPacing",
    defaultMessage: "Impression Pacing",
  },
  ImpressionPacingAmountMinError: {
    id: "strategy.inlineEdit.ImpressionPacingAmountMinError",
    defaultMessage:
      "Impression Pacing Amount must be greater than or equal to 1",
  },
  ImpressionPacingAmountMaxError: {
    id: "strategy.inlineEdit.ImpressionPacingAmountMaxError",
    defaultMessage:
      "Impression Pacing Amount must be less than or equal to 99B",
  },
  ImpressionPacingType_even: {
    id: "strategy.impressionPacingType.even",
    defaultMessage: "Even",
  },
  ImpressionPacingType_asap: {
    id: "strategy.impressionPacingType.asap",
    defaultMessage: "ASAP",
  },
  ImpressionPacingTypeNoLimit: {
    id: "strategy.impressionPacingType.nolimit",
    defaultMessage: "No Cap",
  },
  ImpressionPacingInterval_day: {
    id: "strategy.impressionPacingInterval.day",
    defaultMessage: "Day",
  },
  ImpressionPacingInterval_hour: {
    id: "strategy.impressionPacingInterval.hour",
    defaultMessage: "Hour",
  },
  MaxBidHeader: {
    id: "campaign.troubleshooting.strategies.list.maxbid",
    defaultMessage: "Max Bid",
  },
  MinBidHeader: {
    id: "campaign.troubleshooting.strategies.list.minbid",
    defaultMessage: "Min Bid",
  },
  CampaignBudgetHeader: {
    id: "campaign.troubleshooting.strategies.cmpheader.budget",
    defaultMessage: "Campaign Budget",
  },
  SuddenSpikeTooltip: {
    id: "campaign.troubleshooting.strategies.tooltip.suddenspike",
    defaultMessage: "Sudden Spike in Spend",
  },
  RemainingBudgetHeader: {
    id: "campaign.troubleshooting.strategies.cmpheader.remainingbudget",
    defaultMessage: "Remaining Budget",
  },
  SpendToPaceHeader: {
    id: "campaign.troubleshooting.strategies.cmpheader.spendtopace",
    defaultMessage: "Spend To Pace",
  },
  DailyPacingCapHeader: {
    id: "campaign.troubleshooting.strategies.cmpheader.dailypacing",
    defaultMessage: "Daily Pacing Cap",
  },
  GoalVSActualHeader: {
    id: "campaign.troubleshooting.strategies.cmpheader.goalactual",
    defaultMessage: "Goal / Actual",
  },
  CampaignDatesHeader: {
    id: "campaign.troubleshooting.strategies.cmpheader.cmpdates",
    defaultMessage: "Campaign Start — End",
  },
  SuddenDropTooltip: {
    id: "campaign.troubleshooting.strategies.tooltip.suddendrop",
    defaultMessage: "Sudden Drop in Spend",
  },
  FlightDatesHeader: {
    id: "campaign.troubleshooting.strategies.cmpheader.flightdates",
    defaultMessage: "Flight",
  },
  FooterSave: {
    id: "campaign.troubleshooting.strategies.cmpfooter.save",
    defaultMessage: "Save",
  },
  FooterCancel: {
    id: "campaign.troubleshooting.strategies.cmpfooter.cancel",
    defaultMessage: "Cancel",
  },
  FooterSelected: {
    id: "campaign.troubleshooting.strategies.cmpfooter.selected",
    defaultMessage: "Selected",
  },
  FooterPacing: {
    id: "campaign.troubleshooting.strategies.cmpfooter.pacing",
    defaultMessage: "Pacing",
  },
  EditCampaigns: {
    id: "campaign.troubleshooting.strategies.cmpfooter.editStrategies",
    defaultMessage: "Edit Strategy Details",
  },
  OpenInTabs: {
    id: "campaign.troubleshooting.strategies.cmpfooter.openInTabs",
    defaultMessage: "Open in Tabs",
  },
  EditTargeting: {
    id: "campaign.bulk.edit.targeting",
    defaultMessage: "Edit Targeting",
  },
  FooterGroups: {
    id: "campaign.troubleshooting.strategies.cmpfooter.groups",
    defaultMessage: "Groups",
  },
  PopupNewGroup: {
    id: "campaign.troubleshooting.strategies.cmpfooter.newGroup",
    defaultMessage: "New Group",
  },
  PopupSave: {
    id: "campaign.troubleshooting.strategies.cmpfooter.save",
    defaultMessage: "Save",
  },
  PopupSearchGroup: {
    id: "campaign.troubleshooting.strategies.cmpfooter.searchGroup",
    defaultMessage: "Search Groups",
  },
  AddToStrategyGroupSuccess: {
    id:
      "campaign.troubleshooting.strategies.cmfooter.saveToExistingStrategyGroupSuccess",
    defaultMessage:
      "The selected strategies have been saved to an existing strategy group",
  },
  AddToStrategyGroupError: {
    id:
      "campaign.troubleshooting.strategies.cmfooter.saveToExistingStrategyGroupError",
    defaultMessage:
      "The selected strategies could not be saved. Please try again.",
  },
  TooltipCheckbox: {
    id: "campaign.troubleshooting.strategies.cmptooltip.checkbox",
    defaultMessage: "No more than 100 strategies can be selected",
  },
  FrequencyCapError: {
    id: "campaign.troubleshooting.strategies.frequencyerror.message",
    defaultMessage: "All fields are required",
  },
  FrequencyCapMinAmountError: {
    id:
      "campaign.troubleshooting.strategies.frequencycapminamounterror.message",
    defaultMessage: "Frequency Cap must be greater than 0",
  },
  FrequencyCapMaxAmountError: {
    id:
      "campaign.troubleshooting.strategies.frequencycapmaxamounterror.message",
    defaultMessage: "Frequency Cap value must be less than or equal to 9999999",
  },
  Campaign: {
    id: "campaign.tabs.campaign",
    defaultMessage: "Campaign",
  },
  AllStrategies: {
    id: "campaign.tabs.strategies",
    defaultMessage: `Strategies {strategiesCount}`,
    description: "campaigns top tab title",
    parameterized: true,
  },
  StrategyGroups: {
    id: "campaign.tabs.StrategyGroups",
    defaultMessage: "Strategy Groups {strategiesGroupsCount}",
    description: "campaigns top tab title",
    parameterized: true,
  },
  StartDateGridHeader: {
    id: "campaign.troubleshooting.strategies.startDate",
    defaultMessage: "Flight Start Date",
  },
  EndDateGridHeader: {
    id: "campaign.troubleshooting.strategies.endDate",
    defaultMessage: "Flight End Date",
  },
  StatusGridHeader: {
    id: "campaign.troubleshooting.strategies.status",
    defaultMessage: "Status",
  },
  StrategyStatusFilterArchived: {
    id: "campaign.troubleshooting.filter.statusArchived",
    defaultMessage: `Archived`,
  },
  StrategyStatusFilterActive: {
    id: "campaign.troubleshooting.filter.statusActive",
    defaultMessage: `Active`,
  },
  StrategyStatusFilterInactive: {
    id: "campaign.troubleshooting.filter.statusInactive",
    defaultMessage: `Inactive`,
  },
  StrategyStatusFilterRunning: {
    id: "campaign.troubleshooting.filter.statusRunning",
    defaultMessage: `Running`,
  },
  StrategyStatusFilterLive: {
    id: "campaign.troubleshooting.filter.statusLive",
    defaultMessage: `Live`,
  },
  StrategyStatusHeader: {
    id: "campaign.troubleshooting.list.campaignStatus",
    defaultMessage: "Status",
  },
  ProgressBarInitialMessage: {
    id: "campaign.troubleshooting.targeting.progressBarInitialMessage",
    defaultMessage:
      "Targeting details may take about 30 seconds. Please do not close this panel or switch tabs.",
  },
  ProgressBarMessage: {
    id: "campaign.troubleshooting.targeting.progressBarMessage",
    defaultMessage:
      "We apologize for the wait, please be patient and do not close this panel or switch tabs.",
  },
  NoChartsDataHeader: {
    id: "campaign.strategies.chart.noChartDataHeader",
    defaultMessage: "No data available",
  },
  NoChartDataWithName: {
    id: "campaign.strategies.chart.noChartData",
    defaultMessage: `No data is available for {name}`,
    parameterized: true,
  },
  ChartErrorWithName: {
    id: "campaign.strategies.chart.error",
    defaultMessage: `Something went wrong on our side. Please try charting {name} again.`,
    parameterized: true,
  },
  NoChartsData: {
    id: "campaign.strategies.chart.noChartsData",
    defaultMessage: "No data available for the following strategies",
  },
  NoChartsDataCombined: {
    id: "campaign.strategies.chart.noChartsDataCombined",
    defaultMessage: "No data available for the following",
  },
  BulkChartErrorHeader: {
    id: "campaign.strategies.chart.bulkChartErrorHeader",
    defaultMessage: "Something went wrong on our side.",
  },
  BulkChartError: {
    id: "campaign.strategies.chart.bulkChartError",
    defaultMessage: "Please try reselecting the following strategies",
  },
  BulkChartErrorCombined: {
    id: "campaign.strategies.chart.bulkChartErrorCombined",
    defaultMessage: "Please try reselecting the following",
  },
  BulkChartGeneralError: {
    id: "campaign.strategies.chart.chartError",
    defaultMessage:
      "Something went wrong on our side. Please try reselecting the strategy to chart again.",
  },
  ReportingCallInterrupted: {
    id: "campaign.strategies.chart.reportingCallInterrupted",
    defaultMessage: "Reporting call interrupted",
  },
  MaxStrategy: {
    id: "campaign.strategies.chart.maxStrategy",
    defaultMessage: "No more than 10 strategies can be charted",
  },
  PerformanceMetrics: {
    id: "campaign.strategies.chart.performanceMetrics",
    defaultMessage: "Performance Metrics",
  },
  BidMetrics: {
    id: "campaign.strategies.chart.bidMetrics",
    defaultMessage: "Bid Metrics",
  },
  AllCampaigns: {
    id: "campaign.strategies.breadcrumb.allCampaigns",
    defaultMessage: "All Campaigns",
  },
  ActualGoalPerformaceSevenDaysHeader: {
    id: "campaign.troubleshooting.strategies.list.SevenDays",
    defaultMessage: "Goal Actual (Last 7D)",
  },
  SumSelectedAmounts: {
    id: "campaign.strategies.dba.stgheader.sumSelectedAmounts",
    defaultMessage: "Pacing Sum",
  },
  PacingAmountDailyHeader: {
    id: "campaign.troubleshooting.strategies.pacingAmountDaily",
    defaultMessage: "Pacing Amount (Daily)",
  },
  SumCurrentAmounts: {
    id: "campaign.strategies.dba.sumCurrentAmounts",
    defaultMessage: "Sum of current amounts",
  },
  PreviousAmount: {
    id: "campaign.strategies.dba.previousAmount",
    defaultMessage: "Previous Amount",
  },
  CurrentAmount: {
    id: "campaign.strategies.dba.currentAmount",
    defaultMessage: "Current Amount",
  },
  LastDateUpdatedAmount: {
    id: "campaign.strategies.dba.lastDateUpdatedAmount",
    defaultMessage: "Pacing amounts last saved {date}",
    parameterized: true,
  },
  CannotBeEmpty: {
    id: "campaign.strategies.dba.cannotBeEmpty",
    defaultMessage: "Cannot be empty",
  },
  StrategyBulkUpdateTitle: {
    id: "campaign.strategies.bulk.title",
    defaultMessage:
      "Edit details for {rowsChecked, number} {rowsChecked, plural, one { strategy } other { strategies } }",
    parameterized: true,
  },
  StrategiesAddStrategy: {
    id: "campaign.strategies.add.strategy",
    defaultMessage: "New Strategy",
  },
  StrategiesAddStrategyBeta: {
    id: "campaign.strategies.add.strategybeta",
    defaultMessage: "New Strategy (Beta)",
  },
  StrategiesAddStrategyLegacy: {
    id: "campaign.strategies.add.strategylegacy",
    defaultMessage: "New Strategy (Legacy)",
  },
  RecommendationStatusTitle: {
    id: "campaign.strategies.recommendationStatus.title",
    defaultMessage: "Recommendation Status",
  },
  RecommendationStatusOn: {
    id: "campaign.strategies.recommendationStatus.on",
    defaultMessage: "On",
  },
  RecommendationStatusOff: {
    id: "campaign.strategies.recommendationStatus.off",
    defaultMessage: "Off",
  },
  RecommendationStatusCustom: {
    id: "campaign.strategies.recommendationStatus.customWithDate",
    defaultMessage: "Off until {afterDate}",
    parameterized: true,
  },
  RecommendationStatusOffUntil: {
    id: "campaign.strategies.recommendationStatus.custom",
    defaultMessage: "Off until",
  },
  RecommendationStatusOnAwaiting: {
    id: "campaign.strategies.recommendationStatus.onAwaiting",
    defaultMessage: "On (Awaiting Recommendation)",
  },
  SumRecommendationsOn: {
    id: "campaign.strategies.dba.stgheader.sumRecommendationsOn",
    defaultMessage: "Pacing Sum of Recommendations On",
  },
  SumRecommendationsOff: {
    id: "campaign.strategies.dba.stgheader.sumRecommendationsOff",
    defaultMessage: "Pacing Sum of Recommendations Off",
  },
  TotalSpend: {
    id: "campaign.strategies.TotalSpend",
    defaultMessage: "Total Spend",
  },
  DBAOnTooltip: {
    id: "campaign.strategies.dba.stgheader.dbaOnTooltip",
    defaultMessage:
      "Sum of all the strategy pacing amounts based on strategies that have DBA Recommendations On.",
  },
  DBAOffTooltip: {
    id: "campaign.strategies.dba.stgheader.dbaOffTooltip",
    defaultMessage:
      "Sum of all the strategy pacing amounts based on strategies that have DBA Recommendations Off.",
  },
  DailyPacingAuto: {
    id: "campaign.strategies.dba.stgheader.dailyPacingAuto",
    defaultMessage: "Auto",
  },
  DailyPacingManual: {
    id: "campaign.strategies.dba.stgheader.dailyPacingManual",
    defaultMessage: "(Manual)",
  },
  offUntilDBAOption: {
    id: "strategy.details.off.until.dba.option",
    defaultMessage: "Off until",
  },
  BudgetHeader: {
    id: `campaign.strategies.list.budget`,
    defaultMessage: "Strategy budget",
  },
  StrategyId: {
    id: `campaign.strategies.list.strategyId`,
    defaultMessage: "Strategy Id",
  },
  StrategySearch: {
    id: `campaign.strategies.search`,
    defaultMessage: "Strategy Search",
  },
  StrategySearchBreadcrumb: {
    id: `campaign.strategies.search.breadcrumb`,
    defaultMessage: 'Strategy {searchTerm} search results for "{searchVal}"',
    parameterized: true,
  },
  LoadingStrategiesMessage: {
    id: "campaign.troubleshooting.strategies.loading",
    defaultMessage:
      "Loading strategies... Please wait and do not exit this page.",
  },
  ProgressBarLateMessage: {
    id: "campaign.troubleshooting.strategies.ProgressBarLateMessage",
    defaultMessage: "We apologize for the wait, please be patient.",
  },
  CampaignId: {
    id: `campaign.strategies.list.campaignId`,
    defaultMessage: "Campaign Id",
  },
  CampaignName: {
    id: `campaign.strategies.list.campaignName`,
    defaultMessage: "Campaign Name",
  },
  addGeoTitle: {
    id: "campaign.strategies.bulk.add.geography",
    defaultMessage:
      "Add Geography to { count, number } {count, plural, one { strategy } other { strategies }}",
    parameterized: true,
  },
  removeGeoTitle: {
    id: "campaign.strategies.bulk.remove.geography",
    defaultMessage:
      "Remove Geography from { count, number } {count, plural, one { strategy } other { strategies }}",
    parameterized: true,
  },
  findReplaceGeoTitle: {
    id: "campaign.strategies.bulk.find.replace.geo",
    defaultMessage:
      "Find & Replace Geography for { count, number } {count, plural, one { strategy } other { strategies }}",
    parameterized: true,
  },
  overwriteGeoTitle: {
    id: "campaign.strategies.bulk.overwrite.geo",
    defaultMessage:
      "Overwrite Geography for { count, number } {count, plural, one { strategy } other { strategies }}",
    parameterized: true,
  },
  addCreativesTitle: {
    id: "campaign.strategies.bulk.add.creatives",
    defaultMessage:
      "Add Creatives to { count, number } {count, plural, one { strategy } other { strategies }}",
    parameterized: true,
  },
  removeCreativesTitle: {
    id: "campaign.strategies.bulk.remove.creatives",
    defaultMessage:
      "Remove Creatives from { count, number } {count, plural, one { strategy } other { strategies }}",
    parameterized: true,
  },
  findReplaceCreativesTitle: {
    id: "campaign.strategies.bulk.find.replace.creatives",
    defaultMessage:
      "Find & Replace Creatives for { count, number } {count, plural, one { strategy } other { strategies }}",
    parameterized: true,
  },
  overwriteCreativesTitle: {
    id: "campaign.strategies.bulk.overwrite.creatives",
    defaultMessage:
      "Overwrite Creatives for { count, number } {count, plural, one { strategy } other { strategies }}",
    parameterized: true,
  },
  strategyBtnToolTipMsg: {
    id: "campaign.strategies.strategy.btn.tooltip.msg",
    defaultMessage:
      "You do not have permission to create a new strategy for this campaign",
  },
  withIssueCheckboxLabel: {
    id: "campaign.strategies.withIssues.checkbox",
    defaultMessage: "Load Issues",
  },
  spendGoalMinBidEdit: {
    id: "campaign.strategies.inlineEdit.minBid.spend",
    defaultMessage: "Min Bid field for Spend strategies can't be edited",
  },
  dbaMinBidEdit: {
    id: "campaign.strategies.inlineEdit.minBid.dba",
    defaultMessage: "Min Bid field is controlled by DBA",
  },
  spendGoalMaxBidEdit: {
    id: "campaign.strategies.inlineEdit.maxBid.spend",
    defaultMessage: "Max Bid field for Spend strategies can't be edited",
  },
  winRateToday: {
    id: "campaign.strategies.list.winRate.today",
    defaultMessage: "Win Rate Today",
  },
  winRateYesterday: {
    id: "campaign.strategies.list.winRate.yesterday",
    defaultMessage: "Win Rate Yesterday",
  },
  winRate7Days: {
    id: "campaign.strategies.list.winRate.7days",
    defaultMessage: "Win Rate (7D)",
  },
  winRate14Days: {
    id: "campaign.strategies.list.winRate.14days",
    defaultMessage: "Win Rate (14D)",
  },
  winRate30Days: {
    id: "campaign.strategies.list.winRate.30days",
    defaultMessage: "Win Rate (30D)",
  },
  potentialSpendToday: {
    defaultMessage: "Potential Spend Today",
    id: "campaign.strategies.list.potentialSpend.today",
  },
  potentialSpendTodayUncapped: {
    defaultMessage: "Potential Spend Today Uncapped",
    id: "campaign.strategies.list.potentialSpend.today.uncapped",
  },
  potentialSpendYesterday: {
    defaultMessage: "Potential Spend Yesterday",
    id: "campaign.strategies.list.potentialSpend.yesterday",
  },
  potentialSpendYesterdayUncapped: {
    defaultMessage: "Potential Spend Yesterday Uncapped",
    id: "campaign.strategies.list.potentialSpend.yesterday.uncapped",
  },
  potentialSpend7days: {
    defaultMessage: "Potential Spend (7D)",
    id: "campaign.strategies.list.potentialSpend.7days",
  },
  potentialSpend7daysUncapped: {
    defaultMessage: "Potential Spend (7D) Uncapped",
    id: "campaign.strategies.list.potentialSpend.7days.uncapped",
  },
  potentialSpend14days: {
    defaultMessage: "Potential Spend (14D)",
    id: "campaign.strategies.list.potentialSpend.14days",
  },
  potentialSpend14daysUncapped: {
    defaultMessage: "Potential Spend (14D) Uncapped",
    id: "campaign.strategies.list.potentialSpend.14days.uncapped",
  },
  potentialSpend30days: {
    defaultMessage: "Potential Spend (30D)",
    id: "campaign.strategies.list.potentialSpend.30days",
  },
  potentialSpend30daysUncapped: {
    defaultMessage: "Potential Spend (30D) Uncapped",
    id: "campaign.strategies.list.potentialSpend.30days.uncapped",
  },
  flatRateCpm: {
    defaultMessage: "Flat Rate CPM",
    id: "campaign.strategies.flatRateCpm",
  },
  margin: {
    defaultMessage: "Margin",
    id: "campaign.strategies.margin",
  },
  marginPct: {
    defaultMessage: "Margin Percent",
    id: "campaign.strategies.marginPct",
  },
});

StrategyMessages.HealthStatusMultipleImpacts.parameterized = true;
StrategyMessages.HealthStatusImpactsTooltip.parameterized = true;

i18nFormat(StrategyMessages);

export default StrategyMessages;
