import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

const validId = (id) => parseInt(id, 10) > 0;

const updateLocalStorageItem = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

const useLocalStorageSelections = () => {
  const { user = {} } = useAuth0();
  const USER_ID_KEY = "https://api.mediamath.com/user_id";
  const userId = user[USER_ID_KEY];
  const orgKey = `selectedOrganization_${userId}`;
  const agyKey = `selectedAgency_${userId}`;
  const advKey = `selectedAdvertiser_${userId}`;
  const localOrgId = localStorage.getItem(orgKey);
  const localAgyId = localStorage.getItem(agyKey);
  const localAdvId = localStorage.getItem(advKey);

  const setLocalOrgTree = useCallback(
    (organizationId, agencyId, advertiserId) => {
      // validate selections
      const vorg = validId(organizationId);
      const vagy = validId(agencyId);
      const vadv = validId(advertiserId);
      // validate combinations
      if (vorg && !vagy && !vadv) {
        updateLocalStorageItem(orgKey, organizationId);
        updateLocalStorageItem(agyKey);
        updateLocalStorageItem(advKey);
      }
      if (vorg && vagy && !vadv) {
        updateLocalStorageItem(orgKey, organizationId);
        updateLocalStorageItem(agyKey, agencyId);
        updateLocalStorageItem(advKey);
      }
      if (vorg && vagy && vadv) {
        updateLocalStorageItem(orgKey, organizationId);
        updateLocalStorageItem(agyKey, agencyId);
        updateLocalStorageItem(advKey, advertiserId);
      }
    },
    [orgKey, agyKey, advKey]
  );

  return {
    localOrgId,
    localAgyId,
    localAdvId,
    setLocalOrgTree,
  };
};

export default useLocalStorageSelections;
