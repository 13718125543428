import React from "react";
import { FormattedDate } from "react-intl";
import PropTypes from "prop-types";
import { DateTime as dt } from "luxon";

export default function DateTime({
  formatOptions,
  formatter,
  timeZone,
  value,
}) {
  const isoDate = value instanceof Date ? value.toISOString() : value;
  const date = timeZone
    ? dt.fromISO(isoDate, { zone: timeZone })
    : dt.fromISO(isoDate);

  if (!date.isValid) return "--";

  const dateFormatOptions = formatOptions
    ? { value: date, timeZone, ...formatOptions }
    : {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        value: date,
        timeZone,
      };

  return formatter ? (
    formatter(date, { ...dateFormatOptions })
  ) : (
    <FormattedDate {...dateFormatOptions} />
  );
}

DateTime.propTypes = {
  formatOptions: PropTypes.shape({}),
  formatter: PropTypes.func,
  timeZone: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
