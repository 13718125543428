import qs from "querystring";

const salesforceFieldsMap = {
  userType: "00NF000000DmP98",
  organizationId: "00NF000000DmP97",
  agencyId: "00NF000000DmP92",
  advertiserId: "00NF000000DmP91",
  campaignId: "00NF000000DmP93",
  creativeId: "00NF000000DmP94",
  pixelId: "00NF000000Csbwu",
  strategyId: "00NF000000DmP96",
};

export const caseUrl = "https://mediamathsupport.force.com/s/submit-a-case";

export const buildSupportUrl = ({ ids, pageUrl }) => {
  const params = {};
  Object.entries(ids).forEach(([key, value]) => {
    if (salesforceFieldsMap[key]) {
      params[salesforceFieldsMap[key]] = value;
    }
  });
  const encodedUrl = encodeURIComponent(pageUrl);
  return `${caseUrl}?${qs.encode(params)}&description=${encodedUrl}`;
};

export default salesforceFieldsMap;
