import useIds from "core/shared/hooks/useIds";
import useStreamList from "core/shared/hooks/useStreamList";
import getConnection from "core/shared/util/marvellConnection";
import axios from "axios";
import { getAdvertisersList } from "core/shared/util/marvellStreamingConnection";
import { getFilterBy } from "core/shared/util/filters";

const useAdvertisersList = ({ organizationId, agencyId, filters }) => {
  const filterBy = getFilterBy(filters);
  const { isLoading, isStreaming, data, shouldUpdate } = useStreamList({
    streamFactory: getAdvertisersList,
    dataEvent: "advertisers",
    params: { organizationId, agencyId, filterBy },
    requiredParams: ({ organizationId: oid, agencyId: aid }) => oid || aid,
  });

  const connection = getConnection();

  const changeStatus = async ({ advertiserId, newStatus, version }) => {
    const abortController = new AbortController();
    try {
      await connection.writeEntity({
        entity: { status: newStatus, version },
        entityType: "advertisers",
        entityId: advertiserId,
        signal: abortController?.signal,
      });
      shouldUpdate();
    } catch (e) {
      if (axios.isCancel(e)) {
        // continue regardless of error
      }
    }
  };

  return {
    advIsLoading: isLoading,
    isStreaming,
    advertisers: data,
    advertisersIds: useIds(data),
    changeStatus,
  };
};

export default useAdvertisersList;
