import React from "react";
import PropTypes from "prop-types";
import { useSideNav } from "core/app/hooks/useSideNav";

const Logo = ({ width = "108", height = "40" }) => {
  const { theme } = useSideNav();
  const st0 = { display: "none" };
  const st1 = { display: "inline", fill: "#79C9ED" };
  const st2 = { display: "inline" };
  const st3 = { fill: "#DAF0F9" };
  const st4 = { fill: "#BBE5F6" };
  const st5 = { fill: "#99D4F0" };
  const st6 = { fill: "#58BCEB" };
  const st7 = { fill: "#79C9ED" };
  const dark = { fill: "#000000" };
  const light = { fill: "#FFFFFF" };
  const themeStyle = theme === "light" ? dark : light;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1334 190"
      width={width}
      height={height}
      style={{ enableBackground: "new 0 0 1334 389" }}
    >
      <g>
        <g style={st0}>
          <path
            style={st1}
            d="M1414.09,156.4l18.57,3.39c7.55-34.21,6.12-70.54-5.99-105.79l-18.04,5.98
			C1419.66,92.11,1420.97,125.23,1414.09,156.4z"
          />
          <g style={st2}>
            <path
              style={st3}
              d="M1263.42-62l3.92-17.84c-35.6-6.54-73.6-4.44-110.69,7.86l5.89,17.14
				C1196.34-66.05,1230.98-67.96,1263.42-62z"
            />
            <path
              style={st4}
              d="M1267.34-79.84L1263.42-62c33.47,6.15,64.61,20.68,90.09,42.03l12.68-13.75
				C1338.23-57.15,1304.06-73.09,1267.34-79.84z"
            />
            <path
              style={st5}
              d="M1408.63,59.99l18.04-5.98c-12.11-35.25-33.38-65.01-60.48-87.72l-12.68,13.75
				C1378.21,0.74,1397.59,27.86,1408.63,59.99z"
            />
            <path
              style={st6}
              d="M1368.35,243.48l14.12,11.86c25.05-27.23,42.4-60.25,50.19-95.54l-18.57-3.39
				C1406.99,188.56,1391.18,218.66,1368.35,243.48z"
            />
            <g>
              <path
                style={st5}
                d="M1266.93-24.52c23.68,6.09,44.51,17.1,61.66,31.58l7.61-8.25c-22.1-18.52-49.1-31.12-78.13-36.46
					l-2.35,10.71C1259.46-26.25,1263.2-25.48,1266.93-24.52z"
              />
              <path
                style={st7}
                d="M1373.01,71.8l11-3.65c-9.57-27.86-26.38-51.38-47.81-69.34l-7.61,8.25
					C1349.22,24.46,1364.51,46.87,1373.01,71.8z"
              />
              <path
                style={st6}
                d="M1377.05,149.64l11.69,2.14c5.97-27.04,4.84-55.76-4.73-83.62l-11,3.65
					C1381.3,96.13,1383.08,122.86,1377.05,149.64z"
              />
              <path
                style={st4}
                d="M1255.72-26.94l2.35-10.71c-28.14-5.17-58.18-3.51-87.49,6.21l3.55,10.33
					C1199.75-29.69,1227.77-32.06,1255.72-26.94z"
              />
              <path
                style={st3}
                d="M1174.13-21.11l-3.55-10.33c-29.31,9.72-54.23,26.29-73.43,47.15l8.67,7.29
					C1124.32,2.83,1147.97-12.35,1174.13-21.11z"
              />
            </g>
            <g>
              <path
                style={st7}
                d="M1257.52,6.84c18.6,4.78,34.95,13.44,48.41,24.81l5.91-6.41c-17.34-14.53-38.53-24.42-61.3-28.6l-1.82,8.31
					C1251.66,5.48,1254.59,6.09,1257.52,6.84z"
              />
              <path
                style={st4}
                d="M1184.66,9.54l-2.76-8.04c-23,7.63-42.55,20.63-57.61,37l6.79,5.7
					C1145.57,28.35,1164.13,16.42,1184.66,9.54z"
              />
              <path
                style={st3}
                d="M1131.07,44.2l-6.79-5.7c-15.54,16.89-26.3,37.37-31.13,59.25l9.28,1.7c0.65-3,1.38-6,2.27-8.99
					C1110,72.82,1119.17,57.2,1131.07,44.2z"
              />
              <path
                style={st5}
                d="M1248.72,4.94l1.82-8.31c-22.08-4.06-45.65-2.76-68.65,4.87l2.76,8.04
					C1204.77,2.79,1226.77,0.92,1248.72,4.94z"
              />
              <path
                style={st6}
                d="M1340.76,82.49l8.59-2.85c-7.51-21.86-20.7-40.32-37.51-54.41l-5.91,6.41
					C1322.11,45.32,1334.11,62.92,1340.76,82.49z"
              />
            </g>
          </g>
        </g>
        <g style={themeStyle}>
          <polygon
            points="112.48,256.37 111.4,256.37 50.61,111.55 11.97,111.55 11.97,302.87 37.9,302.87 37.9,145.61 38.47,145.61
			103.04,302.87 120.32,302.87 184.87,145.61 185.42,145.61 185.42,302.87 211.36,302.87 211.36,111.55 172.72,111.55 		"
          />
          <path
            d="M304.35,171.55c-38.37,0-66.19,28.91-66.19,67.25c0,38.13,25.38,67.33,65.93,67.33c24.56,0,43.22-8.67,56.75-26.19
			l-18.37-14.08c-9.47,11.61-20.29,19.18-35.15,19.18c-22.42,0-43.23-14.85-43.23-37.29h102.7v-8.66
			C366.79,206.13,349.51,171.55,304.35,171.55z M264.09,228.3c0-17.05,15.66-37.31,39.71-37.31c24.57,0,36.47,15.95,37.04,37.31
			H264.09z"
          />
          <path
            d="M493.22,111.55v81.59h-0.53c-8.66-13.21-26.21-21.6-44.59-21.6c-39.44,0-65.11,28.91-65.11,67.25
			c0,37.84,25.39,64.07,65.11,64.07c17.29,0,44.59,0,44.59,0h0.53h24.32V111.55H493.22z M451.09,283.42
			c-25.99,0-42.17-18.66-42.17-44.62c0-25.91,16.18-44.57,42.17-44.57c25.9,0,42.13,18.66,42.13,44.57
			C493.22,264.75,476.99,283.42,451.09,283.42z"
          />
          <rect x="549.44" y="174.76" width="24.35" height="128.11" />
          <path
            d="M656.98,171.55c-17.83,0-37.29,6.19-50.79,18.9l14.56,15.12c8.93-6.74,17.85-14.57,36.49-14.57
			c20.8,0,30.56,13.77,30.56,27.04v5.41h-17.85c-38.9,0-70.52,11.3-70.52,44.29c0,24.85,20.54,35.14,44.3,35.14
			c19.44,0,44.84,0,44.84,0h1.36h20.5c0,0,0-14.33,0-23.24v-65.4C710.44,192.07,694.81,171.55,656.98,171.55z M687.8,249.34
			c0,17.31-9.49,37.3-36.49,37.3c-16.76,0-27.59-6.22-27.59-20.79c0-20.52,28.16-24.63,51.07-24.63h13.01V249.34z"
          />
          <path
            d="M1019.79,171.55c-17.84,0-37.32,6.19-50.82,18.9l14.59,15.12c8.93-6.74,17.88-14.57,36.53-14.57
			c20.76,0,30.5,13.77,30.5,27.04v5.41h-17.82c-38.96,0-70.49,11.3-70.49,44.29c0,24.85,20.49,35.14,44.28,35.14
			c19.47,0,44.87,0,44.87,0h1.34h20.54c0,0,0-14.33,0-23.24v-65.4C1073.3,192.07,1057.61,171.55,1019.79,171.55z M1050.58,249.34
			c0,17.31-9.47,37.3-36.49,37.3c-16.75,0-27.55-6.22-27.55-20.79c0-20.52,28.12-24.63,51.08-24.63h12.96V249.34z"
          />
          <polygon
            points="843.97,256.37 842.87,256.37 782.1,111.55 743.44,111.55 743.44,302.87 769.39,302.87 769.39,145.61
			769.93,145.61 834.51,302.87 851.81,302.87 916.38,145.61 916.92,145.61 916.92,302.87 942.87,302.87 942.87,111.55
			904.24,111.55 		"
          />
          <path
            d="M1144.5,253.92v-58.07h34.82v-21.09h-34.82v-35.92h-24.34v35.92h-28.11v21.09h28.11v66.76c0,20.52,0.54,43.51,38.09,43.51
			c4.88,0,16.19-1.11,21.9-4.33v-22.16c-4.9,2.98-11.67,3.78-17.31,3.78C1144.5,283.42,1144.5,268.26,1144.5,253.92z"
          />
          <path
            d="M1270.92,171.55c-19.72,0-42.13,0-42.13,0l-0.56-59.99h-24.32v191.32h24.32v-65.96c0-29.7,15.42-42.68,35.65-42.68
			c15.42,0,29.21,8.92,29.21,32.96v75.68h24.28v-82.44C1317.37,186.41,1295.83,171.55,1270.92,171.55z"
          />
          <rect x="549.44" y="129.88" width="24.35" height="24.31" />
        </g>
      </g>
    </svg>
  );
};

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Logo;
