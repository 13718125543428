import getHostName from "./getHostname";

export default function isProd() {
  // TODO: use environment variable instead of hardcoded string
  // when we have better environment variable management
  if (process.env.REACT_APP_USE_AUTH) {
    return process.env.REACT_APP_USE_AUTH === "prod";
  }
  const host = getHostName();
  return (
    host === "platform.mediamath.com" ||
    host === "newt1.mediamath.com" ||
    host === "newt1-uat.mediamath.com" ||
    host === "uat-platform.mediamath.com"
  );
}
