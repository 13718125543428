import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useUserPreferencesState } from "../settings/hook/useUserPreferences";

const UserPreferences = ({ children }) => {
  const { settings, load: loadPreferences } = useUserPreferencesState();
  useEffect(() => {
    if (settings === null) {
      loadPreferences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

UserPreferences.propTypes = {
  children: PropTypes.node,
};

UserPreferences.defaultProps = {
  children: null,
};

export default UserPreferences;
