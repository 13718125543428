/** @jsxRuntime classic */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { css, jsx } from "@emotion/react";
import Logo from "core/shared/asset/Logo";
import {
  Icon,
  Colors,
  StHeader,
  StHeaderItem,
} from "@mediamath/design-system-eng";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import AppFeatureFlags from "core/app/util/AppFeatureFlags";
import AppColors from "core/shared/presenter/SharedAppColors";
import AccountSelector from "core/app/presenter/AccountSelector";
import { useSideNav } from "core/app/hooks/useSideNav";
import { HeaderUserTimezone } from "core/app/presenter/HeaderBox";
import { useFlagVariations } from "@flopflip/react-broadcast";
import CommonMessages from "creatives/intl/CommonMessages";
import useFormMessages from "../../pattern/form-hooks/hooks/useFormMessages";

const StAppHeader = styled(StHeader)`
  box-sizing: border-box;
  border-bottom: 1px solid ${AppColors.AppBackground};
`;

const menuStyle = css`
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: inherit;
`;

const StLeftItems = styled("div")`
  display: flex;
`;

const StAcctSelectorHolder = styled("div")`
  padding: 0 6px;
  display: flex;
  align-items: center;
`;

const StHeaderIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition-property: color, background-color;
  transition-duration: 500ms;
  ${({ appHeaderOpen }) =>
    appHeaderOpen
      ? `background-color: ${Colors.ColorBlue800};
         color: ${Colors.ColorWhite};`
      : `background-color: ${Colors.ColorWhite};
         color: ${Colors.ColorBlue800};`}
`;

const StBlockHeader = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 50px;
  padding: 10px;
`;

const StHeaderLink = styled("a")`
  font-size: 18px;
`;

const renderAccountSelector = (flagState) => {
  switch (flagState) {
    default:
      return null;
    case "v0-org-only":
      return <AccountSelector />;
  }
};

const AppHeader = () => {
  const { toggleSideNav, collapsed } = useSideNav();
  const [SIDE_NAV_FLAG, ACCOUNT_SELECTOR_FLAG] = useFlagVariations([
    AppFeatureFlags.SIDE_NAV,
    AppFeatureFlags.ACCOUNT_SELECTOR,
  ]);
  const { msgs } = useFormMessages(CommonMessages);

  const isExternalPreview = window?.location?.href?.includes(
    "/creative-preview/"
  );

  return (
    <StAppHeader
      backgroundColor="#ffffff"
      paddingLeft={isExternalPreview ? "15px" : "0px"}
      paddingRight="15px"
    >
      {/* Left side items */}
      <StLeftItems>
        {isExternalPreview ? (
          <Logo />
        ) : (
          <>
            {SIDE_NAV_FLAG && (
              <StHeaderIcon onClick={toggleSideNav} appHeaderOpen={!collapsed}>
                <Icon name="menu-fill" css={[menuStyle]} />
              </StHeaderIcon>
            )}
            <StAcctSelectorHolder>
              {renderAccountSelector(ACCOUNT_SELECTOR_FLAG)}
            </StAcctSelectorHolder>
          </>
        )}
      </StLeftItems>
      {/* Right side items */}
      <StHeaderItem>
        {isExternalPreview ? (
          <StHeaderLink
            href="https://mediamathsupport.force.com/s/"
            target="_blank"
            rel="noreferrer"
          >
            {msgs.MediaMathHelpCenter}
          </StHeaderLink>
        ) : (
          <>
            <StBlockHeader>
              <HeaderUserTimezone />
            </StBlockHeader>
            <Logo />
          </>
        )}
      </StHeaderItem>
    </StAppHeader>
  );
};

AppHeader.defaultProps = {
  actions: undefined,
  featureToggles: undefined,
};

AppHeader.propTypes = {
  actions: PropTypes.shape({}),
  featureToggles: PropTypes.shape({}),
};

export default AppHeader;
