import React from "react";
import PropTypes from "prop-types";
import { StOrgLabel } from "core/app/presenter/AccountSelectorButton";
import { StCaption, Tooltip } from "@mediamath/design-system-eng";
import messages from "core/app/intl/AppMessages";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import regex from "core/app/util/regex";

const StCaptionDetail = styled(StCaption)`
  margin-top: 0px !important;
  font-size: 12px !important;
`;

const HeaderBox = ({ title, text }) => {
  return (
    <div>
      <StOrgLabel>{title}</StOrgLabel>
      <StCaptionDetail>{text}</StCaptionDetail>
    </div>
  );
};

HeaderBox.defaultProps = {
  text: "",
  title: "",
};

HeaderBox.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
};

export const HeaderUserTimezone = () => {
  const intl = useIntl();
  const titleUserTimezone = intl.formatMessage(messages.userTimezone);
  const matchedDate = regex.dateExtractorExpression.exec(new Date().toString());
  const textUserTimezone = `(GMT ${matchedDate.groups.symbol}${matchedDate.groups.hours}:${matchedDate.groups.minutes}) ${matchedDate.groups.location}`;
  const tooltipUserTimezone = intl.formatMessage(messages.userTimezoneTooltip);

  const HeaderB = (
    <span>
      <HeaderBox text={textUserTimezone} title={titleUserTimezone} />
    </span>
  );

  return (
    <Tooltip
      data-testid="usertimezonetooltip"
      aria-label="usertimezone-tooltip"
      trigger={HeaderB}
      content={tooltipUserTimezone}
      position="bottom center"
    />
  );
};

export default HeaderBox;
