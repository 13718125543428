import createStore from "core/shared/util/zustand";
import { persist } from "zustand/middleware";
import getConnection from "core/shared/util/marvellConnection";
import { shallow } from "zustand/shallow";
import _ from "lodash";

import CampaignConstants from "campaign/util/CampaignConstants";

const { RESPONSE_SUCCESS_USER_PREFERENCES } = CampaignConstants;
/* eslint-disable no-param-reassign */
const getData = async (set) => {
  const abortController = new AbortController();
  const connection = getConnection();
  const userPreferences = await connection.getUserPreferences({
    signal: abortController?.signal,
  });
  const { data } = userPreferences.data;
  set((state) => {
    state.userPreferences.settings = data.settings;
  });
};

const useUserPreferencesStore = createStore(
  persist(
    (set, get) => ({
      userPreferences: {
        settings: null,
        error: null,
        setSettings: (settings) =>
          set((state) => {
            state.userPreferences.settings = settings;
          }),
        load: async () => {
          await getData(set);
        },
        getSettings: (key) => {
          return get().userPreferences.settings?.[key];
        },
        save: async (namespace, data) => {
          const abortController = new AbortController();
          const connection = getConnection();
          const JSONdata = data;
          const response = await connection.saveUserPreferences({
            namespace,
            data: JSONdata,
            signal: abortController?.signal,
          });
          if (
            response?.data.meta.status === RESPONSE_SUCCESS_USER_PREFERENCES
          ) {
            await getData(set);
          }
          return response;
        },
      },
    }),
    {
      name: "preferences",
      getStorage: () => localStorage,
      merge: (persistedState, currentState) =>
        _.merge(currentState, persistedState),
    }
  )
);

export const useUserPreferencesState = () =>
  useUserPreferencesStore((state) => state.userPreferences, shallow);

export default useUserPreferencesStore;
