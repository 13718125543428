import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";

const NoMatchMessages = defineMessages({
  title: {
    id: "nomatch.title",
    defaultMessage: "Page Not Found",
  },
  route: {
    id: "nomatch.route",
    defaultMessage:
      "The page you're looking for has been removed, renamed, or made unavailable.",
  },
  back: {
    id: "nomatch.back",
    defaultMessage: "Go Back",
  },
  login: {
    id: "nomatch.auth",
    defaultMessage: "Redirecting for Login...",
  },
});

i18nFormat(NoMatchMessages);

export default NoMatchMessages;
