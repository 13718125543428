// const traditionalRoleMap = [
//   { id: 1, name: "global_admin" },
//   { id: 2, name: "admin" },
//   { id: 3, name: "manager" },
//   { id: 4, name: "reporter" },
//   { id: 5, name: "internal" },
//   { id: 6, name: "agency" },
// ];
// const roleFlagMap = [
//   { id: 7, name: "edit_data_definition" },
//   { id: 8, name: "view_data_definition" },
//   { id: 9, name: "edit_segments" },
//   { id: 10, name: "view_segments" },
//   { id: 11, name: "edit_campaigns" },
//   { id: 12, name: "access_internal_fees" },
//   { id: 13, name: "edit_margins_and_performance" },
//   { id: 14, name: "view_organizations" },
//   { id: 15, name: "view_dmp_reports" },
//   { id: 16, name: "manage_contracts" },
//   { id: 17, name: "view_contracts" },
// ];

const INTERNAL = "INTERNAL";
const AGENCY = "AGENCY";
const ADMIN = "ADMIN";
const SELECT = "SELECT";

/**
 * The key where the user's roles are stored in localStorage
 */
export const roles = "roles";

/**
 * Get the user's roles in localStorage
 */
export const getRbacRoles = () => {
  return (localStorage.getItem(roles) || [])
    .split(",")
    .map((role) => parseInt(role, 10));
};

/**
 * Set the roles in localStorage
 * @param rolesArray
 */
export const setUserRoles = (rolesArray) => {
  localStorage.setItem(roles, rolesArray);
};

export const getUserRoles = () => {
  const rbac = getRbacRoles();
  if (rbac.includes(1)) return { role: ADMIN, type: INTERNAL, scope: "GLOBAL" };
  if (rbac.includes(2))
    return {
      role: ADMIN,
      type: rbac.includes(5) ? INTERNAL : AGENCY,
      scope: SELECT,
    };
  return {
    role: rbac.includes(3) ? "MANAGER" : "REPORTER",
    type: rbac.includes(5) ? INTERNAL : AGENCY,
    scope: SELECT,
  };
};

export const canCreateEditCampaign = () => getRbacRoles().includes(11);
