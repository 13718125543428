export default function createRoutePermission(navRoutes, entity) {
  const routePermissions = {};

  navRoutes.forEach((navRoute) => {
    if (typeof navRoute === "object") {
      const { path, ...rest } = navRoute;
      routePermissions[path] = {
        entity,
        ...rest,
      };
    } else {
      routePermissions[navRoute] = {
        entity,
      };
    }
  });

  return routePermissions;
}
