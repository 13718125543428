import React from "react";
import PropTypes from "prop-types";
import Analytics from "app/Analytics";
import GoogleAnalytics from "app/GoogleAnalytics";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "core/shared/presenter/AuthProvider";
import FeatureFlagContainer from "core/shared/container/FeatureFlagContainer";
import StackedSidebarManager from "core/shared/util/StackedSidebar";
import NotifierProvider from "core/shared/util/Notifier";
import IntlWrapper from "app/IntlWrapper";
import { AccountSelectorProvider } from "core/app/hooks/useAccountSelectorEntities";
import UserPreferences from "app/UserPreferences";
import { DocumentTitleProvider } from "core/app/hooks/useDocumentTitle";
import { SentryContextUpdater } from "app/SentryHelpers";
import styled from "@emotion/styled";
import { StFlexDiv } from "@mediamath/design-system-eng";
import Muli from "core/fonts/Muli-Regular.ttf";
import MuliSemiBold from "core/fonts/Muli-SemiBold.ttf";
import MuliBold from "core/fonts/Muli-Bold.ttf";
import UserPermissionsProvider from "core/shared/presenter/UserPermissionsProvider";

const StViewport = styled(StFlexDiv)`
  width: ${({ isStoryBook }) => (isStoryBook ? "100%" : "100vw")};
  height: ${({ isStoryBook }) => (isStoryBook ? "100%" : "100vh")};
  max-width: ${({ isStoryBook }) => (isStoryBook ? "100%" : "100vw")};
  min-height: ${({ isStoryBook }) => (isStoryBook ? "100%" : "100vh")};
  overflow: hidden;

  @font-face {
    font-family: Muli;
    src: url(${Muli});
  }
  @font-face {
    font-family: Muli;
    font-weight: 600;
    src: url(${MuliSemiBold});
  }
  @font-face {
    font-family: Muli;
    font-weight: 700;
    src: url(${MuliBold});
  }
`;

const Wrappers = ({ children, isStoryBook }) => {
  return (
    <Router>
      <AuthProvider>
        <UserPreferences>
          <DocumentTitleProvider>
            <AccountSelectorProvider>
              <Analytics />
              <FeatureFlagContainer>
                <IntlWrapper>
                  <UserPermissionsProvider>
                    <GoogleAnalytics />
                    <SentryContextUpdater />
                    <NotifierProvider>
                      <StackedSidebarManager>
                        <StViewport flexFlow="column" isStoryBook={isStoryBook}>
                          {children}
                        </StViewport>
                      </StackedSidebarManager>
                    </NotifierProvider>
                  </UserPermissionsProvider>
                </IntlWrapper>
              </FeatureFlagContainer>
            </AccountSelectorProvider>
          </DocumentTitleProvider>
        </UserPreferences>
      </AuthProvider>
    </Router>
  );
};

Wrappers.propTypes = {
  children: PropTypes.node,
  isStoryBook: PropTypes.bool,
};
Wrappers.defaultProps = {
  children: undefined,
  isStoryBook: false,
};

export default Wrappers;
