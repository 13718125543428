import { useMemo } from "react";

const useIds = (data) => {
  return useMemo(
    () => (data ? Object.keys(data).map((i) => parseInt(i, 10)) : []),
    [data]
  );
};

export default useIds;
