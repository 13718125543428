import StreamSource from "core/shared/util/StreamSource";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import Sentry, { logClientTraceId } from "app/SentryHelpers";

const userTZ = DateTime.local().zoneName;
const endpoint = "/marvell";
let getTokenSilently;
export const useInitGetStreamConnection = () => {
  const { getAccessTokenSilently: gts } = useAuth0();
  getTokenSilently = gts;
};

/**
 * useGetEventProvider
 * @param {string} url - the Server Sent Event url to connect to
 * @param {string} withCredentials - Forward cookies, defaults to "include"
 * @param {object} callbacks - An object containing functions for common event source events (complete, data, etc)
 * @param {object} params - An object containing query params to send along with the request
 */
export const getEventProvider = async ({
  url,
  withCredentials = "include",
  callbacks,
  params,
} = {}) => {
  const token = await getTokenSilently();
  const clientTraceId = uuidv4();

  const headers = {
    Authorization: `Bearer ${token}`,
    "client-trace-id": clientTraceId,
  };

  Sentry.addBreadcrumb({
    category: "stream-client-trace-id",
    message: `Endpoint: ${endpoint}/${url}, client-trace-id: ${clientTraceId}`,
    level: "info",
  });

  const sc = new StreamSource({
    url: `${endpoint}/${url}`,
    withCredentials,
    callbacks,
    params,
    headers,
  });

  logClientTraceId(clientTraceId, `${endpoint}/${url}`, "EvStream");
  return sc;
};

export const getDealsList = ({
  organizationId,
  timeWindow,
  dealName,
  startDate,
  endDate,
  callbacks,
}) => {
  const params = {
    organizationId,
    ...(dealName !== "" && { dealName }),
  };

  if (!timeWindow) {
    params.startDate = startDate;
    params.endDate = endDate;
  } else {
    params.timeWindow = timeWindow;
  }

  return getEventProvider({ url: `deals_paging`, params, callbacks });
};

export const getSPMList = ({
  organizationId,
  pageOffset = 0,
  pageLimit,
  spmType = "path",
  callbacks,
  entityId,
  typeEntity,
}) => {
  let params = {
    organizationId,
    pageOffset,
    pageLimit,
  };

  if (spmType === "paths_entity") {
    params = {
      organizationId,
      pageOffset,
      pageLimit,
      entityId,
      typeEntity,
    };
  }

  return getEventProvider({
    url:
      spmType === "path" || spmType === "paths_entity"
        ? `supply_path_management_v2`
        : `supply_path_management_v2_entity`,
    params,
    callbacks,
  });
};

export const getDealGroupsList = ({
  organizationId,
  timeWindow,
  startDate,
  endDate,
  callbacks,
}) => {
  const params = {
    organizationId,
  };
  if (!timeWindow) {
    params.startDate = startDate;
    params.endDate = endDate;
  } else {
    params.timeWindow = timeWindow;
  }

  return getEventProvider({
    url: `deal_group_list_paging/${organizationId}`,
    params,
    callbacks,
  });
};

export const getCampaignList = ({
  organizationId,
  agencyId,
  advertiserId,
  searchText,
  status,
  startDate,
  endDate,
  callbacks,
}) => {
  const params = {
    organizationId,
    agencyId,
    advertiserId,
    status,
    startDate,
    endDate,
    ...(searchText && searchText !== "" && { campaignName: searchText }),
    userTZ,
  };

  return getEventProvider({ url: "campaigns_paging", params, callbacks });
};

export const getStrategyList = ({
  timeWindows,
  hasDba,
  loadWinloss,
  campaignId,
  searchText,
  status,
  showAll,
  withIssues,
  callbacks,
}) => {
  let params = {
    ...(searchText && searchText !== "" && { strategyName: searchText }),
    status,
    userTZ,
    loadWinloss,
    withIssues,
  };
  params = hasDba ? { ...params, with_dba: hasDba, showAll } : { ...params };
  params = timeWindows
    ? { ...params, goals_time_windows: timeWindows }
    : { ...params };

  return getEventProvider({
    url: `strategy_list_paging/${campaignId}`,
    params,
    callbacks,
  });
};

export const getOrganizationList = ({ search, status, callbacks }) => {
  const params = {
    ...(search && { search }),
    ...(typeof status !== "undefined" && { status }),
  };

  return getEventProvider({ url: "organizations", params, callbacks });
};

export const getAdvertisersList = ({
  agencyId,
  organizationId,
  filterBy,
  callbacks,
}) => {
  const params = {
    ...(agencyId && { agencyId }),
    ...(organizationId && { organizationId }),
    ...(filterBy && { filterBy }),
  };
  const url = `advertisers_paging`;

  return getEventProvider({ url, params, callbacks });
};

export const getAgenciesList = ({ organizationId, filterBy, callbacks }) => {
  const params = {
    ...(organizationId && { organizationId }),
    ...(filterBy && { filterBy }),
  };

  const url = `agencies_paging`;
  return getEventProvider({ url, params, callbacks });
};

export const getUserList = ({
  filterBy,
  permissionEntity,
  permissionEntityId,
  callbacks,
}) => {
  const params = {
    filterBy,
    permissionEntity,
    permissionEntityId,
  };
  return getEventProvider({ url: "users_paging", params, callbacks });
};

export const getVendorList = ({ filterBy, callbacks }) => {
  const params = {
    filterBy,
  };
  return getEventProvider({ url: "vendors_paging", params, callbacks });
};

export const getExchangeSeatList = ({ organizationId, callbacks }) => {
  return getEventProvider({
    url: `exchangeseats_list_paging/${organizationId}`,
    callbacks,
  });
};

export const getSupplySources = ({ callbacks }) => {
  return getEventProvider({
    url: "supplysource_list_paging",
    callbacks,
  });
};

export const getStrategiesCatalog = ({ campaignId, status, callbacks }) => {
  const params = {
    status,
    campaignId,
  };
  return getEventProvider({
    url: `strategies_by_campaign/${campaignId}`,
    params,
    callbacks,
  });
};

export default {};
