import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";

const PlanningListMessages = defineMessages({
  planTitle: {
    id: "plan.list.title.plans",
    defaultMessage: "Plans",
  },
  noData: {
    id: "plan.list.noData",
    defaultMessage: "You have no Plans saved for this Organization",
  },
  noOrganizationSelected: {
    id: "plan.list.noOrganizationSelected",
    defaultMessage:
      "Prompt to select an Organization if one isn't already selected",
  },
  planName: {
    id: "plan.list.columns.planName",
    defaultMessage: "Plan name",
  },
  starDate: {
    id: "plan.list.columns.startDate",
    defaultMessage: "Start date",
  },
  endDate: {
    id: "plan.list.columns.endDate",
    defaultMessage: "End date",
  },
  advertiserName: {
    id: "plan.list.columns.advertiserName",
    defaultMessage: "Advertiser name",
  },
  mediaSpend: {
    id: "plan.list.columns.mediaSpend",
    defaultMessage: "Media spend",
  },
  impressions: {
    id: "plan.list.columns.impressions",
    defaultMessage: "Impressions",
  },
  cpm: {
    id: "plan.list.columns.cpm",
    defaultMessage: "CPM",
  },
  uniqueUsers: {
    id: "plan.list.columns.uniqueUsers",
    defaultMessage: "Unique users",
  },
  lastSavedForecast: {
    id: "plan.list.columns.lastSavedForecast",
    defaultMessage: "Last saved & forecast",
  },
  newPlanBtn: {
    id: "plan.list.btn.newPlan",
    defaultMessage: "New plan",
  },
  editPlanAction: {
    id: "plan.list.columns.editPlanAction",
    defaultMessage: "Edit",
  },
  deletePlanAction: {
    id: "plan.list.columns.deletePlanAction",
    defaultMessage: "Delete",
  },
  modalDeleteActionPlanNo: {
    id: "plan.list.modal.deleteActionPlanNo",
    defaultMessage: "NO",
  },
  modalDeleteActionPlanYes: {
    id: "plan.list.modal.deleteActionPlanYes",
    defaultMessage: "YES",
  },
  modalDeleteActionPlanContent: {
    id: "plan.list.modal.deleteActionPlanContent",
    defaultMessage: "Are you sure you want to delete",
  },
  modalDeleteActionPlanHeader: {
    id: "plan.list.modal.deleteActionPlanHeader",
    defaultMessage: "Delete Plan",
  },
  planListSuccessDeletion: {
    id: "plan.list.delete.success",
    defaultMessage: "Plan Deleted",
  },
  planListErrorDeletion: {
    id: "plan.list.delete.error",
    defaultMessage: "Plan was not deleted successfully",
  },
  runForecastAction: {
    id: "plan.list.columns.runForecastAction",
    defaultMessage: "Run Forecast",
  },
  runForecastSuccessfullyMsg: {
    id: "plan.list.forecast.successfully",
    defaultMessage: "Forecaster ran successfully",
  },
  runForecastErrorMsg: {
    id: "plan.list.runForecast.error",
    defaultMessage:
      "There was a problem running your forecast. Please try again.",
  },
  duplicatePlanAction: {
    id: "plan.list.columns.duplicatePlanAction",
    defaultMessage: "Duplicate",
  },
});

i18nFormat(PlanningListMessages);

export default PlanningListMessages;
