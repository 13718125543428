import qs from "query-string";

export default function getQueryStringParameters(location) {
  return qs.parse(location.search, { ignoreQueryPrefix: true });
}

export function getIdParameters(location) {
  const params = getQueryStringParameters(location);
  return Object.keys(params).reduce((prev, key) => {
    if (params[key]) {
      const idKey = key === "id" ? key : `${key}Id`;
      return { ...prev, [idKey]: params[key] };
    }
    return prev;
  }, {});
}

export function modifyQueryStringParameters({ history, entities }) {
  const { location } = history;
  const idProps = getQueryStringParameters(location);

  entities.forEach(({ entity, id }) => {
    if (id) {
      idProps[entity] = id;
    } else {
      delete idProps[entity];
    }
  });
  return qs.stringify(idProps);
}

export function updateQueryStringParameters({
  history,
  entities,
  entity,
  id,
  pathname,
}) {
  const query = modifyQueryStringParameters({
    history,
    entities: entities || [{ entity, id }],
  });
  return history.push({
    pathname: pathname || history.location.pathname,
    search: `${query}`,
  });
}

export function retrieveQueryPathname({
  history,
  entities,
  entity,
  id,
  pathname,
}) {
  const query = modifyQueryStringParameters({
    history,
    entities: entities || [{ entity, id }],
  });
  return [pathname || history.location.pathname, `${query}`];
}

// Removes a search parameter from the URL without changing the history
export const removeSearchParam = (param) => {
  const { href, search } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.delete(param);

  const updatedSearchParams = searchParams.toString();
  const [urlPath] = href.split("?");
  const updatedHref = `${urlPath}?${updatedSearchParams}`;

  window.location.replace(updatedHref);
};
