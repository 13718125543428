import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";
import FormPatternMessages from "core/pattern/form-hooks/intl/FormPatternMessages";

const AccessDeniedMessages = defineMessages({
  title: {
    id: "access.title",
    defaultMessage: "Access Denied",
  },
  text1: {
    id: "access.text1",
    defaultMessage: "You do not have permission to view this page.",
  },
  text2: {
    id: "access.text2",
    defaultMessage:
      "If you believe this is an error, please contact T1 Support.",
  },
  back: {
    id: "access.back",
    defaultMessage: "Go Back",
  },
  notEditPermission: {
    id: "access.notEditPermission",
    defaultMessage: "You can not edit. Your user has limited permissions",
  },
});

AccessDeniedMessages.internalServerError =
  FormPatternMessages.internalServerError;
AccessDeniedMessages.badRequestError = FormPatternMessages.badRequestError;

i18nFormat(AccessDeniedMessages);

export default AccessDeniedMessages;
