import { useEffect } from "react";
import { useFeatureToggle } from "@flopflip/react-broadcast";
import AppFeatureFlags from "core/app/util/AppFeatureFlags";
import useLocalStorage from "core/shared/hooks/useLocalStorage";

const { LEGACY_PLATFORM } = AppFeatureFlags;
const LOCAL_STORAGE_KEY = "isLegacyPlatform";

const useLegacyPlatformView = () => {
  const showLegacyPlatformView = useFeatureToggle(LEGACY_PLATFORM);
  const [state, setState] = useLocalStorage(LOCAL_STORAGE_KEY, false);

  useEffect(() => {
    if (!showLegacyPlatformView) {
      setState(false);
    }
  }, [setState, showLegacyPlatformView]);

  return {
    isLegacyPlatform: state,
    setIsLegacyPlatform: setState,
    showLegacyPlatformView,
  };
};

export default useLegacyPlatformView;
