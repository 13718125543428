import { useMemo } from "react";
import { useIntl } from "react-intl";
import { formatAll, assembleFormMessages } from "core/shared/util/i18nFormat";

const useFormMessages = (messages) => {
  const intl = useIntl();
  return useMemo(() => {
    if (messages) {
      const fieldMessages = assembleFormMessages(messages);
      const msgs = formatAll(intl, messages);
      return { fieldMessages, msgs };
    }
    return {};
  }, [messages, intl]);
};

export default useFormMessages;
