import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useInitGetConnection } from "core/shared/util/marvellConnection";
import { useInitGetStreamConnection } from "core/shared/util/marvellStreamingConnection";
import getIsProd from "core/shared/util/getIsProd";
import {
  setSelectedTimeZone,
  setSelectedLanguage,
} from "settings/util/localizationSettings";
import { setUserRoles } from "settings/util/rolesSettings";

const isProd = getIsProd();

export const API_URL = "https://api.mediamath.com/t1";

const useInitSetLocalStorage = () => {
  const {
    user: {
      [API_URL]: { roles = [], language, zone_name: zoneName } = {},
    } = {},
    isAuthenticated,
  } = useAuth0();

  useEffect(() => {
    if (language) {
      setSelectedLanguage(language);
    }
    if (zoneName) {
      setSelectedTimeZone(zoneName);
    }
    if (roles.length) {
      setUserRoles(roles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
};

const clientId = isProd
  ? "kecSVhNLs023Ty1gbjgtnDEi76IIVgk4"
  : "AC7rOZHG2rsz6tpHeWZ3Hrfi1qkyI0eg";
const domain = isProd ? "auth.mediamath.com" : "mediamath-dev.auth0.com";
const audience = "https://api.mediamath.com/";

export const config = {
  client_id: clientId,
  domain,
  audience,
  redirect_uri: window.location.origin,
  cacheLocation: "localstorage",
};

const APIInit = () => {
  useInitGetConnection();
  useInitGetStreamConnection();
  useInitSetLocalStorage();
  return null;
};

const AuthProvider = ({ children }) => {
  const history = useHistory();
  return (
    <Auth0Provider
      cacheLocation="localstorage"
      clientId={clientId}
      domain={domain}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={(appState) => {
        history.replace(appState?.returnTo || window.location.pathname);
      }}
    >
      <APIInit />
      {children}
    </Auth0Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node,
};
AuthProvider.defaultProps = {
  children: null,
};

export default AuthProvider;
