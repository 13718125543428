import React from "react";
import styled from "@emotion/styled";
import {
  StFlexDiv,
  StDiv,
  Button,
  StHeadline,
  StMarginDiv,
} from "@mediamath/design-system-eng";
import { useHistory } from "react-router-dom";
import useFormMessages from "core/pattern/form-hooks/hooks/useFormMessages";
import NoMatchMessages from "core/shared/intl/NoMatchMessages";

const StError = styled(StFlexDiv)`
  background: none;
  align-items: center;
  justify-content: center;
`;

/* eslint-disable jsx-a11y/anchor-is-valid */
const NoMatch = () => {
  const { msgs } = useFormMessages(NoMatchMessages);
  const history = useHistory();

  return (
    <StError>
      <StDiv>
        <StHeadline>{msgs.title}</StHeadline>
        <StMarginDiv>{msgs.route}</StMarginDiv>
        <StMarginDiv>
          <Button onClick={() => history.goBack()}>{msgs.back}</Button>
        </StMarginDiv>
      </StDiv>
    </StError>
  );
};

export default NoMatch;
