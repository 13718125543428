import React from "react";
import { Colors, Icon } from "@mediamath/design-system-eng";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const StAcctSelector = styled("div")`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 3px 6px;
  cursor: pointer;
  min-width: 120px;
  & > i {
    margin: 15px 0px 0px 18px;
  }
`;

export const StOrgLabel = styled("div")`
  color: ${Colors.ColorGrey400};
  font-size: 10px;
  text-transform: uppercase;
`;

const StOrgName = styled("div")`
  font-size: 16px;
`;

// TODO(IR): Make this not suck
const AcctSelectorButton = ({
  label,
  name,
  onToggle,
  collapsed,
  showCaret,
}) => (
  <StAcctSelector onClick={onToggle}>
    <div>
      <StOrgLabel>{label}</StOrgLabel>
      <StOrgName>{name}</StOrgName>
    </div>
    {showCaret && (
      <Icon
        className={collapsed ? "chevron-down" : "chevron-up"}
        data-testid="select-organizations"
      />
    )}
  </StAcctSelector>
);

AcctSelectorButton.propTypes = {
  label: PropTypes.node,
  name: PropTypes.node,
  onToggle: PropTypes.func,
  collapsed: PropTypes.bool,
  showCaret: PropTypes.bool,
};

AcctSelectorButton.defaultProps = {
  label: undefined,
  name: undefined,
  onToggle: () => {},
  collapsed: true,
  showCaret: false,
};

export default AcctSelectorButton;
