import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Message, Icon, Accordion } from "@mediamath/design-system-eng";
import MessageTypes from "core/shared/util/MessageTypes";

const getPadding = ({ motion }) => {
  switch (motion) {
    default:
      return "0 !important";
    case "in":
      return "";
    case "out":
      return "0 !important";
  }
};

const StMessage = styled(Message)`
  margin: 0 auto !important;
  margin-bottom: 24px !important;
  overflow: hidden;
  width: 50%;

  transition: opacity 400ms ease-out, max-height 400ms ease-out,
    padding-top 300ms ease-out, padding-bottom 300ms ease-out !important;
  opacity: ${({ motion }) => {
    switch (motion) {
      default:
        return 0;
      case "in":
        return 1;
      case "out":
        return 0;
    }
  }};
  max-height: ${({ motion }) => {
    switch (motion) {
      default:
        return 0;
      case "in":
        return "auto";
      case "out":
        return 0;
    }
  }};
  padding-top: ${getPadding};
  padding-bottom: ${getPadding};
  &.ui.message {
    padding-right: 42px; // to accomodate for icon
  }
`;

const StAccordion = styled(Accordion)`
  margin-left: 20px;
  padding-top: 10px;

  .accordion-content {
    max-height: 175px;
    overflow-y: auto;
    margin-top: 0;
    padding-top: 0;
  }

  .accordion-content ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .accordion-content li {
    margin-left: 20px;
    padding-bottom: 5px;
    max-height: 150px;
  }
`;

const StAccordionTitle = styled(Accordion.Title)`
  font-weight: bold;
  & > div > i {
    display: none;
  }
`;

const StBodyMessage = styled.div`
  margin-left: 3em;
  margin-top: 5px;
`;

const getIconClassNameByType = (type) => {
  switch (type) {
    case MessageTypes.ERROR:
      return "fail-line";
    case MessageTypes.SUCCESS:
      return "success-line";
    case MessageTypes.INFO:
      return "info-line";
    case MessageTypes.WARNING:
      return "warning-tri-line";
    default:
      return "";
  }
};

const ToastMessage = ({
  duration,
  message,
  type,
  list,
  headerMsg,
  onExit,
  index,
  ...rest
}) => {
  const [transition, setTransition] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const typeObj = { [type]: true };

  const exit = useCallback(() => {
    if (typeof onExit === "function") {
      onExit(index);
    }
  }, [onExit, index]);

  // dismiss toast message after X seconds
  useEffect(() => {
    let time;
    if (duration !== 0) {
      time = setTimeout(() => {
        setTransition(false);
      }, duration * 1000);
    }
    return () => {
      clearTimeout(time);
    };
  }, [transition, duration]);

  const active = activeIndex === index;
  const headerMessage = (
    <Message.Header>
      <Icon className={getIconClassNameByType(type)} />
      {headerMsg}
    </Message.Header>
  );

  const messagePopup = list ? (
    <StMessage
      onTransitionEnd={() => {
        if (!transition) {
          exit();
        }
      }}
      motion={transition ? "in" : "out"}
      onDismiss={() => {
        setTransition(false);
      }}
      index={index}
      {...typeObj}
      {...rest}
    >
      {headerMessage}
      <StAccordion>
        <StAccordionTitle
          index={index}
          active={active}
          onClick={(e, { index: newIndex }) =>
            setActiveIndex(activeIndex === newIndex ? -1 : newIndex)
          }
        >
          <Icon name="dropdown" />
          {message}
        </StAccordionTitle>
        <Accordion.Content
          className="accordion-content"
          index={index}
          active={active}
        >
          <ul>
            {list.map((value) => {
              return <li key={value}>{value}</li>;
            })}
          </ul>
        </Accordion.Content>
      </StAccordion>
    </StMessage>
  ) : (
    <StMessage
      onTransitionEnd={() => {
        if (!transition) {
          exit();
        }
      }}
      motion={transition ? "in" : "out"}
      onDismiss={() => {
        setTransition(false);
      }}
      index={index}
      {...typeObj}
      {...rest}
    >
      {headerMsg && headerMessage}
      {headerMsg ? (
        <StBodyMessage>{message}</StBodyMessage>
      ) : (
        <>
          <Icon className={getIconClassNameByType(type)} />
          {message}
        </>
      )}
    </StMessage>
  );

  return messagePopup;
};

ToastMessage.defaultProps = {
  duration: 5,
  type: "info",
  message: null,
  onExit: null,
  index: -1,
};
ToastMessage.propTypes = {
  duration: PropTypes.number,
  type: PropTypes.string,
  message: PropTypes.node,
  onExit: PropTypes.func,
  index: PropTypes.number,
};

export default ToastMessage;
