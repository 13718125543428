import { Colors as SharedColors } from "@mediamath/design-system-eng";

const Colors = {
  ActiveSideNavItem: "#29384a",
  AppBackground: "#F5F5F5",
  PlanningBackground: SharedColors.ColorGrey100,
  ColorHover: SharedColors.ColorBlue000,
};

export default Colors;
