import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";

const CommonMessages = defineMessages({
  ActiveStatus: {
    id: "creatives.module.creativeGroups.active.status",
    defaultMessage: "Active",
  },
  CreativeId: {
    id: "creatives.module.creative.id",
    defaultMessage: "Creative ID",
  },
  InactiveStatus: {
    id: "creatives.module.creativeGroups.inactive.status",
    defaultMessage: "Inactive",
  },
  StrategiesTargetedSidebarHeader: {
    id: "creatives.module.strategy_sidebar_header",
    defaultMessage: "Strategy Details",
  },
  ViewStrategies: {
    id: "creatives.module.view_strategies",
    defaultMessage: "View Strategies",
  },
  SaveButton: {
    id: "creatives.module.button.save",
    defaultMessage: "Save",
  },
  CancelButton: {
    id: "creatives.module.button.cancel",
    defaultMessage: "Cancel",
  },
  AcceptButton: {
    id: "creatives.module.button.accept",
    defaultMessage: "Accept",
  },
  EditCreativeGroupButton: {
    id: "creatives.module.button.editCreativeGroup",
    defaultMessage: "Edit Creative Group",
  },
  EditCreativeButton: {
    id: "creatives.module.button.editCreative",
    defaultMessage: "Edit Creative",
  },
  PreviewButton: {
    id: "creatives.module.button.preview",
    defaultMessage: "Preview Creative",
  },
  CreativePreview: {
    id: "creatives.module.sidepanel.header",
    defaultMessage: "Creative Preview",
  },
  ChangeLog: {
    id: "creatives.module.button.changeLog",
    defaultMessage: "View Change Log",
  },
  CreativeTypeVideo: {
    id: "creatives.module.type.video",
    defaultMessage: "Video",
  },
  CreativeTypeAudio: {
    id: "creatives.module.type.audio",
    defaultMessage: "Audio",
  },
  CreativeTypeDisplay: {
    id: "creatives.module.type.display",
    defaultMessage: "Display",
  },
  CreativeTypeNative: {
    id: "creatives.module.type.native",
    defaultMessage: "Native",
  },
  SecureLabel: {
    id: "edit.display.creative.sidepanel.secure.label",
    defaultMessage: "Secure",
  },
  DimensionLabel: {
    id: "creatives.module.creativesPreview.dimensionLabel",
    defaultMessage: "Dimensions",
  },
  Advertiser: {
    id: "creatives.module.advertiser",
    defaultMessage: "Advertiser",
  },
  name: {
    id: "creatives.module.creativeName",
    defaultMessage: "Creative Name",
  },
  VendorsLabel: {
    id: "creatives.module.vendorsLabel",
    defaultMessage: "Vendors",
  },
  AutoDetectedVendorsLabel: {
    id: "creatives.module.autoDetectedVendorsLabel",
    defaultMessage: "Auto-Detected Vendors",
  },
  AdserverLabel: {
    id: "creatives.module.adserverLabel",
    defaultMessage: "Ad Server",
  },
  CreativeNameLengthValidationMessage: {
    id: "creatives.module.form.text.field.length.validation",
    defaultMessage: "Must be less than 256 characters",
  },
  CreativeTextFieldValidationMessage: {
    id: "creatives.module.form.text.field.validation",
    defaultMessage: "Creative Name is required",
  },
  CreativeAdvertiserFieldRequiredMessage: {
    id: "creatives.module.form.advertiser.required",
    defaultMessage: "Advertiser is required",
  },
  BrowseLabel: {
    id: "creatives.module.browseLabel",
    defaultMessage: "Browse",
  },
  StatusLabel: {
    id: "creatives.module.statuslabel",
    defaultMessage: "Status",
  },
  ClickthroughUrl: {
    id: "creatives.module.clickthroughUrl",
    defaultMessage: "Clickthrough Url",
  },
  YesLabel: {
    id: "creatives.module.yeslabel",
    defaultMessage: "Yes",
  },
  NoLabel: {
    id: "creatives.module.nolabel",
    defaultMessage: "No",
  },
  AdFormat: {
    id: "creatives.module.adformat",
    defaultMessage: "Ad Format",
  },
  StartDate: {
    id: "creatives.module.startdate",
    defaultMessage: "Start Date",
  },
  EndDate: {
    id: "creatives.module.enddate",
    defaultMessage: "End Date",
  },
  GroupName: {
    id: "creatives.module.groupname",
    defaultMessage: "Group Name",
  },
  AllTextLabel: {
    id: "creatives.module.alltextlabel",
    defaultMessage: "All",
  },
  ErrorHeading: {
    id: "creative.form.error.heading",
    defaultMessage: "Error",
  },
  MediaMathHelpCenter: {
    id: "creatives.module.mediaMath.help.center",
    defaultMessage: "MediaMath Help Center",
  },
  MissingLabel: {
    id: "creatives.module.value.missing",
    defaultMessage: "Missing",
  },
  NoResultsFound: {
    id: "creatives.module.no.results.found",
    defaultMessage: "No results found.",
  },
  VendorsIssueTooltip: {
    id: "creatives.module.vendors.issue.tooltip",
    defaultMessage:
      "This creative will not run on Adx inventory with {plural, select, true {} other {the}} {vendorWithIssues} vendor/domain{plural, select, true {(s)} other {}}",
    parameterized: true,
  },
});
i18nFormat(CommonMessages);
export default CommonMessages;
