import { defineMessages } from "react-intl";

const CreativesModuleMessages = defineMessages({
  CreativesHeader: {
    id: "creatives.module.header.name",
    defaultMessage: "Creatives",
  },
  CreativesTabLabel: {
    id: "creatives.module.tab.creatives",
    defaultMessage: "Creatives",
  },
  CreativeGroupsTabLabel: {
    id: "creatives.module.tab.creativeGroups",
    defaultMessage: "Creative Groups",
  },
});

export default CreativesModuleMessages;
