import useIds from "core/shared/hooks/useIds";
import useStreamList from "core/shared/hooks/useStreamList";
import { getOrganizationList } from "core/shared/util/marvellStreamingConnection";

const useOrgList = ({ search, status }) => {
  const { isLoading, isStreaming, data } = useStreamList({
    streamFactory: getOrganizationList,
    dataEvent: "organizations",
    params: { search, status },
    dataPath: "data.organizations",
  });

  return {
    isLoading,
    isStreaming,
    organizations: data,
    organizationsIds: useIds(data),
  };
};

export default useOrgList;
