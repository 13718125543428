import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
} from "darkreader";
import { Toggle } from "@mediamath/design-system-eng";
import useLocalStorage from "core/shared/hooks/useLocalStorage";
import { useSideNav } from "core/app/hooks/useSideNav";
import appMessages from "core/app/intl/AppMessages";

const DARK = "dark";
const LIGHT = "light";
const LOCAL_STORAGE_KEY = "theme";

const { darkTheme, lightTheme } = appMessages;

const ThemeToggle = () => {
  const { formatMessage } = useIntl();
  const { setTheme } = useSideNav();
  const labelLight = formatMessage(lightTheme);
  const labelDark = formatMessage(darkTheme);
  const [label, setLabel] = useState("");
  const [toggleValue, setToggleValue] = useState(false);
  const [savedTheme, saveTheme] = useLocalStorage(LOCAL_STORAGE_KEY, LIGHT);

  const turnOnDarkMode = useCallback(() => {
    enableDarkMode({ brightness: 100, contrast: 90, sepia: 10 });
    setLabel(labelDark);
    saveTheme(DARK);
    setTheme(DARK);
  }, [labelDark, saveTheme, setTheme]);

  const turnOffDarkMode = useCallback(() => {
    disableDarkMode();
    setLabel(labelLight);
    saveTheme(LIGHT);
    setTheme(LIGHT);
  }, [labelLight, saveTheme, setTheme]);

  const handleToggle = useCallback(() => {
    const toggleTheme = toggleValue ? turnOffDarkMode : turnOnDarkMode;
    toggleTheme();
    setToggleValue(!toggleValue);
  }, [toggleValue, turnOffDarkMode, turnOnDarkMode]);

  useEffect(() => {
    const isDarkTheme = savedTheme === DARK;
    const toggleTheme = isDarkTheme ? turnOnDarkMode : turnOffDarkMode;
    toggleTheme();
    setToggleValue(isDarkTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedTheme]);

  return (
    <Toggle
      checked={toggleValue}
      label={label}
      name="theme-toggle"
      onChange={handleToggle}
    />
  );
};

export default ThemeToggle;
