import { defineMessages } from "react-intl";

const CampaignMessages = defineMessages({
  AddToFavorite: {
    defaultMessage: "Add to Favorites",
    id: "campaign.addToFavorite",
  },
  AdvertiserTooltip: {
    defaultMessage: `Org ID: {orgId}`,
    id: `campaign.troubleshooting.advertisertooltip`,
  },
  AutoSpendDailyCapPacing: {
    defaultMessage: "(Auto)",
    id: "campaign.chart.autospenddailycappacing",
  },
  BudgetHeader: {
    defaultMessage: "Budget",
    id: `campaign.troubleshooting.list.budget`,
  },
  BulkChartError: {
    defaultMessage: "Please try reselecting the following campaigns",
    id: "campaign.chart.bulkChartError",
  },
  BulkChartErrorHeader: {
    defaultMessage: "Something went wrong on our side.",
    id: "campaign.chart.bulkChartErrorHeader",
  },
  BulkChartGeneralError: {
    defaultMessage:
      "Something went wrong on our side. Please try reselecting the campaign to chart again.",
    id: "campaign.chart.general.chartError",
  },
  CampaignAddToFavoriteError: {
    defaultMessage:
      "Selected campaigns could not be added to Favorites. Please try again.",
    id: "campaign.addToFavorite.error",
  },
  CampaignRemoveFromFavoriteError: {
    defaultMessage:
      "Selected campaigns could not be removed from Favorites. Please try again.",
    id: "campaign.removeFromFavorite.error",
  },
  CampaignAddToFavoriteSuccess: {
    defaultMessage: `{counter, plural, one {# campaign} other {# campaigns}} added to Favorites`,
    id: "campaign.addToFavorite.success",
    parameterized: true,
  },
  CampaignAddToFavoriteWarning: {
    defaultMessage: `Selected {counter, plural, one {campaign} other {campaigns}} cannot be added because {counter, plural, one {it is} other {they are}} already in Favorites`,
    id: "campaign.addToFavorite.warning",
    parameterized: true,
  },
  CampaignsCount: {
    id: `campaign.troubleshooting.count`,
    defaultMessage: `{numOfCampaigns, number} active  {numOfCampaigns, plural,
             one { campaign }
             other { campaigns }
            }`,
  },
  CampaignCreateHeader: {
    defaultMessage: `New Campaign`,
    id: `campaign.troubleshooting.header.campaignCreate`,
  },
  CampaignDailyPacingAt: {
    defaultMessage: "Pacing at {value}",
    id: `campaign.troubleshooting.list.campaignflightdailyat`,
  },
  CampaignDailyPacingBudget: {
    defaultMessage: "Spend to Pace at {value}",
    id: `campaign.troubleshooting.list.campaigndailypacingbudget`,
  },
  CampaignDailyImpressionPacingBudget: {
    defaultMessage: "Impressions to Pace at {value}",
    id: `campaign.troubleshooting.list.campaigndailyimpressionbudget`,
  },
  CampaignDailyPacingHeader: {
    defaultMessage: "Daily Pacing",
    id: `campaign.troubleshooting.list.campaigndailypacing`,
  },
  CampaignFlightPacingAt: {
    defaultMessage: "Flight Pacing at {value}",
    id: `campaign.troubleshooting.list.campaignflightpacingat`,
  },
  CampaignFlightPacingBudget: {
    defaultMessage: "Budget of {value}",
    id: `campaign.troubleshooting.list.campaignflightpacingbudget`,
  },
  CampaignFlightPacingHeader: {
    defaultMessage: "Flight Pacing",
    id: `campaign.troubleshooting.list.campaignflightpacing`,
  },
  CampaignHideChart: {
    defaultMessage: "Hide Chart",
    id: "campaign.troubleshooting.chart.hide",
  },
  CampaignIdHeader: {
    defaultMessage: "ID",
    id: `campaign.troubleshooting.list.campaignid`,
  },
  CampaignKebabMenuChangeLog: {
    defaultMessage: "View Change Log",
    id: "campaign.CampaignKebabMenuChangeLog",
  },
  CampaignNameHeader: {
    defaultMessage: "Campaign Name",
    id: `campaign.troubleshooting.list.campaignname`,
  },
  CampaignNameTooltip: {
    defaultMessage: "View strategies",
    id: "campaign.CampaignNameTooltip",
  },
  CampaignRemovedFromFavoriteSuccess: {
    defaultMessage: `{counter, plural, one {# campaign} other {# campaigns}} removed from Favorites`,
    id: "campaign.removeFromFavorite.success",
    parameterized: true,
  },
  CampaignRemovedFromFavoriteWarning: {
    defaultMessage: `Selected {counter, plural, one {campaign} other {campaigns}} cannot be removed because {counter, plural, one {it is} other {they are}} not in Favorites`,
    id: "campaign.removeFromFavorite.warning",
    parameterized: true,
  },
  CampaignReport: {
    defaultMessage: "View Report",
    id: "campaign.campaignReport",
  },
  CampaignsAddCampaign: {
    defaultMessage: "New Campaign",
    id: "campaign.module.AddCampaign",
  },
  CampaignsAddCampaignBeta: {
    defaultMessage: "New Campaign (Beta)",
    id: "campaign.module.AddCampaignBeta",
  },
  CampaignsAddCampaignLegacy: {
    defaultMessage: "New Campaign (Legacy)",
    id: "campaign.module.AddCampaignLegacy",
  },
  CampaignsCreateHeader: {
    defaultMessage: "Create",
    id: "campaign.module.campaigns",
  },
  CampaignsHeader: {
    defaultMessage: "Campaigns",
    id: "campaign.module.header.campaigns",
  },
  CampaignShowChart: {
    defaultMessage: "Show Chart",
    id: "campaign.troubleshooting.chart.show",
  },
  CampaignsTableView: {
    defaultMessage: "View by:",
    id: "campaign.module.campaignsTableView",
  },
  CampaignStatusFilterActive: {
    defaultMessage: `Active`,
    id: "campaign.troubleshooting.filter.statusActive",
  },
  CampaignStatusFilterInactive: {
    defaultMessage: `Inactive`,
    id: "campaign.troubleshooting.filter.statusInactive",
  },
  CampaignStatusFilterRunning: {
    defaultMessage: `Running`,
    id: "campaign.troubleshooting.filter.statusRunning",
  },
  CampaignStatusFilterLive: {
    defaultMessage: `Live`,
    id: "campaign.troubleshooting.filter.statusLive",
  },
  CampaignStatusFilterArchived: {
    defaultMessage: `Archived`,
    id: "campaign.troubleshooting.filter.statusArchived",
  },
  CampaignStatusHeader: {
    defaultMessage: "Status",
    id: `campaign.troubleshooting.list.campaignStatus`,
  },
  CampaignLastUpdatedHeader: {
    defaultMessage: "Last Updated",
    id: `campaign.module.header.lastUpdated`,
  },
  CampaignDateCreatedHeader: {
    defaultMessage: "Date Created",
    id: `campaign.module.header.dateCreated`,
  },
  ChartBackBtn: {
    defaultMessage: "Back",
    id: "campaign.chart.chartBackBtn",
  },
  ChartError: {
    defaultMessage:
      "Something went wrong on our side. Please try charting {name} again.",
    id: "campaign.chart.chartError",
  },
  Clicks: {
    defaultMessage: "Clicks",
    id: "campaign.Clicks",
  },
  Ctc: {
    defaultMessage: "CTC",
    id: "campaign.Ctc",
  },
  Ctr: {
    defaultMessage: "CTR",
    id: "campaign.Ctr",
  },
  CustomColumnsEditViewTitle: {
    defaultMessage: "Edit Custom View",
    id: "campaign.columns.customColumnsEditViewTitle",
  },
  CustomizeColumnsTitle: {
    defaultMessage: "New Custom View",
    id: "campaign.columns.customizeColumnsTitle",
  },
  CustomPlaceholder: {
    defaultMessage: "(Custom)",
    id: "campaign.module.CustomPlaceholder",
  },
  Day: {
    defaultMessage: "Day",
    id: "campaign.chart.day",
  },
  DaysLeftHeader: {
    defaultMessage: "Days Left",
    id: `campaign.troubleshooting.list.daysleft`,
  },
  EditCampaign: {
    defaultMessage: "Edit Campaign",
    id: "campaign.editCampaign",
  },
  EditCampaignMMBeta: {
    defaultMessage: "Edit Campaign MM Beta",
    id: "campaign.editCampaignBeta",
  },
  EditCampaignLegacy: {
    defaultMessage: "Edit Campaign (Legacy)",
    id: "campaign.editCampaignLegacy",
  },
  DuplicateCampaign: {
    defaultMessage: "Duplicate Campaign",
    id: "campaign.duplicateCampaign",
  },
  EndDate: {
    defaultMessage: "End Date",
    id: "campaign.chart.exportColumn.endDate",
  },
  EndDateHeader: {
    defaultMessage: "Flight End Date",
    id: `campaign.troubleshooting.list.endDate`,
  },
  ExportAllMetrics: {
    defaultMessage: "Export All Metrics",
    id: "campaign.chart.exportAllMetrics",
  },
  ExportCurrentView: {
    defaultMessage: "Export Current View",
    id: "campaign.chart.exportCurrentView",
  },
  FavoriteCampaignsOnly: {
    defaultMessage: "Show favorites",
    id: "campaign.favoriteCampaignsOnly",
  },
  FrequencyOptimizationHeader: {
    defaultMessage: "Frequency Cap",
    id: "campaign.troubleshooting.campaigns.frequencyOptimizationHeader",
  },
  GoalActualHeader: {
    defaultMessage: "Goal Actual",
    id: `campaign.troubleshooting.list.goalactual`,
  },
  GoalActualHeaderFTD: {
    defaultMessage: "(FTD)",
    id: `campaign.troubleshooting.list.goalactualftd`,
  },
  GoalTypeHeader: {
    defaultMessage: "Goal Type",
    id: `campaign.troubleshooting.list.goaltype`,
  },
  GoalValueHeader: {
    defaultMessage: "Goal Value",
    id: `campaign.troubleshooting.list.goalvalue`,
  },
  GoalValueMaxNumberError: {
    defaultMessage: "Goal value must be at most 9,999,999.9999",
    id: "campaign.troubleshooting.campaign.inlineEdit.maxValueError",
  },
  GoalValuePercentageError: {
    defaultMessage: "Please enter a value between 0.01% and 100%",
    id: "campaign.troubleshooting.campaign.inlineEdit.percentegeError",
  },
  HideNoDataIndicator: {
    defaultMessage: "Hide No-Data Indicator",
    id: "campaign.chart.hideNoDataIndicator",
  },
  hour: {
    defaultMessage: "Hour",
    id: "campaign.chart.hour",
  },
  IdealSpend: {
    defaultMessage: "Spend (Ideal)",
    id: "campaign.chart.spend.ideal",
  },
  IdealImpressions: {
    defaultMessage: "Impressions (Ideal)",
    id: "campaign.IdealImpressions",
  },
  ImpressionsActual: {
    defaultMessage: "Impressions (Actual)",
    id: "campaign.Impressions.actual",
  },
  Impressions: {
    defaultMessage: "Impressions",
    id: "campaign.Impressions",
  },
  ImpressionsRemaining: {
    defaultMessage: "Impressions Remaining",
    id: "campaign.ImpressionsRemaining",
  },
  ImpressionsToPace: {
    defaultMessage: "Impressions To Pace",
    id: "campaign.ImpressionsToPace",
  },
  ImpressionsYesterday: {
    defaultMessage: "Impressions Yesterday",
    id: "campaign.ImpressionsYesterday",
  },
  InlineEditConnectionError: {
    defaultMessage: "{campaignName} was not updated due to a connection error.",
    id: "campaign.troubleshooting.campaign.inline-edit.connection-error",
  },
  InlineEditGenericError: {
    defaultMessage: "{campaignName} was not updated due to internal errors.",
    id: "campaign.troubleshooting.campaign.inline-edit.generic-error",
  },
  InlineEditSuccess: {
    defaultMessage: "was successfully updated",
    id: "campaign.troubleshooting.campaign.inline-edit.success",
  },
  ManagedByOption: {
    defaultMessage: `Managed`,
    id: `campaign.troubleshooting.list.managedBy`,
  },
  ManagedBySelfOption: {
    defaultMessage: `Self`,
    id: `campaign.troubleshooting.list.managedBySelf`,
  },
  ManualSpendDailyCapPacing: {
    defaultMessage: "(Manual)",
    id: "campaign.chart.manualSpendDailyCapPacing",
  },
  MarginHeader: {
    defaultMessage: "Margin",
    id: "campaign.margin",
  },
  MarginPctHeader: {
    defaultMessage: "Margin Percent",
    id: "campaign.marginPct",
  },
  MaxCampaign: {
    defaultMessage: "No more than 10 campaigns can be charted",
    id: "campaign.chart.maxCampaign",
  },
  MaxTotalImpressionBudgetError: {
    defaultMessage: "Impression Budget must be less than or equal to 99B",
    id: "campaign.inline-edit.maxTotalImpressionBudgetError",
  },
  MinTotalImpressionBudgetError: {
    defaultMessage: "Impression Budget must be greater than or equal to 1",
    id: "campaign.inline-edit.minTotalImpressionBudgetError",
  },
  MinImpressionsTotalImpressionBudgetError: {
    defaultMessage:
      "Impressions budget cannot be less than impressions served: {impressions}",
    id: "campaign.inline-edit.minImpressionsTotalImpressionBudgetError",
  },
  MeasurabilityRate: {
    defaultMessage: "Measurability Rate",
    id: "campaign.MeasurabilityRate",
  },
  Name: {
    defaultMessage: "Name",
    id: "campaign.chart.exportColumn.name",
  },
  Id: {
    defaultMessage: "ID",
    id: "campaign.chart.exportColumn.id",
  },
  NewViewNameTitle: {
    defaultMessage: "View Name:",
    id: "campaign.columns.NewViewNameTitle",
  },
  NoActiveCampaigns: {
    defaultMessage:
      "You have no active Campaigns under this Organization/Advertiser",
    id: "campaign.troubleshooting.noactive",
  },
  NoAdvertiser: {
    defaultMessage: "No Advertiser Selected",
    id: "campaign.module.noadvertiser.message",
  },
  NoCampaigns: {
    defaultMessage:
      "You have no active Campaigns under this Organization/Advertiser",
    id: `campaign.troubleshooting.noactive`,
  },
  NoCampaignsCount: {
    defaultMessage: `Active campaigns not loaded`,
    id: `campaign.troubleshooting.nocount`,
  },
  NoCampaignsFiltered: {
    defaultMessage: "No campaigns meet your filter criteria",
    id: `campaign.troubleshooting.noactivefilter`,
  },
  NoChartData: {
    defaultMessage: `No data is available for {name}`,
    id: "campaign.chart.noChartData",
  },
  NoChartsData: {
    defaultMessage: "No data available for the following campaigns",
    id: "campaign.chart.noChartsData",
  },
  NoChartsDataHeader: {
    defaultMessage: "No data available",
    id: "campaign.chart.noChartDataHeader",
  },
  NoInactiveCampaigns: {
    defaultMessage:
      "You have no inactive Campaigns under this Organization/Advertiser",
    id: "campaign.troubleshooting.noinactive",
  },
  NoParent: {
    defaultMessage: "No Advertiser / Organization Selected",
    id: "campaign.module.noparent.message",
  },
  NoAccess: {
    defaultMessage: `You do not have permission to view the selected Organization/Advertiser. 
      If you believe this is an error, please request access from MediaMath Support.`,
    id: "campaign.module.noaccess.message",
  },
  NoResults: {
    defaultMessage: "No results for your search",
    id: `campaign.troubleshooting.list.noResults`,
  },
  NoRunningCampaigns: {
    defaultMessage:
      "You have no Campaigns running under this Organization/Advertiser",
    id: "campaign.troubleshooting.norunning",
  },
  NotRunning: {
    defaultMessage: "Not Live",
    id: "campaign.troubleshooting.notrunning",
  },
  OverspendDailyPacing: {
    defaultMessage: "Overspend",
    id: "campaign.chart.overspenddailypacing",
  },
  PerformanceBy: {
    defaultMessage: "PERFORMANCE BY",
    id: "campaign.chart.performanceBy",
  },
  PlayRate: {
    defaultMessage: "Play Rate",
    id: "campaign.PlayRate",
  },
  PostClickConversions: {
    defaultMessage: "Post-Click Conversions",
    id: "campaign.PostClickConversions",
  },
  PostClickRevenue: {
    defaultMessage: "Post-Click Revenue",
    id: "campaign.PostClickRevenue",
  },
  PostViewConversions: {
    defaultMessage: "Post-View Conversions",
    id: "campaign.PostViewConversions",
  },
  PostViewRevenue: {
    defaultMessage: "Post-View Revenue",
    id: "campaign.PostViewRevenue",
  },
  PostClickTotalSpendRoi: {
    defaultMessage: "Post-Click Total Spend ROI",
    id: "campaign.PostClickTotalSpendRoi",
  },
  PostViewTotalSpendRoi: {
    defaultMessage: "Post-View Total Spend ROI",
    id: "campaign.PostViewTotalSpendRoi",
  },
  TotalSpendRoi: {
    defaultMessage: "Total Spend ROI",
    id: "campaign.TotalSpendRoi",
  },
  RemainingBudgetHeader: {
    defaultMessage: "Budget Remaining",
    id: `campaign.troubleshooting.list.remaining.budget`,
  },
  RemainingSpend: {
    defaultMessage: "Remaining",
    id: "campaign.chart.spend.remaining",
  },
  RemoveFromFavorite: {
    defaultMessage: "Remove from Favorites",
    id: "campaign.removeFromFavorite",
  },
  ReportingCallInterrupted: {
    defaultMessage: "Reporting call interrupted",
    id: "campaign.chart.reportingCallInterrupted",
  },
  ResetChart: {
    defaultMessage: "Reset Chart",
    id: "campaign.chart.resetChart",
  },
  SelectMetric: {
    defaultMessage: "Select a Metric",
    id: "campaign.chart.selectMetric",
  },
  ShowNoDataIndicator: {
    defaultMessage: "Show No-Data Indicator",
    id: "campaign.chart.showNoDataIndicator",
  },
  Spend: {
    defaultMessage: "Spend ($)",
    id: "campaign.chart.spend",
  },
  SpendDailyCapPacing: {
    defaultMessage: "Daily Spend Cap",
    id: "campaign.chart.spenddailycappacing",
  },
  ImpressionDailyCapPacing: {
    defaultMessage: "Daily Impressions Cap",
    id: "campaign.chart.impressiondailycappacing",
  },
  SpendDailyPacing: {
    defaultMessage: "Spend Yesterday",
    id: "campaign.chart.spenddailypacing",
  },
  ImpressionsDailyPacing: {
    defaultMessage: "Impressions Yesterday",
    id: "campaign.chart.impressiondailypacing",
  },
  SpendFlightPacing: {
    defaultMessage: "Spend (Actual)",
    id: "campaign.chart.spendflightpacing",
  },
  StartDate: {
    defaultMessage: "Start Date",
    id: "campaign.chart.exportColumn.startDate",
  },
  StartDateDisabled: {
    defaultMessage:
      "Start date cannot be changed because chosen Campaign has started",
    id: "campaign.startDateDisabled",
  },
  StartDateHeader: {
    defaultMessage: "Flight Start Date",
    id: `campaign.troubleshooting.list.startDate`,
  },
  TimeZone: {
    defaultMessage: "Time Zone",
    id: "campaign.chart.exportColumn.timezone",
  },
  TotalConversions: {
    defaultMessage: "Total Conversions",
    id: "campaign.TotalConversions",
  },
  TotalImpressionBudget: {
    defaultMessage: "Impressions Budget",
    id: "campaign.TotalImpressionBudget",
  },
  TotalRevenue: {
    defaultMessage: "Total Revenue",
    id: "campaign.TotalRevenue",
  },
  TotalSpend: {
    defaultMessage: "Total Spend",
    id: "campaign.TotalSpend",
  },
  TotalSpendTooltip: {
    defaultMessage:
      "Represents all possible amounts to go into the 'Total Spend' of your campaign.",
    id: "campaign.TotalSpendTooltip",
  },
  TotalSpendCpa: {
    defaultMessage: "Total Spend eCPA",
    id: "campaign.TotalSpendCpa",
  },
  TotalSpendCpc: {
    defaultMessage: "Total Spend eCPC",
    id: "campaign.TotalSpendCpc",
  },
  TotalSpendCpm: {
    defaultMessage: "Total Spend eCPM",
    id: "campaign.TotalSpendCpm",
  },
  TotalSpendHeader: {
    defaultMessage: "Spend",
    id: `campaign.troubleshooting.list.totalspend`,
  },
  "TotalSpendHeader.yesterday": {
    defaultMessage: "Spend Yesterday",
    id: `campaign.troubleshooting.list.totalspend.yesterday`,
  },
  TotalSpendVcpm: {
    defaultMessage: "Total Spend vCPM",
    id: "campaign.TotalSpendVcpm",
  },
  UnderspendDailyPacing: {
    defaultMessage: "Underspend",
    id: "campaign.chart.underspenddailypacing",
  },
  VideoCompleteRate: {
    defaultMessage: "100% Completed Rate",
    id: "campaign.VideoCompleteRate",
  },
  VideoCompleteRateImpressionBased: {
    defaultMessage: "VCR (Impression Based)",
    id: "campaign.VideoCompleteRateImpressionBased",
  },
  VideoFirstQuartileRate: {
    defaultMessage: "25% Completed Rate",
    id: "campaign.VideoFirstQuartileRate",
  },
  VideoMidpointQuartileRate: {
    defaultMessage: "50% Completed Rate",
    id: "campaign.VideoMidpointQuartileRate",
  },
  VideoThirdQuartileRate: {
    defaultMessage: "75% Completed Rate",
    id: "campaign.VideoThirdQuartileRate",
  },
  ViewabilityRate: {
    defaultMessage: "Viewability Rate",
    id: "campaign.ViewabilityRate",
  },
  ViewabilityRate100Percent: {
    defaultMessage: "100% In View Rate",
    id: "campaign.ViewabilityRate100Percent",
  },
  Week: {
    defaultMessage: "Week",
    id: "campaign.chart.week",
  },
  CampaignImpressionFlightPacingHeader: {
    defaultMessage: "Impressions Flight Pacing",
    id: `campaign.troubleshooting.list.campaignImpressionFlightPacing`,
  },
  CampaignImpressionDailyPacingHeader: {
    defaultMessage: "Impressions Daily Pacing",
    id: `campaign.troubleshooting.list.campaignImpressionDailyPacing`,
  },
  OverImpressionDailyPacing: {
    defaultMessage: "Over",
    id: `campaign.troubleshooting.list.campaignOverImpressionDailyPacing`,
  },
  UnderImpressionDailyPacing: {
    defaultMessage: "Under",
    id: `campaign.troubleshooting.list.campaignUnderImpressionDailyPacing`,
  },
  AgencyReporterInfoBanner: {
    defaultMessage:
      "This page is view-only in your role as Agency Reporter. Please {supportLink} for any assistance.",
    id: `campaign.role.agencyReporterInfoBanner`,
  },
  InternalReporterInfoBanner: {
    defaultMessage:
      "This page is view-only in your role as Mediamath Restricted Reporter. Please {supportLink} for any assistance.",
    id: `campaign.role.internalReporterInfoBanner`,
  },
  InternalManagerInfoBanner: {
    defaultMessage:
      "This page is view-only in your role as Mediamath Restricted Manager. Please {supportLink} for any assistance.",
    id: `campaign.role.internalManagerInfoBanner`,
  },
  AgencyManagerInfoBanner: {
    defaultMessage:
      "This page is view-only in your role as Agency Manager. Please {supportLink} for any assistance.",
    id: `campaign.role.agencyManagerInfoBanner`,
  },
  AgencyAdminInfoBanner: {
    defaultMessage:
      "This page is view-only in your role as Agency Admin. Please {supportLink} for any assistance.",
    id: `campaign.role.agencyAdminInfoBanner`,
  },
  HeaderCheckboxTooltip: {
    defaultMessage: `{rowsSelected} rows selected. Up to {checkLimit} rows can be selected at once.`,
    id: "campaign.table.headerCheckboxTooltip",
  },
  CampaignStrategySearchPlaceholder: {
    defaultMessage: `Search strategy `,
    id: `campaign.strategy.searchPlaceholder`,
  },
  CampaignStrategySearchDefault: {
    defaultMessage: `Search strategy name`,
    id: `campaign.strategy.searchPlaceholderDefault`,
  },
  CampaignNameLockTooltipInternalManager: {
    defaultMessage:
      "This Campaign is view-only in your role as MediaMath Restricted Manager. Please {supportLink} to request editing of Campaigns managed by T1 Client.",
    id: `campaignname.lockTooltip.internalManager`,
  },
  contactSupportLinkText: {
    defaultMessage: "contact Support",
    id: `contact.supportLinkText`,
  },
  CampaignNameLockTooltipAgencyManager: {
    defaultMessage:
      "This Campaign is view-only in your role as Agency Manager. Please {supportLink} to request editing of Campaigns managed by MediaMath.",
    id: `campaignname.lockTooltip.agencyManager`,
  },
  CampaignNameLockTooltipAgencyAdmin: {
    defaultMessage:
      "This Campaign is view-only in your role as Agency Admin. Please {supportLink} to request editing of Campaigns managed by MediaMath.",
    id: `campaignname.lockTooltip.agencyAdmin`,
  },
  CampaignStrategySearchWarning: {
    defaultMessage:
      "Search term is too short. Please type 2 or more characters to search for strategies.",
    id: `campaign.strategy.searchWarning`,
  },
  ViewCampaignSummary: {
    defaultMessage: "View Summary",
    id: "campaign.view.campaign.summary",
  },
  CampaignSummaryHeader: {
    defaultMessage: "Summary",
    id: "campaign.campaign.summary.header",
  },
  CampaignDuplicateHeader: {
    defaultMessage: "Duplicate Campaign",
    id: "campaign.campaign.duplicate.header",
  },
  AdjustMargin: {
    defaultMessage: "Adjust Margin",
    id: "campaign.view.campaign.margin",
  },
  StartEndToplineHeader: {
    defaultMessage: "Campaign Start — End",
    id: "campaign.view.campaign.margin.topline",
  },
  AdjustMarginInfo: {
    defaultMessage:
      "The margin field is only adjustable for the previous 5 days and will not be reflected in reports until tomorrow",
    id: "campaign.view.campaign.margin.info",
  },
  Date: {
    defaultMessage: "Date",
    id: "campaign.view.campaign.margin.columns.date",
  },
  Margin: {
    defaultMessage: "Margin %",
    id: "campaign.view.campaign.margin.columns.margin",
    parameterized: true,
  },
  MarginTooltip: {
    defaultMessage:
      "Enter client margin as percent on Total Ad Cost. Note: This does not factor in MediaMath Fees.",
    id: "campaign.view.campaign.margin.columns.marginTooltip",
  },
  TotalAdCost: {
    defaultMessage: "Total Ad Cost",
    id: "campaign.view.campaign.margin.columns.totalAdCost",
  },
  TotalAdCostTooltip: {
    defaultMessage:
      "The campaign spend before including MediaMath fees and client margin. See the {addCostKnowledgeBase} for more information.",
    id: "campaign.view.campaign.margin.columns.totalAdCostTooltip",
    parameterized: true,
  },
  ECPM: {
    defaultMessage: "eCPM",
    id: "campaign.view.campaign.margin.columns.eCPM",
  },
  espmTooltip: {
    defaultMessage:
      "Total Spend eCPM is the effective cost per thousand impressions based on Total Spend.",
    id: "campaign.view.campaign.margin.columns.eCPMTooltip",
  },
  GoalPerformance: {
    defaultMessage: "Goal Performance",
    id: "campaign.view.campaign.margin.columns.goalPerformance",
  },
  OnTotalAdCost: {
    defaultMessage: "on Total Ad Cost",
    id: "campaign.view.campaign.margin.columns.onTotalAdCost",
  },
  MarginSaveSuccess: {
    defaultMessage: "Margin saved successfully",
    id: "campaign.view.campaign.margin.save.success",
  },
  MarginInputError: {
    defaultMessage:
      "Maximum value is 9,999,999.9999. Please enter a valid margin %",
    id: "campaign.view.campaign.margin.input.error",
  },
  Totals: {
    defaultMessage: "Totals",
    id: "campaign.view.campaign.margin.columns.totals",
  },
  KnowledgeBaseLink: {
    defaultMessage: "Knowledge Base",
    id: "campaign.view.campaign.margin.columns.knowladgeBase",
  },
});

export default CampaignMessages;
