export const browserLanguage = navigator.language.substr(0, 2);
export const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * The key where the selected language is stored in localStorage
 */
export const selectedLanguageKey = "selectedLanguage";

/**
 *
 * The key where the selected time zone is stored in localStorage
 */
export const selectedTimeZoneKey = "selectedTimeZone";

/**
 * Get the selected language in localStorage
 */
export const getSelectedLanguage = () => {
  return localStorage.getItem(selectedLanguageKey) || "en";
};

/**
 * Set the selected language in localStorage
 * @param language
 */
export const setSelectedLanguage = (language) => {
  localStorage.setItem(selectedLanguageKey, language);
};

/**
 * Get the selected time zone in localStorage
 */
export const getSelectedTimeZone = () => {
  return localStorage.getItem(selectedTimeZoneKey);
};

/**
 * Set the selected language in localStorage
 * @param language
 */
export const setSelectedTimeZone = (language) => {
  localStorage.setItem(selectedTimeZoneKey, language);
};
