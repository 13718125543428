import React, { useState, useMemo } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useFlagVariations } from "@flopflip/react-broadcast";
import styled from "@emotion/styled";
import _ from "lodash";
import packageJson from "package.alias.json";
import {
  Colors,
  StMenuContainer,
  StInnerMenuContainer,
  Icon,
  StHorizontalStroke,
  Toggle,
} from "@mediamath/design-system-eng";
import { useLocation } from "core/shared/hooks/useStableRouter";
import { buildSupportUrl } from "core/app/util/SupportDefs";
import useUrlEntities from "core/shared/hooks/useUrlEntities";
import SmartLink from "core/shared/util/SmartLink";
import appMessages from "core/app/intl/AppMessages";
import AppFeatureFlags from "core/app/util/AppFeatureFlags";
import AppColors from "core/shared/presenter/SharedAppColors";
import { useSideNav } from "core/app/hooks/useSideNav";
import useLegacyPlatformView from "core/app/hooks/useLegacyPlatformView";
import ThemeToggle from "core/app/presenter/ThemeToggle";

// Styled Items
const StMenuItem = styled("div")`
  width: 100%;
  display: ${(props) => (props.block ? "block" : "flex")};
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  background: ${(props) =>
    props.active ? AppColors.ActiveSideNavItem : "none"};

  & > * {
    color: ${Colors.ColorWhite} !important;
    opacity: ${(props) => (props.active ? "1.0" : "0.5")} !important;
  }
`;

const StMenuText = styled("span")`
  margin: ${(props) => (props.indent ? "18px 0 18px 6px" : "18px 0")};
  display: block;

  &:hover {
    color: ${Colors.ColorWhite} !important;
  }
`;

const StMenuIcon = styled(Icon)`
  transform: ${(props) =>
    props.expanded ? "rotate(-90deg)" : "rotate(90deg)"};
  transition: transform 300ms ease-in-out;
  font-size: 9px !important;
  line-height: 9px;
  width: 9px !important;
  height: 9px !important;
  margin: 0 !important;
  display: block;
  cursor: pointer;
`;

const StSubNavContainer = styled("div")`
  background: ${AppColors.ActiveSideNavItem};
  overflow: hidden;
  transition: ${(props) =>
    props.expanded ? "max-height 500ms ease-in" : "max-height 500ms ease-out"};
  height: auto;
  max-height: ${(props) => (props.expanded ? "500px" : "0px")};
`;

const StUserSpan = styled("span")`
  font-size: 10px;
  display: block;
`;

const StStaticContainer = styled("div")`
  padding: 0 18px;
  & > * {
    color: ${Colors.ColorWhite} !important;
    opacity: 0.5 !important;
    margin: 9px 0;
    display: block;
  }
`;

const StFlexMenuContainer = styled(StInnerMenuContainer)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const StVersionNumber = styled(StMenuItem)`
  alig-self: auto;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: baseline;
`;

const StToggleBtn = styled(StMenuItem)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: -3px;
  .ui.checkbox,
  .ui.toggle.checkbox input.hidden + label {
    color: ${Colors.ColorWhite} !important;
  }
  .ui.toggle.checkbox label:before {
    height: 19px !important;
  }
`;

const refreshPage = () => window.location.reload(false);

const LeagacyPlatformToggle = () => {
  const intl = useIntl();
  const { isLegacyPlatform, setIsLegacyPlatform } = useLegacyPlatformView();
  const [toggleValue, setToggleValue] = useState(isLegacyPlatform);

  const handleChange = () => {
    const value = !toggleValue;
    setIsLegacyPlatform(value);
    setToggleValue(value);
    setTimeout(() => refreshPage(), 200);
  };

  return (
    <StToggleBtn>
      <Toggle
        checked={toggleValue}
        label={intl.formatMessage(appMessages.legacyPlatform)}
        name="legacy_platform"
        onChange={handleChange}
      />
    </StToggleBtn>
  );
};

// Version Number
const VersionNumber = () => (
  <StVersionNumber block>
    <StUserSpan>
      Version:
      {packageJson.version ? packageJson.version : "--"}
    </StUserSpan>
    <StUserSpan>
      Commit:
      {packageJson.commit ? packageJson.commit.substring(0, 7) : "--"}
    </StUserSpan>
  </StVersionNumber>
);

// Static Link
const StaticLink = ({ title, url }) => (
  <StStaticContainer>
    <a href={url} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  </StStaticContainer>
);

StaticLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

// Ext Link
const ExtLink = ({ children = null, to = null, indent = false }) => (
  <a href={to} target="_blank" rel="noopener noreferrer">
    <StMenuText indent={indent}>{children}</StMenuText>
  </a>
);

ExtLink.propTypes = {
  children: PropTypes.node,
  indent: PropTypes.number,
  to: PropTypes.string,
};

// Config / Util
const idUrl = "https://idlookup.mediamath.com/";
const mmaUrl = "https://academy.mediamath.com";
const kbUrl = "https://mediamathsupport.force.com/s/";

const matchPath = ({ location, matches }) => {
  return matches.some((path) => {
    return String(location.pathname).slice(0, path.length) === path;
  });
};

const getLinkType = ({ isSmartLink, isExternal }) => {
  if (isExternal) return ExtLink;
  if (isSmartLink) return SmartLink;
  return Link;
};

// Menu Item
const MenuItem = ({
  active,
  beta = false,
  betaLabel,
  data: { key, link: path, subMenu },
  expanded,
  isExternal = false,
  isSmartLink = true,
  label,
  onSelect = () => {},
  sub,
  toggleClick,
  user,
}) => {
  return (
    <StMenuItem active={active ? 1 : 0}>
      <StMenuText
        as={getLinkType({ isSmartLink, isExternal })}
        indent={sub ? 1 : 0}
        onClick={() => onSelect(key)}
        to={path}
      >
        <span>
          {label}
          {beta && (
            <sup>
              &nbsp;
              {betaLabel}
            </sup>
          )}
        </span>
        {user && <StUserSpan>{user}</StUserSpan>}
      </StMenuText>
      {subMenu ? (
        <StMenuIcon
          expanded={expanded ? 1 : 0}
          name="arrow-fill"
          onClick={() => toggleClick()}
        />
      ) : null}
    </StMenuItem>
  );
};

MenuItem.propTypes = {
  active: PropTypes.bool,
  beta: PropTypes.bool,
  betaLabel: PropTypes.string,
  data: PropTypes.shape({
    flag: PropTypes.string,
    key: PropTypes.string,
    link: PropTypes.string,
    subMenu: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  expanded: PropTypes.bool,
  isExternal: PropTypes.bool,
  isSmartLink: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  sub: PropTypes.bool,
  toggleClick: PropTypes.func,
  user: PropTypes.string,
};

const flagKeys = [
  AppFeatureFlags.ADMIN_MODULE_COMPASS,
  AppFeatureFlags.ADMIN_MODULE,
  AppFeatureFlags.AUDIENCE_MODULE_COMPASS,
  AppFeatureFlags.AUDIENCE_MODULE,
  AppFeatureFlags.CAMPAIGNS_MODULE_COMPASS,
  AppFeatureFlags.CAMPAIGNS_MODULE,
  AppFeatureFlags.CREATIVES_MODULE_COMPASS,
  AppFeatureFlags.CREATIVES_MODULE,
  AppFeatureFlags.DARK_MODE,
  AppFeatureFlags.ENTERPRISE_CONTROLS_MODULE,
  AppFeatureFlags.MEDIA_MODULE_COMPASS,
  AppFeatureFlags.PLANS_MODULE_V2,
  AppFeatureFlags.PLANS_MODULE,
  AppFeatureFlags.REPORTS_MODULE_POC,
  AppFeatureFlags.REPORTS,
  AppFeatureFlags.SIDE_NAV,
];

const buildFeatureTogglesObject = (flagValues) => {
  return flagKeys.reduce((obj, currVal, index) => {
    return { ...obj, [currVal]: flagValues[index] };
  }, {});
};

// Nav
const SideNav = ({ onSelect = () => {}, data = [] }) => {
  const intl = useIntl();
  const location = useLocation();
  const supportUrl = buildSupportUrl({
    ids: useUrlEntities(),
    pageUrl: window.location.href,
  });
  const { isLegacyPlatform, showLegacyPlatformView } = useLegacyPlatformView();

  const flagValues = useFlagVariations(flagKeys);
  const featureToggles = useMemo(() => buildFeatureTogglesObject(flagValues), [
    flagValues,
  ]);

  const { collapsed } = useSideNav();
  const { isAuthenticated, user } = useAuth0();
  const username = user?.nickname;
  const [expandedItem, setExpandedItem] = useState(null);
  const hasFeatureFlag = (flag) => _.get(featureToggles, flag, null);
  const toggleSubMenu = (key) =>
    expandedItem === key ? setExpandedItem(null) : setExpandedItem(key);

  return featureToggles[AppFeatureFlags.SIDE_NAV] ? (
    <StMenuContainer
      width={collapsed ? "0px" : "200px"}
      backgroundColor={Colors.ColorBlue800}
    >
      <StFlexMenuContainer>
        {data.map((item) => {
          const {
            beta,
            flag,
            intlKey,
            isSmartLink,
            key,
            legacy,
            matches,
            poc,
            subMenu,
          } = item;

          const isActive = matchPath({ location, matches });

          // Hide all legacy items if switch is turned off or hidden
          if (!isLegacyPlatform && legacy) return null;

          return hasFeatureFlag(flag) || !flag ? (
            <React.Fragment key={key}>
              <MenuItem
                active={isActive}
                beta={beta || poc || legacy}
                betaLabel={
                  (poc && intl.formatMessage(appMessages.poc)) ||
                  (beta && intl.formatMessage(appMessages.beta)) ||
                  (legacy && intl.formatMessage(appMessages.legacy))
                }
                data={item}
                expanded={expandedItem === key}
                isSmartLink={isSmartLink}
                key={key}
                label={intl.formatMessage(intlKey)}
                onSelect={onSelect}
                toggleClick={() => toggleSubMenu(key)}
              />
              {subMenu ? (
                <StSubNavContainer expanded={expandedItem === key}>
                  {subMenu.map((subItem) => {
                    const {
                      beta: subBeta,
                      flag: subFlag,
                      intlKey: subIntlKey,
                      isExternal: subIsExternal,
                      isSmartLink: subIsSmartLink,
                      key: subKey,
                      legacy: subLegacy,
                      matches: subMatches,
                    } = subItem;

                    const isSubActive = matchPath({
                      location,
                      matches: subMatches,
                    });

                    return hasFeatureFlag(subFlag) || !subFlag ? (
                      <MenuItem
                        key={subKey}
                        data={subItem}
                        onSelect={onSelect}
                        active={isSubActive}
                        beta={subBeta || subLegacy}
                        sub
                        label={intl.formatMessage(subIntlKey)}
                        isSmartLink={subIsSmartLink}
                        isExternal={subIsExternal}
                        betaLabel={
                          (subBeta && intl.formatMessage(appMessages.beta)) ||
                          (subLegacy && intl.formatMessage(appMessages.legacy))
                        }
                      />
                    ) : null;
                  })}
                </StSubNavContainer>
              ) : null}
            </React.Fragment>
          ) : null;
        })}
        <StHorizontalStroke color={Colors.ColorWhite} />
        <StaticLink
          title={intl.formatMessage(appMessages.idLookup)}
          url={idUrl}
        />
        <StaticLink title={intl.formatMessage(appMessages.kb)} url={kbUrl} />
        <StaticLink
          title={intl.formatMessage(appMessages.mmAcademy)}
          url={mmaUrl}
        />
        <StaticLink
          title={intl.formatMessage(appMessages.support)}
          url={supportUrl}
        />
        <StHorizontalStroke color={Colors.ColorWhite} />
        {isAuthenticated ? (
          <MenuItem
            data={{ key: "logout", link: "/logout", flag: null }}
            isSmartLink={false}
            label={intl.formatMessage(appMessages.logout)}
            user={username}
          />
        ) : null}
        {showLegacyPlatformView && <LeagacyPlatformToggle />}
        {featureToggles[AppFeatureFlags.DARK_MODE] && (
          <StToggleBtn>
            <ThemeToggle />
            <span>
              <sup>
                &nbsp;
                {intl.formatMessage(appMessages.beta)}
              </sup>
            </span>
          </StToggleBtn>
        )}
        <VersionNumber />
      </StFlexMenuContainer>
    </StMenuContainer>
  ) : null;
};

SideNav.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ subMenu: PropTypes.arrayOf(PropTypes.shape({})) })
  ),
  onSelect: PropTypes.func,
};

export default SideNav;
