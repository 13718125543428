import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Button,
  Colors,
  ConditionalTooltip,
  makeMillerColumns,
  SearchInput,
  Message,
  useControlledState,
} from "@mediamath/design-system-eng";
import styled from "@emotion/styled";
import messages from "core/app/intl/AppMessages";
import { isCancel } from "axios";
import { MODULE_CONTAINER_MARGIN } from "core/shared/presenter/SharedAppStyles";
import AccountSelectorButton from "core/app/presenter/AccountSelectorButton";
import Entities from "core/shared/util/entities";
import useOrganizationsList from "newadmin/hooks/useOrganizationsList";
import useAgenciesList from "newadmin/hooks/useAgenciesList";
import useAdvertisersList from "newadmin/hooks/useAdvertisersList";
import { updateQueryStringParameters } from "core/shared/util/accountUtils";
import { useAcctSelector } from "core/app/hooks/useSideNav";
import useUrlEntities from "core/shared/hooks/useUrlEntities";
import getConnection from "core/shared/util/marvellConnection";
import ListView from "core/app/presenter/ListView";
import { getSortMillerColumnBySelected } from "core/shared/util/MillerColumnsUtils";
import { find } from "lodash";
import useLocalStorageSelections from "core/shared/hooks/useLocalStorageSelections";
import { useAccountSelectorEntities } from "core/app/hooks/useAccountSelectorEntities";
import { useFlagVariation } from "@flopflip/react-broadcast";
import AppFeatureFlags from "core/app/util/AppFeatureFlags";
import useDebounce from "core/shared/hooks/useDebounce";
import useAdvertiser from "core/shared/hooks/useAdvertiser";

const doubleMargin = MODULE_CONTAINER_MARGIN * 2;

const ANIMATION_TIME = 500;
const DEBOUNCE_DELAY = 500;
const { ADVERTISER, AGENCY, ORGANIZATION } = Entities;

// TODO(PUP): Share this with MillerColumnsWrapper

const StAcctSelector = styled("div")`
  position: relative;
  background: ${Colors.ColorWhite};
  width: ${`calc(100% - ${doubleMargin}px)`};
  height: ${(props) => (props.closed ? "0px" : "500px")};
  display: flex;
  flex-direction: column;
  margin: ${`0 ${MODULE_CONTAINER_MARGIN}px`};
  transition: height ${ANIMATION_TIME}ms ease-in-out;
  overflow: hidden;
`;

const StAcctSelectorSearch = styled("div")`
  margin: 15px;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StSearchInput = styled(SearchInput)`
  width: calc(30% + 30px);
`;

const StMessage = styled(Message)`
  margin: 0 10px 15px 10px !important;
  min-height: auto !important;

  .header {
    text-align: center;
  }
`;

const StAcctSelectorPanel = styled("div")`
  top: 50px;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
  visibility: ${(props) => (props.closed ? "hidden" : "visible")};
  transition: ${(props) =>
    props.closed ? `visibility 0ms ${ANIMATION_TIME}ms` : "visibility 0ms"};
`;

const StAcctSelectorBlocker = styled("div")`
  background: #000000;
  opacity: ${(props) => (props.closed ? "0" : "0.4")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: ${`opacity ${ANIMATION_TIME}ms ease-in-out`};
`;

const StLeftItems = styled("div")`
  font-size: inherit;
  > * {
    margin-left: 10px !important;
  }
`;

const getMillerColumnsResult = ({ managerProps }) =>
  makeMillerColumns({
    manager: {
      useMillerModel: ({ selectedOrg, selectedAgency, selectedAdvertiser }) => {
        return { selectedOrg, selectedAgency, selectedAdvertiser };
      },
      ...managerProps,
    },
  });

/**
 * Flattens the data structure provided by the search service
 * @param data
 * @returns
 */
function getFlatSearchData(data) {
  const { organizations = [], agencies = [], advertisers = [] } = data;
  return [
    ...organizations.map((org) => ({ ...org, type: ORGANIZATION })),
    ...agencies.map((agy) => ({ ...agy, type: AGENCY })),
    ...advertisers.map((adv) => ({ ...adv, type: ADVERTISER })),
  ];
}

/**
 * Returns the initial keyMaps and valueMaps for a given item
 * @param flatItem -- the data for an entity as it came from the search service
 * @returns {[keyMaps, valueMaps]}
 */
export const getInitialMaps = (flatItem) => {
  const { type, agency, organization, name, id } = flatItem;
  const initialKeyMaps = [];
  const initialValueMaps = [];

  if (type === ORGANIZATION) {
    initialKeyMaps.push({ [id]: [] });
    initialValueMaps.push({ [id]: { name, id, subtitle: id } });
  } else if (type === AGENCY) {
    initialKeyMaps.push({ [organization.id]: [id] });
    initialValueMaps.push(
      {
        [organization.id]: {
          name: organization.name,
          id: organization.id,
          subtitle: organization.id,
        },
      },
      {
        [id]: { id, name, subtitle: id },
      }
    );
  } else if (type === ADVERTISER) {
    initialKeyMaps.push(
      { [organization.id]: [agency.id] },
      { [agency.id]: [id] }
    );
    initialValueMaps.push(
      {
        [organization.id]: {
          name: organization.name,
          id: organization.id,
          subtitle: organization.id,
        },
      },
      {
        [agency.id]: { name: agency.name, id: agency.id, subtitle: agency.id },
      },
      {
        [id]: { name, id, subtitle: id },
      }
    );
  }
  return [initialKeyMaps, initialValueMaps];
};

// Return the selected entity name in the URL -- prioritize miller columns data, then check item selected in ListView
const getEntityName = ({
  type,
  orgId,
  agyId,
  advId,
  intl,
  appliedNames,
  valueMaps: suppliedValueMaps,
  noDefaults,
}) => {
  const selectedMap = {
    [ORGANIZATION]: { index: 0, id: orgId },
    [AGENCY]: { index: 1, id: agyId },
    [ADVERTISER]: { index: 2, id: advId },
  };
  const selectedItem = selectedMap[type];
  const { id, index } = selectedItem;

  if (id) {
    const nameFromMiller = suppliedValueMaps[index][id]?.name;

    if (noDefaults) return nameFromMiller || appliedNames[index];

    return nameFromMiller || appliedNames[index] || "...";
  }

  if (orgId && !noDefaults) return intl.formatMessage(messages.all);
  if (!noDefaults) return "--";

  return null;
};

const StAccountSelectorButtons = styled.div`
  display: flex;
  filter: ${({ disabled }) => disabled && "grayscale(1)"};
  opacity: ${({ disabled }) => disabled && "0.5"};
  pointer-events: ${({ disabled }) => disabled && "none"};
`;

const AccountSelector = () => {
  const {
    acctSelectorCollapsed,
    toggleAcctSel,
    closeAcctSel,
    followCampaignId,
    campaignId,
  } = useAcctSelector();
  const intl = useIntl();
  const history = useHistory();
  const instance = useMemo(() => getConnection(), []);
  const searchInputRef = useRef();
  const [showSelectionRequired, setShowSelectionRequired] = useState(false);
  const [applyImmediately, setApplyImmediately] = useState(false);
  const accountSelectorConfig = useFlagVariation(
    AppFeatureFlags.ACCOUNT_SELECTOR_CONFIG
  );
  const acctSelectorDisabled = followCampaignId && campaignId;

  const showAdvertisers = accountSelectorConfig?.byRoute
    ? (
        accountSelectorConfig.byRoute.find(({ route }) => {
          return history.location.pathname.startsWith(route);
        }) || {}
      ).showAdvertisers
    : false;

  /**
   * Search state
   */
  const [searchTerm, setSearchTerm] = useState(""); // Search query in input
  const [searchData, setSearchData] = useState({}); // Results from search (in ListView)
  const flatSearchData = useMemo(() => getFlatSearchData(searchData), [
    searchData,
  ]);
  const [injectedMaps, setInjectedMaps] = useState(); // constructed keyMaps and valueMaps from search service
  const [injectedKeyMaps, injectedValueMaps] = injectedMaps || [[], []];
  const abortController = useRef(new AbortController());
  const [debouncedTerm] = useDebounce(searchTerm, DEBOUNCE_DELAY);

  const clearSearch = useCallback(() => {
    setSearchTerm("");
  }, []);

  // Call the search service and update state as the search term changes
  useEffect(() => {
    if (debouncedTerm.length) {
      abortController.current.abort();
      abortController.current = new AbortController();

      setShowSelectionRequired(false);

      instance
        .orgSearch({
          name: debouncedTerm,
          only: showAdvertisers ? undefined : "organizations",
          signal: abortController.current.signal,
        })
        .then((data) => {
          setSearchData(data);
        })
        .catch((error) => {
          if (isCancel(error)) {
            // console.debug("Request cancelled in AccountSelector");
          }
        });
    }
    return () => {
      abortController.current.abort();
    };
  }, [debouncedTerm, instance, showAdvertisers, setShowSelectionRequired]);

  /**
   * Miller Columns - selections
   */
  const [millerColumnsState, controlMillerColumnsState] = useControlledState({
    selectionMaps: [{}, {}, {}],
  });
  const { selectionMaps } = millerColumnsState;

  const {
    organizationId: urlOrgId,
    agencyId: urlAgyId,
    advertiserId: urlAdvId,
  } = useUrlEntities();
  const [campaignData, setCampaignData] = useState(null);
  const { advertiserId: campaignAdvertiserId } = campaignData || {};
  const {
    localOrgId,
    localAgyId,
    localAdvId,
    setLocalOrgTree,
  } = useLocalStorageSelections();
  const {
    isLoading: isLoadingAdvertiser,
    advertiser: campaignAdvertiser,
  } = useAdvertiser(campaignAdvertiserId);
  const {
    setContextEntities,
    setHasAdvertiserForEntityLoaded,
  } = useAccountSelectorEntities();

  const savedOrgId = urlOrgId || localOrgId;
  const savedAgyId = urlAgyId || localAgyId;
  const savedAdvId = urlAdvId || localAdvId;
  const mostSpecificSavedId = savedAdvId || savedAgyId || savedOrgId;
  const originalSavedOrgIdRef = useRef(savedOrgId);
  const originalSavedAgyIdRef = useRef(savedAgyId);
  const originalSavedAdvIdRef = useRef(savedAdvId);
  const originalSavedOrgId = originalSavedOrgIdRef.current || null; // undefined here means no override
  const originalSavedAgyId = originalSavedAgyIdRef.current || null;
  const originalSavedAdvId = originalSavedAdvIdRef.current || null;

  // Update URL params if campaign advertiser does not match current advertiser (if followCampaignId)
  useEffect(() => {
    if (
      followCampaignId &&
      !isLoadingAdvertiser &&
      campaignId &&
      campaignAdvertiser?.id
    ) {
      setHasAdvertiserForEntityLoaded(true);
      if (`${campaignAdvertiser?.id}` !== `${urlAdvId}`) {
        const campOrgId = campaignAdvertiser.orgId;
        const campAgencyId = campaignAdvertiser.agencyId;
        const campAdvId = campaignAdvertiser.id;
        const entities = [
          { id: campOrgId, entity: "organization" },
          { id: campAgencyId, entity: "agency" },
          { id: campAdvId, entity: "advertiser" },
        ];
        setLocalOrgTree(campOrgId, campAgencyId, campAdvId);
        updateQueryStringParameters({
          history,
          entities,
        });
      }
    }
  }, [
    setHasAdvertiserForEntityLoaded,
    followCampaignId,
    campaignAdvertiser,
    isLoadingAdvertiser,
    setLocalOrgTree,
    urlAdvId,
    campaignId,
    history,
  ]);

  // Load campaign data as campaign ID changes
  useEffect(() => {
    if (campaignId) {
      instance
        .getCampaignInfo({ campaignId })
        .then((response) => {
          setCampaignData(response);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("Failed to get campaign", error);
        });
    } else {
      setCampaignData(null);
    }
  }, [campaignId, instance]);

  // When the URL or local storage changes, update the miller column state
  useEffect(() => {
    controlMillerColumnsState({}, () => {
      const initialSelectionMaps = [];

      initialSelectionMaps.push(savedOrgId ? { [savedOrgId]: true } : {});
      initialSelectionMaps.push(savedAgyId ? { [savedAgyId]: true } : {});
      initialSelectionMaps.push(savedAdvId ? { [savedAdvId]: true } : {});

      return { selectionMaps: initialSelectionMaps };
    });

    // Call the search service to populate the initial data required for miller columns
    // without waiting for paging / in case it is missing from initial page
    if (mostSpecificSavedId) {
      (async () => {
        const data = await instance.orgSearch({ name: mostSpecificSavedId });
        const items = getFlatSearchData(data);
        const thisItem = find(
          items,
          ({ id }) => Number(mostSpecificSavedId) === id
        );

        if (thisItem) setInjectedMaps(getInitialMaps(thisItem));
      })();
    }
    setApplyImmediately(true);
  }, [
    controlMillerColumnsState,
    savedOrgId,
    savedAgyId,
    savedAdvId,
    instance,
    mostSpecificSavedId,
  ]);

  const [selectedOrgId] = Object.keys(selectionMaps[0]);
  const [selectedAgyId] = Object.keys(selectionMaps[1]);
  const [selectedAdvId] = Object.keys(selectionMaps[2]);

  const managerProps = useMemo(() => {
    return {
      getCompareAllChildKeys: getSortMillerColumnBySelected({
        selectedOrg: originalSavedOrgId,
        selectedAgency: originalSavedAgyId,
        selectedAdvertiser: originalSavedAdvId,
      }),
    };
  }, [originalSavedOrgId, originalSavedAgyId, originalSavedAdvId]);

  // Only rerender MillerColumns if the managerProps change
  const MillerColumns = useMemo(
    () => getMillerColumnsResult({ managerProps }),
    [managerProps]
  );

  /**
   * Services
   */
  const {
    organizations: allOrgs,
    organizationsIds: allOrgIds,
    isStreaming: isOrganizationsStreaming,
  } = useOrganizationsList({});

  // If the account selector is closed, we do not have an orgId in the URL,
  // we are done streaming AND only have one org, then apply the selection immediately
  useEffect(() => {
    if (
      acctSelectorCollapsed &&
      !urlOrgId &&
      !localOrgId &&
      !isOrganizationsStreaming &&
      allOrgIds.length === 1
    ) {
      const newSelectionMaps = [...selectionMaps];
      newSelectionMaps[0][allOrgIds[0]] = true;
      controlMillerColumnsState({ selectionMaps: newSelectionMaps });
      setApplyImmediately(true);
    }
  }, [
    allOrgIds,
    isOrganizationsStreaming,
    selectionMaps,
    controlMillerColumnsState,
    urlOrgId,
    localOrgId,
    acctSelectorCollapsed,
  ]);

  const {
    agencies: allAgys,
    agenciesIds: allAgyIds,
    isStreaming: isAgenciesStreaming,
  } = useAgenciesList({
    organizationId: selectedOrgId,
    agencyId: selectedAgyId,
  });

  const {
    advertisers: allAdvs,
    advertisersIds: allAdvIds,
    isStreaming: isAdvertisersStreaming,
  } = useAdvertisersList({ agencyId: selectedAgyId });

  // These are used to store the currently selected names while the data might not exist in miller columns
  const [appliedNames, setAppliedNames] = useState([]);

  /**
   * Miller Columns - inner state
   */
  // Construct the value maps for the miller colums
  const valueMaps = useMemo(() => {
    const orgsMap = { ...injectedValueMaps[0] };
    const agcsMap = { ...injectedValueMaps[1] };
    const advsMap = { ...injectedValueMaps[2] };

    Object.keys(allOrgs).forEach((orgId) => {
      orgsMap[orgId] = { ...allOrgs[orgId], subtitle: orgId };
    });
    Object.keys(allAgys).forEach((agyId) => {
      agcsMap[agyId] = { ...allAgys[agyId], subtitle: agyId };
    });
    Object.keys(allAdvs).forEach((advId) => {
      advsMap[advId] = { ...allAdvs[advId], subtitle: advId };
    });

    // Store the applied entity names because miller columns can lose the data
    const names = [
      orgsMap[urlOrgId] ? orgsMap[urlOrgId].name : appliedNames[0],
      agcsMap[urlAgyId] ? agcsMap[urlAgyId].name : appliedNames[1],
      advsMap[urlAdvId] ? advsMap[urlAdvId].name : appliedNames[2],
    ];
    if (JSON.stringify(names) !== JSON.stringify(appliedNames)) {
      setAppliedNames(names);
    }

    return [orgsMap, agcsMap, advsMap];
  }, [
    urlOrgId,
    urlAgyId,
    urlAdvId,
    allOrgs,
    allAgys,
    allAdvs,
    appliedNames,
    injectedValueMaps,
  ]);

  // Construct the key maps for the miller columns
  const keyMaps = useMemo(() => {
    const orgKeyMap = { ...injectedKeyMaps[0] }; // { orgId: [agyId], }
    const agcKeyMap = { ...injectedKeyMaps[1] }; // { agyId: [advId], }

    // TODO(PUP): rewrite this logic?
    // if we have a selected org
    if (selectedOrgId) {
      // if it's already included in the organization map
      if (orgKeyMap[selectedOrgId]) {
        // Get the list of Agy Ids from the map
        const agyKeys = new Set(orgKeyMap[selectedOrgId]);
        // Add ALL agency IDs
        allAgyIds.forEach((agyId) => agyKeys.add(agyId));
        // all unique agy Ids from this org become the keys
        orgKeyMap[selectedOrgId] = [...agyKeys];
      } else {
        orgKeyMap[selectedOrgId] = allAgyIds;
      }
    }

    if (selectedAgyId) {
      if (agcKeyMap[selectedAgyId]) {
        const keys = new Set(agcKeyMap[selectedAgyId]);
        allAdvIds.forEach((advId) => keys.add(advId));
        agcKeyMap[selectedAgyId] = [...keys];
      } else {
        agcKeyMap[selectedAgyId] = allAdvIds;
      }
    }

    if (!isAgenciesStreaming) {
      if (!orgKeyMap[selectedOrgId] || orgKeyMap[selectedOrgId].length === 0) {
        orgKeyMap[selectedOrgId] = [intl.formatMessage(messages.noAgency)];
      }
    }

    if (!isAdvertisersStreaming) {
      if (!agcKeyMap[selectedAgyId] || agcKeyMap[selectedAgyId].length === 0) {
        agcKeyMap[selectedAgyId] = [intl.formatMessage(messages.noAdvertisers)];
      }
    }

    return [orgKeyMap, agcKeyMap];
  }, [
    selectedOrgId,
    selectedAgyId,
    allAgyIds,
    allAdvIds,
    isAgenciesStreaming,
    isAdvertisersStreaming,
    intl,
    injectedKeyMaps,
  ]);

  // Focus the search input when the account selector is opened
  useEffect(() => {
    if (!acctSelectorCollapsed && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [acctSelectorCollapsed]);

  // Clear the selection in the miller columns
  const clearSelection = useCallback(() => {
    setShowSelectionRequired(false);
    controlMillerColumnsState({}, () => ({ selectionMaps: [{}, {}, {}] }));
  }, [controlMillerColumnsState]);

  // Reset the selection in the miller columns to what is in the URL
  const resetSelection = useCallback(() => {
    const maps = [
      urlOrgId ? { [urlOrgId]: true } : {},
      urlAgyId ? { [urlAgyId]: true } : {},
      urlAdvId ? { [urlAdvId]: true } : {},
    ];

    controlMillerColumnsState({}, () => ({ selectionMaps: maps }));
  }, [controlMillerColumnsState, urlAdvId, urlAgyId, urlOrgId]);

  const closeWithoutApplying = useCallback(() => {
    setShowSelectionRequired(false);
    resetSelection();
    closeAcctSel();
  }, [closeAcctSel, resetSelection]);

  const isSelectionRequiredOrg = useCallback(
    (orgID) => {
      // If user selects whitelisted 'heavy' org (and not within the compass workflow),
      // shown a message to indicate they are required to select an Agency.
      const whiteListedOrgs =
        accountSelectorConfig?.orgsThatRequireAgencySelection;

      if (
        showAdvertisers &&
        whiteListedOrgs &&
        whiteListedOrgs.length &&
        whiteListedOrgs.includes(orgID)
      ) {
        return true;
      }

      return false;
    },
    [showAdvertisers, accountSelectorConfig?.orgsThatRequireAgencySelection]
  );
  /**
   * Apply the selection in the miller columns
   * (write in local storage and URL)
   */
  const applySelection = useCallback(() => {
    if (isSelectionRequiredOrg(selectedOrgId) && !selectedAgyId) {
      setShowSelectionRequired(true);
      originalSavedOrgIdRef.current = selectedOrgId;
      return;
    }

    // If a user opens the AppHeader and applys no changes, we just want to
    // close the AppHeader without applying anything
    if (
      selectedOrgId === urlOrgId &&
      selectedAgyId === urlAgyId &&
      selectedAdvId === urlAdvId
    ) {
      closeWithoutApplying();
      return;
    }

    setLocalOrgTree(selectedOrgId, selectedAgyId, selectedAdvId);

    originalSavedOrgIdRef.current = selectedOrgId;
    originalSavedAgyIdRef.current = selectedAgyId;
    originalSavedAdvIdRef.current = selectedAdvId;

    const entities = [
      { entity: ORGANIZATION, id: selectedOrgId },
      { entity: AGENCY, id: selectedAgyId },
      { entity: ADVERTISER, id: selectedAdvId },
    ];

    updateQueryStringParameters({ history, entities });
    closeAcctSel();
    setShowSelectionRequired(false);
  }, [
    selectedOrgId,
    selectedAdvId,
    selectedAgyId,
    setLocalOrgTree,
    history,
    closeAcctSel,
    closeWithoutApplying,
    urlOrgId,
    urlAgyId,
    urlAdvId,
    isSelectionRequiredOrg,
  ]);

  // Update context as url values change
  useEffect(() => {
    const params = {
      orgId: urlOrgId,
      agyId: urlAgyId,
      advId: urlAdvId,
      noDefaults: true,
      valueMaps,
      intl,
      appliedNames,
    };
    const organizationName = getEntityName({ type: ORGANIZATION, ...params });
    const agencyName = getEntityName({ type: AGENCY, ...params });
    const advertiserName = getEntityName({ type: ADVERTISER, ...params });

    setContextEntities({
      organizationId: urlOrgId,
      organizationName,
      agencyId: urlAgyId,
      agencyName,
      advertiserId: urlAdvId,
      advertiserName,
    });
  }, [
    urlOrgId,
    urlAgyId,
    urlAdvId,
    setContextEntities,
    valueMaps,
    intl,
    appliedNames,
  ]);

  // Apply the selection immediately when an item is selected in the ListView
  useEffect(() => {
    if (applyImmediately) {
      applySelection();
      setShowSelectionRequired(false);
      setApplyImmediately(false);
    }
  }, [applyImmediately, applySelection]);

  // Select a specific entity in account selector (and its ancestors) -- for ListView, not Miller Columns
  const selectEntity = useCallback(
    (item) => {
      const { id, type, organization, agency, name } = item;
      const newSelectionMaps = [{}, {}, {}];

      if (type === ORGANIZATION) {
        newSelectionMaps[0][id] = true;
        setAppliedNames([name]);
      } else if (type === AGENCY) {
        newSelectionMaps[0][organization.id] = true;
        newSelectionMaps[1][id] = true;
        setAppliedNames([organization.name, name]);
      } else if (type === ADVERTISER) {
        newSelectionMaps[0][organization.id] = true;
        newSelectionMaps[1][agency.id] = true;
        newSelectionMaps[2][id] = true;
        setAppliedNames([organization.name, agency.name, name]);
      }

      controlMillerColumnsState({}, () => ({
        selectionMaps: newSelectionMaps,
      }));

      const allowClose =
        (type === ORGANIZATION && !isSelectionRequiredOrg(id.toString())) ||
        type === AGENCY ||
        type === ADVERTISER;

      if (allowClose) {
        closeAcctSel();
        setApplyImmediately(true);
      } else if (type === ORGANIZATION) {
        originalSavedOrgIdRef.current = id.toString();
      }
    },
    [controlMillerColumnsState, closeAcctSel, isSelectionRequiredOrg]
  );

  const getNameParams = {
    orgId: urlOrgId,
    agyId: urlAgyId,
    advId: urlAdvId,
    valueMaps,
    intl,
    appliedNames,
  };

  return (
    <React.Fragment>
      <ConditionalTooltip
        tooltipContent={
          acctSelectorDisabled ? (
            <FormattedMessage {...messages.disabled} />
          ) : null
        }
      >
        <div>
          <StAccountSelectorButtons disabled={acctSelectorDisabled}>
            <AccountSelectorButton
              onToggle={toggleAcctSel}
              collapsed={acctSelectorCollapsed}
              label={<FormattedMessage {...messages.organization} />}
              name={getEntityName({ type: ORGANIZATION, ...getNameParams })}
              showCaret={!showAdvertisers}
            />
            {showAdvertisers && (
              <AccountSelectorButton
                onToggle={toggleAcctSel}
                collapsed={acctSelectorCollapsed}
                label={<FormattedMessage {...messages.agency} />}
                name={getEntityName({ type: AGENCY, ...getNameParams })}
              />
            )}
            {showAdvertisers && (
              <AccountSelectorButton
                onToggle={toggleAcctSel}
                collapsed={acctSelectorCollapsed}
                label={<FormattedMessage {...messages.advertiser} />}
                name={getEntityName({ type: ADVERTISER, ...getNameParams })}
                showCaret
              />
            )}
          </StAccountSelectorButtons>
        </div>
      </ConditionalTooltip>
      <StAcctSelectorPanel closed={acctSelectorCollapsed}>
        <StAcctSelectorBlocker
          closed={acctSelectorCollapsed}
          onClick={closeWithoutApplying}
        />
        <StAcctSelector closed={acctSelectorCollapsed}>
          <StAcctSelectorSearch closed={acctSelectorCollapsed}>
            <StSearchInput
              fluid
              value={searchTerm}
              placeholder={intl.formatMessage(messages.searchPlaceholder)}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClear={clearSearch}
              ref={searchInputRef}
              data-testid="search-input-organizations"
            />
            <StLeftItems>
              <Button onClick={applySelection}>
                {intl.formatMessage(messages.applySelections)}
              </Button>
              <Button secondary onClick={clearSelection}>
                {intl.formatMessage(messages.clearSelections)}
              </Button>
            </StLeftItems>
          </StAcctSelectorSearch>
          {showSelectionRequired && (
            <StMessage warning>
              <Message.Header>
                <FormattedMessage {...messages.selectionRequired} />
              </Message.Header>
            </StMessage>
          )}
          {searchTerm?.length ? (
            <ListView
              searchTerm={searchTerm}
              onSelect={(item) => {
                selectEntity(item);
                clearSearch();
              }}
              data={flatSearchData}
            />
          ) : (
            <MillerColumns
              {...millerColumnsState}
              selectedOrg={selectedOrgId}
              selectedAgency={showAdvertisers && selectedAgyId}
              selectedAdvertiser={showAdvertisers && selectedAdvId}
              rootKeys={
                !allOrgIds.length && !isOrganizationsStreaming
                  ? [intl.formatMessage(messages.noOrganizationsFound)]
                  : allOrgIds
              }
              columnKeys={[ORGANIZATION, AGENCY, ADVERTISER].slice(
                0,
                showAdvertisers ? 3 : 1
              )}
              columnMap={{
                [ORGANIZATION]: {
                  label: <FormattedMessage {...messages.organization} />,
                  loading: isOrganizationsStreaming,
                },
                [AGENCY]: {
                  label: <FormattedMessage {...messages.agency} />,
                  loading: isAgenciesStreaming,
                },
                [ADVERTISER]: {
                  label: <FormattedMessage {...messages.advertiser} />,
                  loading: isAdvertisersStreaming,
                },
              }}
              keyMaps={keyMaps}
              valueMaps={valueMaps}
              onSelect={(payload, updater) => {
                const {
                  columnIndex,
                  selectionMaps: payloadSelectionMaps,
                  itemKey,
                } = payload;
                // Do not select the option in the case of itemKey 0 or null
                if (!Number(itemKey)) return;

                // Empty out selections for sub-entities to prevent errors
                payloadSelectionMaps.forEach((_, idx) => {
                  if (idx > columnIndex) {
                    payloadSelectionMaps[idx] = {};
                  }
                });

                setSearchTerm("");
                searchInputRef.current.focus();

                controlMillerColumnsState(payload, updater);
              }}
            />
          )}
        </StAcctSelector>
      </StAcctSelectorPanel>
    </React.Fragment>
  );
};

export default AccountSelector;
