import { Redirect, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import NavRoutesPermissions from "core/app/util/NavRoutesPermissions";
import { Loader } from "@mediamath/design-system-eng";
import useUserPermissions from "core/shared/hooks/useUserPermissions";
import { useFeatureToggle } from "@flopflip/react-broadcast";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { useAcctSelector } from "core/app/hooks/useSideNav";
import { useAccountSelectorEntities } from "core/app/hooks/useAccountSelectorEntities";

function renderComponent({
  component: Component,
  render,
  children,
  routeProps,
}) {
  if (Component) {
    return <Component {...routeProps} />;
  }
  if (render) {
    return render(routeProps);
  }
  return children;
}

const StWaitLoader = styled("div")`
  background: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PermissionRouteWrapper = ({
  component,
  render,
  children,
  auth,
  flag,
  followCampaignId,
  routeProps,
}) => {
  const userPermissions = useUserPermissions();
  const flagValue = useFeatureToggle(flag);
  const { isAuthenticated, isLoading } = useAuth0();
  const routePath = routeProps.match.path;
  const isSharedPreviewRoute = routePath.includes("/creative-preview/");
  const { campaignId: urlCampaignId } = useParams();
  const { setCampaignId, setFollowCampaignId } = useAcctSelector();
  const {
    hasAdvertiserForEntityLoaded,
    setHasAdvertiserForEntityLoaded,
  } = useAccountSelectorEntities();

  // When the route unmounts, we want to reset the advertiser loaded value
  useEffect(() => {
    return () => {
      setHasAdvertiserForEntityLoaded(false);
    };
  }, [setHasAdvertiserForEntityLoaded]);

  // When there is a campaign ID in the URL, disable the account selector and set the correct entities
  useEffect(() => {
    setFollowCampaignId(followCampaignId);
    if (followCampaignId && urlCampaignId) {
      setCampaignId(urlCampaignId);
    }
    return () => {
      setCampaignId(null);
      setFollowCampaignId(false);
    };
  }, [followCampaignId, urlCampaignId, setCampaignId, setFollowCampaignId]);

  // if we're loading, show the spinner
  if (
    (auth && isLoading) ||
    (followCampaignId && urlCampaignId && !hasAdvertiserForEntityLoaded) ||
    (!isSharedPreviewRoute && isEmpty(userPermissions))
  ) {
    return (
      <StWaitLoader>
        <Loader active size="tiny" />
      </StWaitLoader>
    );
  }

  let hasPermission = true; // this gets overwritten in the case of actual permissions

  const navRoutesPermission = NavRoutesPermissions[routePath];
  if (navRoutesPermission) {
    const { entity, isCreateMode } = navRoutesPermission;
    const entityPermissions = userPermissions[entity];

    if (isCreateMode) {
      hasPermission = entityPermissions?.create;
    } else {
      hasPermission =
        entityPermissions?.create ||
        entityPermissions?.edit ||
        entityPermissions?.view;
    }
  }

  // Route requires login and user is not logged in
  if (auth && !isAuthenticated) {
    hasPermission = false;
  }

  // Route requires a feature flag and user does not have the value
  if (flag && !flagValue) {
    hasPermission = false;
  }

  return hasPermission ? (
    renderComponent({
      component,
      render,
      children,
      routeProps,
    })
  ) : (
    <Redirect to="/access-denied" />
  );
};

PermissionRouteWrapper.propTypes = {
  component: PropTypes.elementType,
  render: PropTypes.func,
  routeProps: PropTypes.shape({
    match: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
  /**  should this route require authentication */
  auth: PropTypes.bool,
  /**  what is the feature flag this route requires */
  flag: PropTypes.string,
  followCampaignId: PropTypes.bool,
};

PermissionRouteWrapper.defaultProps = {
  component: undefined,
  render: undefined,
  routeProps: undefined,
  children: undefined,
  auth: false,
  flag: undefined,
  followCampaignId: false,
};

export default PermissionRouteWrapper;
