import React, { useEffect } from "react";
import { matchPath } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { useAuth0 } from "@auth0/auth0-react";
import packageJson from "package.alias.json";
import { getSelectedLanguage } from "settings/util/localizationSettings";
import NavRoutes from "core/app/util/NavRoutes";
import fetchIntercept from "fetch-intercept";

const routes = [];
const sentryReservedWords = ["id"];
const testingWithJest = () => process.env.JEST_WORKER_ID !== undefined;
const getTz = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
const isLocalDev = (host) => ["localhost", "127.0.0.1"].includes(host);
const isE2E = () => !!navigator.webdriver;
const isProd = (host) =>
  ["platform.mediamath.com", "newt1.mediamath.com"].includes(host);
const isUat = (host) =>
  ["newt1-uat.mediamath.com", "uat-platform.mediamath.com"].includes(host);
const testRunning = isE2E() && window.Cypress?.mocha;
const testUserIDs = [37832, 37833];

function getMatchPath(path, allPaths) {
  for (let i = 0; i < allPaths.length; i += 1) {
    const match = matchPath(path, allPaths[i]);

    if (match) return match;
  }
  return null;
}

function initRoutes() {
  Object.values(NavRoutes).forEach((r) => {
    Object.values(r)
      .reverse()
      .forEach((path) => {
        routes.push({ path });
      });
  });
}

const parseHeader = (header) => {
  return header.split("\r\n").reduce((acc, current) => {
    const parts = current.split(": ");
    return { ...acc, [parts[0]]: parts[1] };
  }, {});
};

const getFullTestName = (ctx) => {
  const titles = [];
  const getTitle = (test) => {
    if (test?.parent) {
      titles.push(test.title.split("tags")[0].trim());
      getTitle(test.parent);
    }
  };
  getTitle(ctx.test);
  return titles.reverse().join(" - ");
};

const getEnvironmentFromHostname = (host) => {
  if (isUat(host)) return "uat";
  if (isProd(host)) return "production";
  if (host.indexOf("pr") > -1) return "pr";
  if (host.indexOf("qa") > -1) return "qa";
  if (isLocalDev(host)) return "dev";
  return "unknown";
};

const tagsWithPrefixIfNeeded = (newTags) =>
  Object.entries(newTags).reduce((obj, [key, value]) => {
    // eslint-disable-next-line no-param-reassign
    obj[sentryReservedWords.includes(key) ? `param_${key}` : key] = value;

    return obj;
  }, {});

const getTeamByPathname = (pathname) => {
  if (
    pathname.startsWith("/admin") ||
    pathname.startsWith("/enterprise-controls") ||
    pathname.startsWith("/plans") ||
    /^\/campaigns\/[0-9]+\/strategies\/new$/.test(pathname) ||
    /^\/campaigns\/[0-9]+\/strategies\/[0-9]+$/.test(pathname)
  ) {
    return "admin";
  }

  if (
    pathname.startsWith("/campaigns") ||
    pathname.startsWith("/strategies") ||
    pathname.startsWith("/h/campaign") ||
    pathname.startsWith("/h/strategy")
  ) {
    return "campaign";
  }

  if (pathname.startsWith("/creative")) return "creative";
  if (pathname.startsWith("/inventory")) return "inventory";

  return "unknown";
};

const getBaseTags = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = Object.fromEntries(urlParams.entries());
  const { pathname } = window.location;
  const match = getMatchPath(pathname, routes);
  const team = getTeamByPathname(pathname);

  const tags = {
    "browser.locale": navigator.language,
    "user.language": getSelectedLanguage(),
    "user.tz": getTz(),
    e2e: isE2E(),
    team,
    ...tagsWithPrefixIfNeeded(params),
  };

  return match ? { ...tags, ...tagsWithPrefixIfNeeded(match.params) } : tags;
};

export function initSentry() {
  const host = window.location.hostname;
  const env = getEnvironmentFromHostname(host);

  // Disable Sentry while testing
  if (env === "dev" || env === "pr" || testingWithJest() || isE2E()) return;

  initRoutes();

  Sentry.init({
    trackComponents: true,
    environment: env,
    release: `t1next@${packageJson.version || "dev"}`,
    autoSessionTracking: true,
    dsn:
      "https://00443d4fd31c4d2ea6fd62ea36bd65a8@o1043010.ingest.sentry.io/6054776",
    // Ignore oAuth errors that are part of the workflow of the application
    // in the future we should handle this
    ignoreErrors: [
      "Error new t(@auth0/auth0-react/dist/auth0-react.esm)",
      "Login required",
    ],
    // Ignore legacy URLs
    denyUrls: [/.*\/c\/*.*/i],
    beforeSendTransaction(evt) {
      // Do not sent events to Sentry for test users, could
      // otherwise waste a large amount of our transaction budget
      if (testUserIDs.includes(evt.user?.id)) return null;

      return evt;
    },
    // Called only for message and error events, not transactions
    beforeSend(event) {
      // Do not send to Sentry errors from legacy application
      if (event.request?.url?.includes("/c/")) return null;
      // Check if it is an exception, and if so, show the report dialog
      if (window.enableUserFeedback && event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      const tags = { ...event.tags, ...getBaseTags() };
      const exception = event.exception?.values[0]?.value;

      if (exception) {
        const httpStatus = Number.parseInt(
          exception.split("status code ")[1],
          10
        );

        if (Number.isInteger(httpStatus)) {
          tags.httpStatus = httpStatus;
        }
      }

      if (testRunning) {
        const { ctx } = window.Cypress.mocha.getRunner().suite;
        const testName = getFullTestName(ctx);
        const sentryUrl = `https://sentry.io/organizations/mediamath/issues/?project=6054776&query=id%3A${event.event_id}`;
        // eslint-disable-next-line no-console
        console.log("Sentry Issue Url: ", sentryUrl);
        window.Cypress.log({
          name: "SentryIssueUrl",
          displayName: "Sentry Url",
          message: `${sentryUrl}`,
          consoleProps: () => ({ Url: sentryUrl }),
        });

        return { ...event, tags: { ...tags, test: testName } };
      }

      return { ...event, tags };
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (["fetch", "xhr"].includes(breadcrumb.category)) {
        const traceId =
          breadcrumb.category === "fetch"
            ? hint.response.headers.get("trace-id")
            : parseHeader(hint.xhr.getAllResponseHeaders())["trace-id"]; // to avoid 'Refused to get unsafe header "trace-id"'

        if (traceId && traceId !== "00000000000000000000000000000000") {
          return {
            ...breadcrumb,
            data: { ...breadcrumb.data, trace_id: traceId },
          };
        }
      }

      if (breadcrumb.category === "console") {
        return null; // to avoid "413 Payload Too Large"
      }

      if (breadcrumb.category === "ui.click") {
        const tgt = hint.event.target;
        const tgtTestId = tgt.getAttribute("data-testid");
        const tgtInnerText = tgt.innerText;
        const tgtNodeName = tgt.nodeName;

        const message =
          tgtTestId || tgtInnerText
            ? `${tgtNodeName}: ${tgtTestId || tgtInnerText}`
            : breadcrumb.message;

        return { ...breadcrumb, message };
      }

      return breadcrumb;
    },
    integrations: [
      new BrowserTracing({
        beforeNavigate: (context) => {
          const match = getMatchPath(window.location.pathname, routes);
          const tags = { ...context.tags, ...getBaseTags() };

          return match
            ? { ...context, name: match.path, tags }
            : { ...context, tags };
        },
        // Upgrade to routerV6 implementation once react-router is upgraded to v6
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#react-router-v6
        // routingInstrumentation: Sentry.reactRouterV5Instrumentation(),
        tracePropagationTargets: ["localhost", "marvell"],
      }),
    ],
    tracesSampler: (samplingContext) => {
      if (samplingContext.location.href.includes("/c/")) return 0;
      // To stay within our alloted subscription quota:
      // - Log 100% of transactions only in Production.
      // - Log 1% of transactions in any other environment.

      // TODO(EL): reverse this change after testing
      // temporarily log 100% to test in QA
      return env === "production" ? 0.1 : 1.0;
    },
  });
}

function traceIdProcess(traceId, clientTraceId) {
  if (traceId && traceId !== "00000000000000000000000000000000") {
    if (testRunning) {
      const { ctx } = window.Cypress.mocha.getRunner().suite;
      if (!ctx.test?.id) return;
      // eslint-disable-next-line no-console
      console.log("client_trace_id: ", traceId);
      const clientTraceIdKey = `client_trace_id-${ctx.test.id}`;
      const clientTraceIdSavedStr = sessionStorage.getItem(clientTraceIdKey);
      let clientTraceIdSaved = JSON.parse(clientTraceIdSavedStr);

      if (!clientTraceIdSavedStr) clientTraceIdSaved = [];
      clientTraceIdSaved.push({ clientTraceId, traceId });

      sessionStorage.setItem(
        clientTraceIdKey,
        JSON.stringify(clientTraceIdSaved)
      );

      sessionStorage.setItem("client_trace_id", traceId);

      window.Cypress.log({
        consoleProps: () => ({ traceId }),
        displayName: "client_trace_id",
        message: `${traceId}`,
        name: "client_trace_id",
      });
    }
  }
}

export function logClientTraceId(clientTraceId, _url, _method, error) {
  if (testRunning) {
    const currentTestId = sessionStorage.getItem("currentTestId");
    const clientTraceIdKey = `clientTraceId-${currentTestId}`;
    const clientTraceIdSavedStr = sessionStorage.getItem(clientTraceIdKey);
    let clientTraceIdSaved = JSON.parse(clientTraceIdSavedStr);

    if (!clientTraceIdSavedStr) clientTraceIdSaved = [];

    clientTraceIdSaved.push({
      clientTraceId,
      error,
      method: _method,
      url: _url,
    });

    sessionStorage.setItem(
      clientTraceIdKey,
      JSON.stringify(clientTraceIdSaved)
    );

    sessionStorage.setItem("clientTraceId", clientTraceId);

    window.Cypress.log({
      consoleProps: () => ({ clientTraceId }),
      displayName: "clientTraceId",
      message: `${clientTraceId}`,
      name: "clientTraceId",
    });
  }
}

export function SentryContextUpdater() {
  const { user } = useAuth0();
  const userId = user ? user["https://api.mediamath.com/user_id"] : null;

  useEffect(() => {
    if (userId) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: userId });
      });
    }
  }, [userId]);

  return <React.Fragment />;
}

export const traceInterceptor = () => {
  // TODO(EL): remove fetchIntercept, add it to axios interceptors
  fetchIntercept.register({
    request: (url, config) => [url, config],
    requestError: (error) => {
      Sentry.addBreadcrumb({
        category: "fetch-error",
        message: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        level: "error",
      });

      Sentry.captureException(new Error(error));

      return Promise.reject(error);
    },
    response: (response) => {
      if (!response.ok && response.status !== 401) {
        const message = `HTTP status code ${response.status}`;

        Sentry.captureException(new Error(message));
      }

      if (testRunning) {
        const clientTrace = response.headers.get("trace-id");
        const clientTraceId = response.request.headers.get("client-trace-id");
        traceIdProcess(clientTrace, clientTraceId);
      }

      return response;
    },
    responseError: (error) => {
      // if error code is 20 the user aborted a request
      if (error.code !== 20) {
        Sentry.addBreadcrumb({
          category: "fetch-error",
          message: JSON.stringify(error, Object.getOwnPropertyNames(error)),
          level: "error",
        });
        Sentry.captureException(new Error(error));
      }

      if (testRunning) {
        const clientTraceId = error.request.headers.get("client-trace-id");
        const { url, method } = error.request;
        const { message } = error;
        logClientTraceId(clientTraceId, url, method, message);
      }

      return Promise.reject(error);
    },
  });
};

export function logClientResponseTraceId(axios) {
  const ctx = testRunning ? window.Cypress.mocha.getRunner().suite.ctx : null;

  axios.interceptors.request.use(
    (successfulReq) => {
      if (testRunning && ctx) {
        logClientTraceId(
          successfulReq.headers["client-trace-id"],
          `${successfulReq.baseURL}/${successfulReq.url}`,
          successfulReq.method
        );
      }

      return successfulReq;
    },
    (error) => {
      Sentry.captureException(new Error(error));
      if (testRunning && ctx) {
        const headersRequest = error.request.headers;
        const clientTraceId = headersRequest["client-trace-id"];
        const clientTraceIdKey = `clientTraceId-${ctx.test.id}`;
        const clientTraceIdSavedStr = sessionStorage.getItem(clientTraceIdKey);
        const clientTraceIdSaved = JSON.parse(clientTraceIdSavedStr);
        const foundIndex = clientTraceIdSaved.findIndex(
          (x) => x.clientTraceId === clientTraceId
        );
        clientTraceIdSaved[foundIndex].error = error.message;
        sessionStorage.setItem(
          clientTraceIdKey,
          JSON.stringify(clientTraceIdSaved)
        );
      }

      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (successfulResponse) => {
      if (testRunning) {
        const clientTrace = successfulResponse.headers["trace-id"];
        const clientTraceId =
          successfulResponse.config.headers["client-trace-id"];
        traceIdProcess(clientTrace, clientTraceId);
      }

      return successfulResponse;
    },
    (axiosResponseError) => {
      const message = axiosResponseError.message.toLowerCase();

      if (
        !message.includes("unloaded") &&
        !message.includes("cancel") &&
        !message.includes("unmounted")
      ) {
        Sentry.captureException(new Error(axiosResponseError.message));
      }

      return Promise.reject(axiosResponseError);
    }
  );
}

export default Sentry;
