import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ShadedContainer, Loader, Colors } from "@mediamath/design-system-eng";
import styled from "@emotion/styled";

const StShadedContainer = styled(ShadedContainer)`
  height: 100%;
  width: 100%;
  background-color: ${({ light }) => light && Colors.ColorWhite};
`;

const SuspenseLoader = ({ delay = 300, light }) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsHidden(false);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  return isHidden ? (
    <div />
  ) : (
    <StShadedContainer light={light}>
      <Loader active inline size="large" />
    </StShadedContainer>
  );
};

SuspenseLoader.propTypes = {
  delay: PropTypes.number,
  light: PropTypes.bool,
};

SuspenseLoader.defaultProps = {
  delay: 300,
};

export default SuspenseLoader;
