import React, { lazy, Suspense } from "react";
import Route from "core/shared/presenter/Route";
import { Redirect } from "react-router-dom";
import Logout from "core/shared/presenter/Logout";
import { StFlexDiv } from "@mediamath/design-system-eng";
import SideNav from "core/app/presenter/SideNav";
import AppHeader from "core/app/presenter/AppHeader";
import SideNavConfig from "core/app/util/SideNavConfig";
import ErrorBoundary from "app/ErrorBoundary";
import SmartSwitch from "core/shared/util/SmartSwitch";
import NavRoutes from "core/app/util/NavRoutes";
import AccessDeniedError from "core/shared/presenter/AccessDeniedError";
import AppFeatureFlags from "core/app/util/AppFeatureFlags";
import Sentry, { initSentry } from "app/SentryHelpers";
import EnterpriseControlsMessages from "newadmin/intl/EnterpriseControlsMessages";
import InventoryMessages from "inventory/intl/InventoryMessages";
import AudienceMessages from "audience/intl/AudienceMessages";
import CampaignMessages from "campaign/intl/CampaignMessages";
import StrategyMessages from "campaign/intl/StrategyMessages";
import AdminMessages from "newadmin/intl/AdminMessages";
import CreativesModuleMessages from "creatives/intl/CreativesModuleMessages";
import PlanningListMessages from "planning/intl/PlanningListMessages";
import SectionNameMessages from "core/shared/intl/SectionNameMessages";
import Wrappers from "app/Wrappers";

// Lazy load modules for better code splitting
const CampaignModule = lazy(() => import("campaign/presenter/CampaignModule"));
const CreativesModulePresenter = lazy(() =>
  import("creatives/presenter/CreativesModulePresenter")
);
const InventoryModulePresenter = lazy(() =>
  import("inventory/presenter/InventoryModulePresenter")
);
const EnterpriseControlsModule = lazy(() =>
  import("enterprise_controls/presenter/EnterpriseControlsModule")
);
const AdminModule = lazy(() => import("newadmin/presenter/AdminModule"));
const AudienceModule = lazy(() => import("audience/presenter/AudienceModule"));
const PlanningModule = lazy(() => import("planning/presenter/PlanningModule"));
const PlanningV2Module = lazy(() =>
  import("../strategy-plans/presenter/PlanningModule")
);
const Frame = lazy(() => import("core/shared/presenter/Frame"));
const HybridFrame = lazy(() => import("core/shared/presenter/HybridFrame"));
const LookerFrame = lazy(() => import("core/shared/presenter/LookerFrame"));
const CreativesExternalPreview = lazy(() =>
  import("creatives/presenter/CreativesExternalPreview")
);

const CAMPAIGNS_ROUTE = NavRoutes.campaigns.default;

initSentry();

const LandingPage = () => {
  return <Redirect to={CAMPAIGNS_ROUTE} />;
};

/* eslint-disable react/prop-types */
const Root = () => (
  <Wrappers>
    <AppHeader />
    <StFlexDiv flexFlow="row">
      <SideNav data={SideNavConfig} />
      <ErrorBoundary>
        <Suspense fallback={<div />}>
          <SmartSwitch>
            <Route auth exact path="/" component={LandingPage} />
            <Route exact path="/logout" component={Logout} />
            <Route
              auth
              path="/admin"
              title={AdminMessages.admin}
              component={AdminModule}
            />
            <Route
              auth
              path="/campaigns"
              flag={AppFeatureFlags.CAMPAIGNS_MODULE}
              title={CampaignMessages.CampaignsHeader}
              component={CampaignModule}
            />
            <Route
              auth
              path="/strategies"
              flag={AppFeatureFlags.CAMPAIGNS_MODULE}
              title={StrategyMessages.StrategiesHeader}
              component={CampaignModule}
            />
            <Route
              auth
              path="/strategySearch"
              flag={AppFeatureFlags.CAMPAIGNS_MODULE}
              title={StrategyMessages.StrategySearch}
              component={CampaignModule}
            />
            <Route
              auth
              path="/audiences"
              flag={AppFeatureFlags.AUDIENCE_MODULE}
              title={AudienceMessages.AudiencesHeader}
              component={AudienceModule}
            />
            <Route
              auth
              path="/inventory"
              title={InventoryMessages.DealHeader}
              component={InventoryModulePresenter}
            />
            <Route
              auth
              path="/enterprise-controls"
              flag={AppFeatureFlags.ENTERPRISE_CONTROLS_MODULE}
              title={EnterpriseControlsMessages.EnterpriseControlsHeader}
              component={EnterpriseControlsModule}
            />
            <Route
              auth
              path="/creative"
              flag={AppFeatureFlags.CREATIVES_MODULE}
              title={CreativesModuleMessages.CreativesHeader}
              component={CreativesModulePresenter}
            />
            <Route
              auth
              path="/plans"
              title={PlanningListMessages.planTitle}
              component={PlanningModule}
            />
            <Route
              auth
              path="/plansv2"
              title={PlanningListMessages.planTitle}
              component={PlanningV2Module}
            />
            <Route exact path="/access-denied" component={AccessDeniedError} />
            <Route
              exact
              path="/creative-preview/:shortCode"
              component={CreativesExternalPreview}
            />
            {/* Compass Routes with page titles */}
            <Route
              auth
              path="/c/monitor"
              title={CampaignMessages.CampaignsHeader}
              component={Frame}
            />
            <Route
              auth
              path="/c/reports"
              title={SectionNameMessages.reports}
              component={Frame}
            />
            <Route
              auth
              path="/c/media"
              title={InventoryMessages.DealHeader}
              component={Frame}
            />
            <Route
              auth
              path="/c/admin"
              title={AdminMessages.admin}
              component={Frame}
            />
            <Route
              auth
              path="/c/creatives"
              title={CreativesModuleMessages.CreativesHeader}
              component={Frame}
            />
            <Route
              auth
              path="/c/audiences"
              title={AudienceMessages.AudiencesHeader}
              component={Frame}
            />
            <Route auth exact path="/c/*" component={Frame} />
            {/* Hybrid Routes with page titles */}
            <Route
              auth
              path="/h/monitor"
              title={CampaignMessages.CampaignsHeader}
              component={HybridFrame}
            />
            <Route
              auth
              path="/h/reports"
              title={SectionNameMessages.reports}
              component={HybridFrame}
            />
            <Route
              auth
              path="/h/media"
              title={InventoryMessages.DealHeader}
              component={HybridFrame}
            />
            <Route
              auth
              path="/h/admin"
              title={AdminMessages.admin}
              component={HybridFrame}
            />
            <Route
              auth
              path="/h/creatives"
              title={CreativesModuleMessages.CreativesHeader}
              component={HybridFrame}
            />
            <Route
              auth
              path="/h/audiences"
              title={AudienceMessages.AudiencesHeader}
              component={HybridFrame}
            />

            <Route auth exact path="/h/*" component={HybridFrame} />
            <Route auth exact path="/reports/*" component={LookerFrame} />
          </SmartSwitch>
        </Suspense>
      </ErrorBoundary>
    </StFlexDiv>
  </Wrappers>
);

export default Sentry.withProfiler(Root, { name: "NewT1" });
