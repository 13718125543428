import styled from "@emotion/styled";
import { Icon, Colors } from "@mediamath/design-system-eng";

const MODULE_CONTAINER_MARGIN = 50;

const StLink = styled("a")`
  font-weight: 400;
  font-size: 1em;
  color: ${Colors.ColorBlue500};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const StSpendYesterdayText = styled("span")`
  color: ${(props) => (props.value === 0 ? Colors.ColorRed100 : "inherit")};
`;

const StNonBreakingText = styled("span")`
  white-space: nowrap;
`;

const StSectionHeader = styled("h1")`
  border-bottom: 1px solid ${Colors.ColorGrey200};
  font-size: 20px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 20)}px;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const StBreadcrumbSection = styled("div")`
  border-bottom: 1px solid ${Colors.ColorGrey200};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : 10)}px;
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : 8)}px;
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : 10}px;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : 8)}px;
`;

const StTextArea = styled("textarea")`
  font-family: Muli, Helvetica Neue, Arial, Helvetica, sans-serif;
  background: ${(props) =>
    props.error ? Colors.ColorRed000 : Colors.ColorWhite};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.error ? Colors.ColorRed100 : Colors.ColorGrey400};
  border-color: ${(props) =>
    props.error ? Colors.ColorRed100 : Colors.ColorGrey400};
  border-radius: 3px;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  height: ${(props) => (props.height ? props.height : "75px")};
  width: ${(props) => (props.width ? props.width : "300px")};
  &:focus {
    border-color: ${Colors.ColorBlue400};
    box-shadow: 0 0 3px 1px ${Colors.ColorBlue200} !important;
    outline: none !important;
  }
`;

// shared Chart Icon
const StChartIcon = styled(Icon)`
  background: ${({ background }) => background || undefined};
  color: ${({ color }) => color || Colors.ColorGrey600};
  width: ${({ width }) => width || "18px"} !important;
  height: ${({ height }) => height || "18px"} !important;
  margin: ${({ margin }) => margin || "0"} !important;
  display: ${({ display }) => display || "flex"} !important;
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "center"};
  border: ${({ border }) => border || undefined};
  padding: ${({ padding }) => padding || undefined};
  box-sizing: border-box;
  cursor: ${({ cursor }) => cursor || undefined};
`;

const StLinkUnderline = styled("a")`
  font-weight: 400;
  font-size: 1em;
  color: ${Colors.ColorBlue400};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

/* eslint-disable import/prefer-default-export */
export {
  StLink,
  StSpendYesterdayText,
  StSectionHeader,
  StNonBreakingText,
  MODULE_CONTAINER_MARGIN,
  StBreadcrumbSection,
  StTextArea,
  StChartIcon,
  StLinkUnderline,
};
