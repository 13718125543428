const NavRoutes = {
  compass: {
    campaigns: "/c/monitor",
    creatives: "/c/creatives",
    reports: "/c/reports",
    audiences: "/c/audiences",
    media: "/c/media",
    appstore: "/c/appstore",
    admin: "/c/admin",
  },
  campaigns: {
    default: "/campaigns",
    strategies: "/campaigns/:campaignId/strategies",
    strategyGroups: "/campaigns/:campaignId/strategies/groups",
    strategySearch: "/strategySearch",
    oldStrategies: "/strategies",
    new: "/campaigns/new",
    editCampaign: "/campaigns/edit/:campaignId",
    createStrategy: "/campaigns/:campaignId/strategies/new",
    editStrategy: "/campaigns/:campaignId/strategies/edit/:strategyId",
    hybridCreateCampaign: "/h/campaign/",
    hybridCreateStrategy: "/h/strategy/",
  },
  reports: {
    default: "/reports",
    poc: "/reports/poc",
    hybridReports: "/h/reports/campaigns/performance/:id",
  },
  inventory: {
    default: "/inventory",
    deals: "/inventory/deals",
    dealGroups: "/inventory/deals/groups",
    publishers: "/inventory/publishers",
    dealsCreate: "/inventory/deals/new",
    dealsCreateBulk: "/inventory/deals/bulk",
    dealsEdit: "/inventory/deals/edit",
    publishersCreate: "/inventory/publishers/new",
    spm: "/inventory/spm",
    spmNewPath: "/inventory/supplycontrols/spm",
    supplycontrols: "/inventory/supplycontrols/:type",
    spoCreate: "/inventory/spo/new",
    siteLists: "/inventory/sitelists",
    createSiteList: "/inventory/sitelists/new",
    editSiteList: "/inventory/sitelists/:id/edit",
  },
  audiences: {
    default: "/audiences",
    firstpartydata: "/audiences/firstpartydata",
    type: "/audiences/:type",
  },
  admin: {
    default: "/admin",
    usersGridView: "/admin/users",
    createUser: "/admin/users/new",
    editUser: "/admin/users/:id",
    vendorsGridView: "/admin/vendors",
    createVendor: "/admin/vendors/new",
    editVendor: "/admin/vendors/:id",
    editVendorDetails: "/admin/vendors/:id/details",
    editVendorSettings: "/admin/vendors/:id/settings",
    orgsGridView: "/admin/organizations",
    createOrg: "/admin/organizations/new",
    editOrgBase: "/admin/organizations/:id",
    editOrgDetails: "/admin/organizations/:id/details",
    editOrgSettings: "/admin/organizations/:id/settings",
    editOrgContracts: "/admin/organizations/:id/contracts",
    editOrgExchangeSeats: "/admin/organizations/:id/exchangeseats",
    editOrgAgencies: "/admin/organizations/:id/agencies",
    editOrgAdvertisers: "/admin/organizations/:id/advertisers",
    editOrgUsers: "/admin/organizations/:id/users",
    createAgency: "/admin/organizations/:orgId/agencies/new",
    editAgency: "/admin/organizations/:orgId/agencies/:id",
    editAgencyDetails: "/admin/organizations/:orgId/agencies/:id/details",
    editAgencySettings: "/admin/organizations/:orgId/agencies/:id/settings",
    editAgencyAdvertisers:
      "/admin/organizations/:orgId/agencies/:id/advertisers",
    editAgencyUsers: "/admin/organizations/:orgId/agencies/:id/users",
    createAdv: "/admin/organizations/:orgId/agencies/:agencyId/advertisers/new",
    editAdv: "/admin/organizations/:orgId/agencies/:agencyId/advertisers/:id",
    editAdvDetails:
      "/admin/organizations/:orgId/agencies/:agencyId/advertisers/:id/details",
    editAdvSettings:
      "/admin/organizations/:orgId/agencies/:agencyId/advertisers/:id/settings",
    editAdvUsers:
      "/admin/organizations/:orgId/agencies/:agencyId/advertisers/:id/users",
    entityGroupsGridView: "/admin/enterprise-controls",
    createEntityGroup: "/admin/enterprise-controls/create-entity-group",
    editEntityGroup: "/admin/enterprise-controls/edit-entity-group/:id(\\d+)",
    editEntityGroupBase: "/admin/enterprise-controls/edit-entity-group",
    ecosystemCostsGridView: "/admin/ecosystem-costs",
  },
  enterprise_controls: {
    default: "/enterprise-controls",
    supplyPathManagement: "/enterprise-controls/supply-path-management",
  },
  creatives: {
    default: "/creative",
    groups: "/creative/groups",
    groupsNew: "/creative/groups/new",
    groupsEdit: "/creative/groups/:id/edit",
    newThirdPartySingle: "/creative/new/tpas/:creativeType",
    editThirdPartySingle: "/creative/edit/:id/tpas/:creativeType",
    thirdPartyBulk: "/creative/new/bulk/tpas",
    newMediaMathNativeCreative: "/creative/new/mmas/native",
    newMediaMathAdServer: "/creative/new/mmas/:creativeType",
    editMediaMathAdServer: "/creative/edit/:id/mmas/:creativeType",
    mediaMathAdServerBulk: "/creative/new/bulk/mmas",
  },
  plans: {
    default: "/plans",
    createPlan: "/plans/new",
    editPlan: "/plans/:id",
    duplicatePlan: "/plans/:action/:id",
  },
  strategy_plans: {
    default: "/plansv2",
    createPlan: "/plansv2/new",
    editPlan: "/plansv2/:id",
    duplicatePlan: "/plansv2/:action/:id",
  },
};

export default NavRoutes;
