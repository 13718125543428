import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import useUrlEntities from "core/shared/hooks/useUrlEntities";

const AccountSelectorContext = React.createContext({});

const { Provider } = AccountSelectorContext;

// Treat as read  only (will not impact account selector state)
export const useAccountSelectorEntities = () => {
  const {
    organizationId,
    organizationName,
    agencyId,
    agencyName,
    advertiserId,
    advertiserName,
    setContextEntities,
    hasAdvertiserForEntityLoaded,
    setHasAdvertiserForEntityLoaded,
  } = useContext(AccountSelectorContext);
  return {
    organizationId,
    organizationName,
    agencyId,
    agencyName,
    advertiserId,
    advertiserName,
    setContextEntities,
    hasAdvertiserForEntityLoaded,
    setHasAdvertiserForEntityLoaded,
  };
};

export const AccountSelectorProvider = ({ children }) => {
  const {
    organizationId: initialOrgId,
    agencyId: initialAgyId,
    advertiserId: initialAdvId,
  } = useUrlEntities();
  const [organizationId, setOrganizationId] = useState(initialOrgId);
  const [organizationName, setOrganizationName] = useState();
  const [agencyId, setAgencyId] = useState(initialAgyId);
  const [agencyName, setAgencyName] = useState();
  const [advertiserId, setAdvertiserId] = useState(initialAdvId);
  const [advertiserName, setAdvertiserName] = useState();
  const [
    hasAdvertiserForEntityLoaded,
    setHasAdvertiserForEntityLoaded,
  ] = useState(false);
  const setContextEntities = useCallback(
    ({
      organizationId: newOrgId,
      organizationName: newOrgName,
      agencyId: newAgyId,
      agencyName: newAgyName,
      advertiserId: newAdvId,
      advertiserName: newAdvName,
    }) => {
      setOrganizationId(newOrgId);
      setOrganizationName(newOrgName);
      setAgencyId(newAgyId);
      setAgencyName(newAgyName);
      setAdvertiserId(newAdvId);
      setAdvertiserName(newAdvName);
    },
    []
  );

  const value = {
    organizationId,
    organizationName,
    agencyId,
    agencyName,
    advertiserId,
    advertiserName,
    setContextEntities,
    hasAdvertiserForEntityLoaded,
    setHasAdvertiserForEntityLoaded,
  };

  return <Provider value={value}>{children}</Provider>;
};

AccountSelectorProvider.defaultProps = {
  children: null,
};
AccountSelectorProvider.propTypes = {
  children: PropTypes.node,
};
