import axios from "axios";
import { get, isEmpty } from "lodash";
import qs from "query-string";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import Sentry, {
  logClientResponseTraceId,
  traceInterceptor,
} from "app/SentryHelpers";
import { numericOperators } from "campaign/util/parseFilterSort";
import CreativesConstants from "creatives/util/CreativesConstants";
import InventoryConstants from "inventory/util/InventoryConstants";
import CampaignConstants from "campaign/util/CampaignConstants";

const {
  CHANNEL_TYPE: { NATIVE, VIDEO, DISPLAY },
} = CreativesConstants;

const { CUSTOM_TIME_WINDOW_TEXT } = InventoryConstants;

export const TEST_ACCESS_TOKEN = "some_access_token";

const userTZ = DateTime.local().zoneName;
const endpoint = "/marvell";

const getMMASBasicFormData = ({ form, entity }) => {
  form.append("creative_group", JSON.stringify(entity.creative_group));
  form.append("active", entity.active);
  form.append("advertiser", entity.advertiser);
  form.append("name", entity.name);
  form.append("landing_url", entity.landing_url);
  form.append("clickthrough_url", entity.clickthrough_url);
  if (entity.clock_number) {
    form.append("clock_number", entity.clock_number);
  }
  if (entity.event_pixels) {
    form.append("event_pixels", JSON.stringify(entity.event_pixels));
  }
  if (entity.start_time) {
    form.append("start_time", entity.start_time);
  }
  if (entity.end_time) {
    form.append("end_time", entity.end_time);
  }
  if (entity.classifications) {
    form.append("classifications", JSON.stringify(entity.classifications));
  }
  if (entity.disabledVariants) {
    form.append("disabledVariants", JSON.stringify(entity.disabledVariants));
  }
  if (entity.companionBanner) {
    form.append("companionBanner", JSON.stringify(entity.companionBanner));
    entity.companionBanner.forEach((banner, index) =>
      form.append(`image${index}`, banner.companionFile)
    );
  }
  if (entity.ad_format) {
    form.append("ad_format", entity.ad_format);
  }
  if (entity.expansion_trigger) {
    form.append("expansion_trigger", entity.expansion_trigger);
  }
  if (entity.expansion_direction) {
    form.append("expansion_direction", entity.expansion_direction);
  }
  if (entity.display_dimensions) {
    form.append("display_dimensions", entity.display_dimensions);
  }
  if (entity.tpas_ad_tag !== null && entity.tpas_ad_tag !== undefined) {
    form.append("tpas_ad_tag", entity.tpas_ad_tag);
  }
  if (entity.edited_tag) {
    form.append("edited_tag", entity.edited_tag);
  }
  if (entity.version !== undefined) {
    form.append("version", entity.version);
  }
  return form;
};

const getNativeCreativeData = ({ form, entity }) => {
  form.append("creative_group", JSON.stringify(entity.creative_group));
  form.append("active", entity.active);
  form.append("advertiser", entity.advertiser);
  form.append("name", entity.name);
  if (entity.start_time) {
    form.append("start_time", entity.start_time);
  }
  if (entity.end_time) {
    form.append("end_time", entity.end_time);
  }
  if (entity.body) {
    form.append("body", entity.body);
  }
  if (entity.clickTrackingUrl) {
    form.append("clickTrackingUrl", entity.clickTrackingUrl);
  }
  if (entity.clickthrough_url) {
    form.append("clickthrough_url", entity.clickthrough_url);
  }
  if (entity.description) {
    form.append("description", entity.description);
  }
  if (entity.brandName) {
    form.append("brandName", entity.brandName);
  }
  if (entity.destinationUrl) {
    form.append("destinationUrl", entity.destinationUrl);
  }
  if (entity.displayUrl) {
    form.append("displayUrl", entity.displayUrl);
  }
  if (entity.impressionTrackingTag) {
    form.append("impressionTrackingTag", entity.impressionTrackingTag);
  }
  if (entity.impressionTrackingUrl) {
    form.append("impressionTrackingUrl", entity.impressionTrackingUrl);
  }
  if (entity.threePasId) {
    form.append("threePasId", entity.threePasId);
  }
  if (entity.title) {
    form.append("title", entity.title);
  }
  if (entity.viewabilityTrackingTag) {
    form.append("viewabilityTrackingTag", entity.viewabilityTrackingTag);
  }
  if (entity.imageAsset && typeof entity.imageAsset === "string") {
    form.append("image", entity.imageAsset);
    form.append("imageAssetId", entity.imageAssetId);
  } else {
    form.append("imageAssetFile", entity.imageAsset);
  }
  if (entity.logoAsset && typeof entity.logoAsset === "string") {
    form.append("logo", entity.logoAsset);
    form.append("logoAssetId", entity.logoAssetId);
  } else {
    form.append("logoAssetFile", entity.logoAsset);
  }
  return form;
};
class Connection {
  constructor({ getAccessTokenSilently }) {
    this.getTokenSilently = getAccessTokenSilently;
    this.instance = axios.create({ baseURL: endpoint, timeout: 91000 });

    traceInterceptor();
    logClientResponseTraceId(this.instance);
  }

  async getHeaders() {
    const token = await this.getTokenSilently();
    const clientTraceId = uuidv4();
    Sentry.addBreadcrumb({
      category: "req-client-trace-id",
      message: `client-trace-id: ${clientTraceId}`,
      level: "info",
    });

    return {
      Authorization: `Bearer ${token}`,
      "client-trace-id":
        token !== TEST_ACCESS_TOKEN ? clientTraceId : undefined,
    };
  }

  async getCampaigns({
    limit,
    organizationId,
    agencyId,
    advertiserId,
    page,
    timeWindow,
    filters,
    sortKey,
    sortDescending,
    signal,
  }) {
    const opts = {
      filters: [
        {
          op: numericOperators[2],
          field: "organization_id",
          value: organizationId,
        },
        ...filters,
      ],
      time_window: timeWindow,
      pagination: { page_size: limit, page_index: page },
      rollup: "all",
    };
    if (agencyId) {
      opts.filters = [
        {
          op: numericOperators[2],
          field: "agency_id",
          value: agencyId,
        },
        ...filters,
      ];
    }
    if (advertiserId) {
      opts.filters = [
        {
          op: numericOperators[2],
          field: "advertiser_id",
          value: advertiserId,
        },
        ...filters,
      ];
    }
    if (sortKey) {
      opts.order = [{ field: sortKey, desc: sortDescending, nulls_last: true }];
    } else {
      // default sort by total budget
      opts.order = [
        { field: "campaign_total_budget", desc: true, nulls_last: true },
      ];
    }
    const headers = await this.getHeaders();
    const response = await this.instance.post(`campaigns_paging2`, opts, {
      headers,
      signal,
    });
    const responseData = get(response, "data", {});
    return responseData;
  }

  async getCampaignList({
    organizationId,
    advertiserId,
    campaignName,
    campaignId,
    returnAll,
  }) {
    const params = {
      organizationId,
      ...(advertiserId !== "-1" && { advertiserId }),
      ...(campaignName !== "" && { campaignName }),
      campaignId,
      userTZ,
      returnAll,
    };
    const headers = await this.getHeaders();
    const response = await this.instance.get(`campaignList/`, {
      params,
      headers,
    });
    const data = get(response, "data.data", {});
    const { advertiserId: id, advertiserName: name, campaigns } = data;
    return { advertiser: { id, name }, campaigns };
  }

  async getCampaignListWithoutSpend({
    advertiserId,
    campaignName,
    campaignId,
    returnAll,
  }) {
    const params = {
      ...(advertiserId !== "-1" && { advertiserId }),
      ...(campaignName !== "" && { campaignName }),
      campaignId,
      returnAll,
    };
    const headers = await this.getHeaders();
    const response = await this.instance.get(`campaignListWithoutSpend/`, {
      params,
      headers,
    });
    const data = get(response, "data.data", {});
    const { advertiserId: id, advertiserName: name, campaigns } = data;
    return { advertiser: { id, name }, campaigns };
  }

  async getEditMargin({ campaignId, signal }) {
    const { SEARCH_DATE_FORMAT } = CampaignConstants;
    const headers = await this.getHeaders();
    const today = DateTime.now();
    const end = today.minus({ days: 1 });
    const start = end.minus({ days: 4 });
    const params = {
      end: end.toFormat(SEARCH_DATE_FORMAT),
      start: start.toFormat(SEARCH_DATE_FORMAT),
    };
    const response = await this.instance.get(
      `campaigns/${campaignId}/margins?${qs.stringify(params)}`,
      { headers, signal }
    );
    return response?.data;
  }

  async postEditMargin({ campaignId, margin, signal }) {
    const headers = await this.getHeaders();
    return this.instance.post(`campaigns/${campaignId}/set_margins`, margin, {
      headers,
      signal,
    });
  }

  async getPixelById({ pixelId, signal }) {
    const headers = await this.getHeaders();
    return this.instance.get(`pixels/${pixelId}`, {
      headers,
      signal,
    });
  }

  async getStrategySearch(
    {
      organizationId,
      filters,
      sortDescending,
      sortKey,
      strategySearch,
      pageOffset = 0,
    },
    cancelToken
  ) {
    let opts;
    if (isEmpty(filters)) {
      opts = {
        filter: [
          {
            operator: "eq",
            property: "organization_id",
            value: organizationId,
          },
        ],
      };
    } else {
      opts = {
        filter: [
          {
            operator: "eq",
            property: "organization_id",
            value: organizationId,
          },
          ...filters,
        ],
      };
    }
    opts.pageOffset = pageOffset;
    if (sortKey && sortDescending) {
      opts.sortBy = [{ property: sortKey, order: sortDescending }];
    } else {
      // default sort by name
      opts.sortBy = [{ property: "name", order: "DESC" }];
    }

    const params = {
      organizationId,
      searchTerm: strategySearch.input,
      searchOption: strategySearch.ddl,
    };

    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `strategiesSearch?${qs.stringify(params)}`,
      opts,
      {
        cancelToken,
        headers,
      }
    );
    return response;
  }

  async getStrategyList({ campaignId }) {
    const headers = await this.getHeaders();
    const params = { userTZ };
    const response = await this.instance.get(`strategyList/${campaignId}`, {
      params,
      headers,
    });
    const data = get(response, "data.data", {});
    const {
      campaignId: id,
      campaignName: name,
      campaignHealthCheck: healthcheck,
      strategies,
    } = data;
    return { campaign: { id, name, healthcheck }, strategies };
  }

  async getStrategiesPaged({
    limit,
    organizationId,
    agencyId,
    advertiserId,
    campaignId,
    page,
    timeWindow,
    filters,
    sortKey = "strategy_status",
    sortDescending = true,
    signal,
    updatedEntities,
  }) {
    const opts = {
      filters: [
        {
          op: numericOperators[2],
          field: "organization_id",
          value: organizationId,
        },
        ...filters,
      ],
      time_window: timeWindow,
      pagination: { page_size: limit, page_index: page },
      rollup: "all",
    };
    if (agencyId) {
      opts.filters = [
        {
          op: numericOperators[2],
          field: "agency_id",
          value: agencyId,
        },
        ...filters,
      ];
    }
    if (advertiserId) {
      opts.filters = [
        {
          op: numericOperators[2],
          field: "advertiser_id",
          value: advertiserId,
        },
        ...filters,
      ];
    }
    if (campaignId) {
      opts.filters = [
        {
          op: numericOperators[2],
          field: "campaign_id",
          value: campaignId,
        },
        ...opts.filters,
      ];
    }
    if (sortKey) {
      opts.order = [{ field: sortKey, desc: sortDescending, nulls_last: true }];
    }
    if (updatedEntities) {
      opts.updated_entities = updatedEntities;
    }
    const headers = await this.getHeaders();
    const response = await this.instance.post(`strategies_paging`, opts, {
      headers,
      signal,
    });
    const responseData = get(response, "data", {});
    return responseData;
  }

  async getStrategiesGroupList({ campaignId, params }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `/campaigns/${campaignId}/strategy_groups/list`,
      { params, headers }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getCampaignInfo({ campaignId }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`campaign/${campaignId}`, {
      headers,
    });
    const data = get(response, "data.data", {});
    return data;
  }

  async getChangeLogHistory(
    { strategiesIds, campaignsIds, isShortData },
    signal
  ) {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `campaignManagement/changelog`,
      {
        strategies: strategiesIds,
        campaigns: campaignsIds,
        isShortData,
      },
      { headers, signal }
    );
    const responseData = get(response, "data.data", {});
    return responseData;
  }

  async getAdvertiserList({ search, organizationId }) {
    const queryObj = { organizationId, search };
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `advertisers?${qs.stringify(queryObj)}`,
      { headers }
    );
    const data = get(response, "data.data", {});
    const { advertisers } = data;
    return advertisers;
  }

  async getAdvertiserById({ advertiserId }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`advertiser/${advertiserId}`, {
      headers,
    });
    const data = get(response, "data.data", {});
    const { advertiser } = data;
    return advertiser;
  }

  async getFirstPartyDataList({ organizationId, advertiserId, signal }) {
    const params = {
      organizationId,
      ...(advertiserId !== "-1" && { advertiserId }),
    };
    const headers = await this.getHeaders();
    const response = await this.instance.get(`onboarding/firstPartyList`, {
      params,
      headers,
      signal,
    });
    const data = get(response, "data.data.onboarding", {});
    return data;
  }

  async getPixelAttributeList({ pixelId, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`event_inspector/${pixelId}`, {
      headers,
      signal,
    });
    const data = get(response, "data.data.event_inspector", {});
    return data;
  }

  async getDeal({ organizationId, signal, rtbType }) {
    const params = {
      organizationId,
      rtbType,
    };
    const headers = await this.getHeaders();
    // TODO(jd): consider changing the bff to pass orgId in the URL instead of as a query string
    const response = await this.instance.get(`deal?${qs.stringify(params)}`, {
      headers,
      signal,
    });
    const data = get(response, "data", {});
    return data;
  }

  async getDealAnalytics({
    organizationId,
    pageOffset,
    pageSize,
    timeWindow,
    activeFilters,
    signal,
    sorting,
    startDate,
    endDate,
  }) {
    const headers = await this.getHeaders();
    const params = {
      organizationId,
      pageOffset,
    };
    const response = await this.instance.post(
      `deal_analytics?${qs.stringify(params)}`,
      {
        paging: { page_offset: pageOffset, page_limit: pageSize },
        timeWindow,
        activeFilters,
        sorting,
        startDate,
        endDate,
      },
      {
        headers,
        signal,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async favoriteDeals(payload) {
    const headers = await this.getHeaders();

    const response = await this.instance.post(
      `favorite_deals`,
      {
        ...payload,
      },
      {
        headers,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getDealStrategies({
    entityId,
    organizationId,
    strategyFilters,
    entityType,
    signal,
  }) {
    const headers = await this.getHeaders();
    const params = {
      organizationId,
      entityType,
    };
    if (
      strategyFilters.timeWindow &&
      strategyFilters.timeWindow !== CUSTOM_TIME_WINDOW_TEXT
    ) {
      params.timeWindow = strategyFilters.timeWindow;
    } else {
      params.startDate = strategyFilters.startDate;
      params.endDate = strategyFilters.endDate;
    }
    const response = await this.instance.get(
      `deal_strategies/${entityId}?${qs.stringify(params)}`,
      {
        headers,
        signal,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getPGSetupStatus(dealId, dealType) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `pg_setup_status/${dealId}/${dealType}`,
      {
        headers,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getStrategyHealthByDeal({ strategyId, dealId, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `strategy_health_by_deal/${strategyId}/${dealId}`,
      {
        headers,
        signal,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getDealListByStrategyId({ strategyId, organizationId }) {
    const params = {
      organizationId,
    };
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `dealList_by_strategy/${strategyId}`,
      {
        params,
        headers,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getStrategyHealthToplineMetricsByDeal({ strategyId, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `strategy_health_topline_metrics/${strategyId}`,
      {
        headers,
        signal,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getDealListByIds({
    organizationId,
    dealIds,
    timeWindow,
    startDate,
    endDate,
    signal,
  }) {
    const params = {
      organizationId,
      dealIds,
    };
    if (!timeWindow) {
      params.startDate = startDate;
      params.endDate = endDate;
    } else {
      params.timeWindow = timeWindow;
    }

    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `deals_by_ids?${qs.stringify(params, { arrayFormat: "comma" })}`,
      {
        headers,
        signal,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getDealComposition({ dealId, dimensionIds, isGeography, signal }) {
    const params = {
      dimensionIds: dimensionIds.join(","),
      dealId,
    };

    const endPoint = isGeography ? "deal_geo_composition" : "deal_composition";

    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `${endPoint}?${qs.stringify(params)}`,
      {
        headers,
        signal,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getDealIssues({ dealId, signal }) {
    const params = {
      days: 1,
    };
    const headers = await this.getHeaders();
    // TODO(jd): consider changing the bff to pass orgId in the URL instead of as a query string
    const response = await this.instance.get(
      `deal_health/${dealId}?${qs.stringify(params)}`,
      {
        headers,
        signal,
      }
    );
    const data = get(response, "data", {});
    return data;
  }

  async getCreativeGroupsStrategiesTargeted({ creativeGroupIds, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `creatives/groups/${creativeGroupIds}/strategies`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async getCreatives({
    organizationId,
    agencyId,
    advertiserId,
    pageOffset = 0,
    sortBy,
    filterBy,
    withVideoInfo = false,
    withApprovalStatus = false,
    signal,
  }) {
    const params = {
      organizationId,
      agencyId,
      advertiserId,
      pageOffset,
      sortBy,
      filterBy,
      withVideoInfo,
      withApprovalStatus,
    };

    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `creatives?${qs.stringify(params)}`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async getStrategies({
    organizationId,
    agencyId,
    advertiserId,
    pageOffset = 0,
    sortBy,
    filterBy,
    signal,
  }) {
    const params = {
      organizationId,
      agencyId,
      advertiserId,
      pageOffset,
      sortBy,
      filterBy,
    };

    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `strategies?${qs.stringify(params)}`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async getCreativeGroups({
    organizationId,
    agencyId,
    advertiserId,
    pageOffset = 0,
    sortBy,
    filterBy,
    useMaxPageLimit = false,
    signal,
  }) {
    const params = {
      organizationId,
      agencyId,
      advertiserId,
      pageOffset,
      sortBy,
      filterBy,
      useMaxPageLimit,
    };
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `creatives/groups?${qs.stringify(params)}`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async saveNewCreativeGroup({
    creativeGroupName,
    advertiser,
    status,
    signal,
  }) {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `creatives/groups`,
      {
        name: creativeGroupName,
        advertiser,
        status,
        signal,
      },
      { headers }
    );
    return response;
  }

  async bulkUpdateCreatives({
    attribute,
    value,
    creatives,
    vendorsData,
    isNewGroup,
    name,
    advertiser,
    validate,
    signal,
  }) {
    const headers = await this.getHeaders();
    const url = `creatives`;
    try {
      const response = await this.instance.post(
        url,
        {
          attribute,
          value,
          creatives,
          vendorsData,
          isNewGroup,
          name,
          advertiser,
          validate,
          signal,
        },
        { headers }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async bulkUpdateCreativeGroups({ attribute, value, creativeGroups, signal }) {
    const headers = await this.getHeaders();
    const url = `creatives/groups/bulk`;
    return this.instance.post(
      url,
      {
        attribute,
        value,
        creative_groups: creativeGroups,
        signal,
      },
      { headers }
    );
  }

  async getCreativesAssigned({ creativeGroupId, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `creatives/groups/${creativeGroupId}/creatives`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async getSupportedAdServers({ creativeType, signal }) {
    const headers = await this.getHeaders();
    const params = {
      creativeType,
    };
    const response = await this.instance.get(
      `creatives/supportedAdServers?${qs.stringify(params)}`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async getCreativePreviewUrl({ channel, id, signal }) {
    const headers = await this.getHeaders();
    const params = {
      channel,
    };
    const response = await this.instance.get(
      `creatives/${id}/preview_url?${qs.stringify(params)}`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async getCreativePreviewData({ ids, shortCode, signal, cache }) {
    let headers;
    const requestUrl = shortCode
      ? `creatives/sharedPreview/${shortCode}`
      : `creatives/preview`;
    if (ids) {
      headers = await this.getHeaders();
    }
    const params = {
      ...(ids && { ids }),
      ...(cache && { cache: true }),
    };
    const response = await this.instance.get(
      `${requestUrl}?${qs.stringify(params)}`,
      {
        signal,
        ...(headers && { headers }),
      }
    );
    return response;
  }

  async getCreativesApprovalStatus({ creativeIds, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `/creatives/approval_report?creative_ids=(${creativeIds})`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async getCreativeChangeLog({ id, signal, dateRange, channel }) {
    const headers = await this.getHeaders();
    const queryParams = {
      ...(dateRange && {
        startDate: dateRange?.fromDate,
        endDate: dateRange?.toDate,
      }),
      channel,
    };

    const response = await this.instance.get(
      `creatives/${id}/change_log?${qs.stringify(queryParams)}`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async getClassifications({ signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("creative_classifications", {
      signal,
      headers,
    });
    return response;
  }

  async getVendors({ signal, filterBy, useMaxPageLimit = false }) {
    const headers = await this.getHeaders();
    const params = { filterBy, useMaxPageLimit };
    const response = await this.instance.get(
      `vendors?${qs.stringify(params)}`,
      {
        signal,
        headers,
      }
    );
    return response;
  }

  async postSupplyPathTemplate(data) {
    const { selection, body } = data;
    const formData = new FormData();
    formData.append("metadata", JSON.stringify(body.metadata));
    formData.append("file", body.file);
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `supply_path_management/templates/${selection}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers,
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async validatePostalCodes(file, restriction) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("restriction", restriction);
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `validate_postal_codes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers,
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getPostalCodes({ strategyId, signal }) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.get(
        `strategies/${strategyId}/postal_codes`,
        { headers, signal }
      );
      return response?.data;
    } catch (err) {
      return err.response;
    }
  }

  async validateIpAddresses(file) {
    const formData = new FormData();
    formData.append("file", file);
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `validate_ip_addresses`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers,
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getIpAddresses({ strategyId, signal }) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.get(
        `strategies/${strategyId}/ip_addresses`,
        { headers, signal }
      );
      return response?.data;
    } catch (err) {
      return err.response;
    }
  }

  async deleteSupplyPath(supplyPathData) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.delete(
        "supply_path_management/paths",
        {
          headers,
          data: {
            ...supplyPathData,
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getEntityGroups({ entityId, signal }) {
    const headers = await this.getHeaders();
    const url = entityId
      ? `enterprise-controls/entity_groups/${entityId}`
      : `enterprise-controls/entity_groups`;
    const response = await this.instance.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...headers,
      },
      signal,
    });
    const data = get(response, "data");
    return data;
  }

  async getEntity({
    entityType,
    entityId,
    subEntityType,
    ancestorTypes,
    signal,
    queryParams,
  }) {
    try {
      const queryObj = queryParams;
      const headers = await this.getHeaders();
      if (ancestorTypes) queryObj.ancestorTypes = ancestorTypes.join(",");

      const url = subEntityType
        ? `entity/${entityType}/${entityId}/${subEntityType}?${qs.stringify(
            queryObj
          )}`
        : `entity/${entityType}/${entityId}?${qs.stringify(queryObj)}`;

      const response = await this.instance.get(url, {
        signal,
        headers,
      });
      const data = get(response, "data");
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async deleteEntityGroup(entityGroupId) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.delete(
        `enterprise-controls/entity_groups/${entityGroupId}`,
        { headers }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async removeECAssignments(entityGroupId) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.delete(
        `enterprise-controls/entity_groups/${entityGroupId}/controls`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getEcSettingsByAdvertiserIdAndType(advertiserId, type) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.get(
        `enterprise-controls/advertisers/${advertiserId}/controls/${type}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getEcSettingsByOrganizationIdAndType(organizationId, type) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.get(
        `enterprise-controls/organizations/${organizationId}/controls/${type}`,
        { headers }
      );
      const data = get(response, "data");
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getMeritPixels({ advertiserId, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`pixel_bundle/${advertiserId}`, {
      signal,
      headers,
    });
    const data = get(response, "data");
    return data;
  }

  async getStrategyGroups({ campaignId, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `campaigns/${campaignId}/strategy_groups`,
      { signal, headers }
    );
    const data = get(response, "data");
    return data;
  }

  async saveGroup({ campaignId, groupName, strategies, signal }) {
    const headers = await this.getHeaders();
    const id = campaignId.campaignId;
    const url = `campaign/${id}/strategy_groups/add`;
    const response = await this.instance.post(
      url,
      {
        groupName,
        strategies,
      },
      { signal, headers }
    );
    return response;
  }

  async validateVast({ data, signal }) {
    const form = new FormData();
    form.append("vast", data);
    const headers = await this.getHeaders();
    const url = `creatives/validateVast`;
    try {
      const response = await this.instance.post(url, form, {
        signal,
        headers: {
          "Content-Type": "multipart/form-data",
          ...headers,
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async validateMacro({ data, signal }) {
    const headers = await this.getHeaders();
    const url = `creatives/validateMacro`;
    try {
      const response = await this.instance.post(url, data, {
        signal,
        headers,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getUserPreferences({ signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`/user_preferences`, {
      signal,
      headers,
    });
    return response;
  }

  async saveUserPreferences({ namespace, data, signal }) {
    const headers = await this.getHeaders();
    const url = `/save_user_preferences`;
    const response = await this.instance.post(
      url,
      { namespace, data },
      { signal, headers }
    );
    return response;
  }

  async getSiteLists({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("site_lists", {
      params,
      signal,
      headers,
    });
    return response;
  }

  async saveDBAPacing({ campaignId, strategyDBAData, signal }) {
    const headers = await this.getHeaders();
    const url = `campaign/${campaignId}/dynamic_budget_allocation/apply_recommendations`;

    const response = await this.instance.post(
      url,
      {
        strategies: strategyDBAData,
      },
      { signal, headers }
    );
    return response;
  }

  async getSiteListAssignments({ siteListId, params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `site_lists/${siteListId}/assignments`,
      {
        params,
        signal,
        headers,
      }
    );
    return response;
  }

  // TODO: move cancel token into single input object
  async postSiteLists({ siteListsId, status, version }, signal) {
    const headers = await this.getHeaders();
    const url = `site_lists/${siteListsId}`;
    const response = await this.instance.post(
      url,
      {
        status,
        version,
      },
      { signal, headers }
    );
    return response;
  }

  async exportSiteList({ siteListId, signal }) {
    const headers = await this.getHeaders();
    const url = `export_site_list/${siteListId}`;
    return this.instance.get(url, { signal, headers });
  }

  async getTZAdserver({ signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("create_campaign_meta/", {
      signal,
      headers,
    });
    const data = get(response, "data");
    return data;
  }

  async getAdserver({ signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("ad_servers_meta/", {
      signal,
      headers,
    });
    const data = get(response, "data");
    return data;
  }

  async getCurrencyRate({ currencyCode }) {
    const headers = await this.getHeaders();
    const params = {
      currencyCode,
    };

    const response = await this.instance.get("currency_rate", {
      params,
      headers,
    });

    return get(response, "data");
  }

  async getVertical({ signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("vertical_meta/", {
      signal,
      headers,
    });
    const data = get(response, "data");
    return data;
  }

  async getChannels({ signal }) {
    const headers = await this.getHeaders();

    const response = await this.instance.get("channels", {
      signal,
      headers,
    });

    return get(response, "data");
  }

  async getLocations({ params, signal }) {
    const headers = await this.getHeaders();

    const response = await this.instance.get("locations", {
      params,
      signal,
      headers,
    });

    return get(response, "data");
  }

  async getUsers({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("users", {
      params,
      signal,
      headers,
    });
    return response;
  }

  async getContracts({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("contracts", {
      params,
      signal,
      headers,
    });
    return response;
  }

  async getPixelTargetingAdvertisers({ signal, params }) {
    const { advertiserId } = params;
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `advertisers/${advertiserId}/pixel_targeting_advertisers`,
      {
        params,
        signal,
        headers,
      }
    );
    return response;
  }

  async getInternalFees({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("internal_fees_list", {
      params,
      signal,
      headers,
    });
    return response;
  }

  writeInternalFees = async ({ entity }) => {
    const form = new FormData();
    form.append("data", JSON.stringify(entity));
    try {
      const headers = await this.getHeaders();
      const response = await this.instance.post("internal_fees", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...headers,
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  async getInternalFee({ ecoSupply, ecoOrg, signal }) {
    const params = { ecoSupply, ecoOrg };
    const headers = await this.getHeaders();
    const response = await this.instance.get("internal_fee", {
      params,
      headers,
      signal,
    });
    const data = get(response, "data");
    return data;
  }

  async getStandardAttributes({ signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("attributes/whitelist", {
      signal,
      headers,
    });
    return get(response, "data");
  }

  async getSupplies({ params, signal, path }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(path, {
      params,
      signal,
      headers,
    });

    return get(response, "data");
  }

  async ungroupStrategyGroups({ campaignId, groupName, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `campaign/${campaignId}/strategy_groups/ungroup`,
      {
        groups: groupName,
      },
      { headers, signal }
    );
    const data = get(response, "data");
    return data;
  }

  async removeStrategyGroups({ campaignId, groupName, strategyId, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `campaign/${campaignId}/strategy_groups/remove`,
      {
        groups: groupName,
        strategies: strategyId,
      },
      { signal, headers }
    );
    const data = get(response, "data");
    return data;
  }

  async writeEntity({ entityType, entityId, subEntityType, entity, signal }) {
    const headers = await this.getHeaders();
    // note that order matters here as the adama json format will step on entityType if its not 'last'
    try {
      const params = {
        ...entity,
        entityType,
        entityId,
        ...(entityType !== "users" && { subEntityType }),
      };

      const response = await this.instance.post(`writeentity/`, params, {
        headers,
        signal,
        ...(entityType === "site_lists" && { timeout: 180000 }),
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  // TODO: consider adding a canceltoken to this
  async writeBudgetAllocation(data) {
    const headers = await this.getHeaders();
    const response = this.instance.post("budget_allocation", data, { headers });
    return response;
  }

  async getTargetingTroubleshootingByStrategyId({
    strategyId,
    isOcient,
    signal,
  }) {
    const params = {
      isOcient,
    };
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `strategy/${strategyId}/troubleshooting?${qs.stringify(params)}`,
      { headers, signal }
    );
    const data = get(response, "data");
    return data;
  }

  async getOpenAuctionTroubleshootingByStrategyId({ strategyId, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `open_auction/troubleshooting/${strategyId}`,
      { headers, signal }
    );
    const data = get(response, "data");
    return data;
  }

  async getCampaignsPerformance({
    campaignId,
    signal,
    dateRange,
    organizationId,
    reportType,
    timeRollup,
  }) {
    try {
      const headers = await this.getHeaders();
      const ids = campaignId.join(",");
      const url = `campaigns/reporting?${dateRange}&campaign=${ids}&organization=${organizationId}&reportType=${reportType}&timeRollup=${timeRollup}`;
      const response = await this.instance.get(url, { headers, signal });
      const data = get(response, "data");
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getStrategiesPerformance(
    campaignId,
    organizationId,
    strategiesId,
    reportType,
    timeRollup,
    signal,
    dateRange
  ) {
    try {
      const headers = await this.getHeaders();
      const ids = strategiesId.join(",");
      const url = `strategy/reporting?${dateRange}&campaign=${campaignId}&strategy=${ids}&organization=${organizationId}&reportType=${reportType}&timeRollup=${timeRollup}`;
      const response = await this.instance.get(url, { headers, signal });
      const data = get(response, "data");
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async getAudienceSegmentsList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `advertisers/${params.advertiserId}/audience_segments`,
      {
        params,
        signal,
        headers,
      }
    );
    return response;
  }

  async getFrequentlyUsedAudiencesList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `/audience_segments/frequently_used`,
      {
        params,
        signal,
        headers,
      }
    );
    return response;
  }

  async getAdaptiveSegmentsList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `advertisers/${params.advertiserId}/adaptive_segments`,
      {
        params,
        signal,
        headers,
      }
    );
    return response;
  }

  async postAudienceSegmentsEstimate({ currencyCode, segments, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `audience_segments/estimate`,
      { currency_code: currencyCode, segments },
      {
        headers,
        signal,
      }
    );
    return response;
  }

  async getContextualSegmentsList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`/contextual/segments`, {
      params,
      signal,
      headers,
    });
    return response;
  }

  async getContextualProvidersList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`/contextual/providers`, {
      params,
      signal,
      headers,
    });
    return response;
  }

  async getFrequentlyUsedList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`/contextual/frequently_used`, {
      params,
      signal,
      headers,
    });
    return response;
  }

  async getContextualTypeList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`/contextual/segment_objectives`, {
      params,
      signal,
      headers,
    });
    return response;
  }

  async postContextualCPMCost({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.post(`contextual/estimate`, params, {
      headers,
      signal,
    });
    return response;
  }

  async getStrategyPlansList({ organizationId, params, signal }) {
    const headers = await this.getHeaders();
    return this.instance.get(`strategy_plans/${organizationId}`, {
      params,
      signal,
      headers,
    });
  }

  async deleteStrategyPlan({ id }) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.delete(`strategy_plans/${id}`, {
        headers,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getStrategiesByCampaign({ campaignId, status }) {
    const queryObj = { status };
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.get(
        `campaigns/${campaignId}/strategies?${qs.stringify(queryObj)}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getStrategiesTargeting({ ids, targetingType, signal }) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.get(`strategies_targeting`, {
        headers,
        signal,
        params: { ids: `(${ids.join(",")})`, targetingType },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async saveStrategiesBulkTargeting(data) {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `strategies/targeting/bulk`,
        data,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getFirstPartyPixelList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("pixels", {
      params,
      signal,
      headers,
    });
    return response;
  }

  async getTechnologyList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`technology/${params.dimension}`, {
      params,
      signal,
      headers,
    });
    return get(response, "data");
  }

  async getAudioVideoList({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get("audio_video", {
      params,
      signal,
      headers,
    });
    return get(response, "data");
  }

  async getProvidersByAdvertiserId({
    advertiserId,
    signal,
    isBrainEnabled = false,
    isThirdParty = false,
  }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `advertisers/${advertiserId}/providers`,
      {
        params: { isBrainEnabled, isThirdParty },
        signal,
        headers,
      }
    );

    return get(response, "data");
  }

  async getSiteListsByOrgId({ params, signal }) {
    const headers = await this.getHeaders();
    const response = await this.instance.get(
      `organizations/${params.organizationId}/site_lists`,
      {
        params,
        signal,
        headers,
      }
    );
    return response;
  }

  async getReportingMetrics({ reportType, signal }) {
    try {
      const headers = await this.getHeaders();
      const url = `reporting/metrics?reportType=${reportType}`;
      const response = await this.instance.get(url, { headers, signal });

      return get(response, "data");
    } catch (error) {
      return error.response;
    }
  }

  parseFile = async ({ entity, entityType }) => {
    const form = new FormData();
    form.append("entityType", entityType);
    form.append("file", entity.file);
    form.append("organizationId", entity.organizationId);
    const headers = await this.getHeaders();
    const apiPath =
      entityType === "deals_bulk"
        ? "deals_bulk_upload"
        : "supply_path_bulk_upload";
    try {
      const response = await this.instance.post(apiPath, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...headers,
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  siteListsStep1 = async ({ entity }) => {
    const form = new FormData();
    form.append("entityType", "site_list");
    form.append("entityId", entity.entityId);
    form.append("step", "step1");
    form.append("file", entity.file);
    form.append("overwrite", entity.overwrite);
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post("site_list/step1", form, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...headers,
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  bulkUpload = async ({ entity, signal }) => {
    const {
      action,
      advertiserId,
      adServer,
      files,
      creatives,
      creativeType,
      uploadId,
      isMMAS,
    } = entity;

    const form = new FormData();
    form.append("advertiserId", advertiserId);
    form.append("creativeType", creativeType.toUpperCase());

    switch (action) {
      case "create":
        form.append("creatives", JSON.stringify(creatives));
        if (creativeType === NATIVE) form.append("uploadId", uploadId);
        break;
      case "parse":
      default:
        if (creativeType !== NATIVE && !isMMAS)
          form.append("adServer", adServer);

        if (Array.isArray(files)) {
          files.forEach((file, index) => {
            form.append(`file${index}`, file);
          });
        } else {
          form.append("file", files);
        }
    }

    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `creatives/bulkUpload/${
          // eslint-disable-next-line no-nested-ternary
          creativeType === NATIVE
            ? "native"
            : isMMAS
            ? "mediamath"
            : "third_party"
        }/${action}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers,
          },
          signal,
          ...(creativeType === NATIVE && { timeout: 1000 * 3000 }),
        }
      );

      return response;
    } catch (error) {
      return error.response;
    }
  };

  nativeCreativeEdit = async ({ entity, signal }) => {
    const form = getNativeCreativeData({ form: new FormData(), entity });
    const params = {
      channel: NATIVE.toLowerCase(),
    };

    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `creatives/${entity.id}?${qs.stringify(params)}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers,
          },
          signal,
        }
      );

      return response;
    } catch (error) {
      return error.response;
    }
  };

  newMediaMathAdServer = async ({ entity, signal }) => {
    const form = getMMASBasicFormData({ form: new FormData(), entity });
    if (entity.file) {
      form.append("convert_to_vast_4", entity.convert_to_vast_4);
    }

    form.append("new_media_math_creative", true);
    if (!entity.useLocalVideoUpload && (entity.file || entity.audioFile)) {
      form.append("file", entity.audioFile || entity.file);
    }

    if (entity.manual_vendors) {
      form.append("manualVendors", JSON.stringify(entity.manual_vendors));
    }
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(`creatives/video`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...headers,
        },
        signal,
      });

      if (entity.useLocalVideoUpload) {
        const {
          data: { uploadInfo },
        } = response;

        if (uploadInfo) {
          const uploadFormData = new FormData();
          const uploadHeaders = {
            "Content-Type": "multipart/form-data",
            ...headers,
          };

          // Prevent CORS error by removing client-trace-id
          delete uploadHeaders["client-trace-id"];

          uploadFormData.append("key", uploadInfo.key);
          uploadFormData.append("file", entity.audioFile || entity.file);
          await axios.post(uploadInfo.url, uploadFormData, {
            headers: {
              ...uploadHeaders,
            },
            signal,
          });
        }
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };

  newMediaMathAdServerDisplay = async ({ entity, signal }) => {
    const form = getMMASBasicFormData({ form: new FormData(), entity });
    form.append("new_mmas_display_creative", true);
    if (entity.displayFile) {
      form.append("file", entity.displayFile);
    }
    if (entity.manual_vendors) {
      form.append("manualVendors", JSON.stringify(entity.manual_vendors));
    }
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `creatives/mmas_display`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers,
          },
          signal,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  newMediaMathAdServerDisplayEdit = async ({ entity, signal }) => {
    const form = getMMASBasicFormData({ form: new FormData(), entity });
    form.append("mainFile", JSON.stringify(entity.mainFile));
    form.append("backupFile", JSON.stringify(entity.backupFile));
    form.append("displayFile", entity.displayFile);
    form.append("backupDisplayFile", entity.backupDisplayFile);
    form.append("entityId", entity.id);
    form.append("secure", false);
    form.append("entityType", "display_creatives");
    form.append("subEntityType", "");

    if (entity.manual_vendors) {
      form.append("manualVendors", JSON.stringify(entity.manual_vendors));
    }

    const params = {
      channel: DISPLAY.toLowerCase(),
    };

    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `creatives/mmas_display/${entity.id}?${qs.stringify(params)}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers,
          },
          signal,
        }
      );

      return response;
    } catch (error) {
      return error.response;
    }
  };

  newMediaMathAdServerEdit = async ({ entity, signal }) => {
    const form = getMMASBasicFormData({ form: new FormData(), entity });
    form.append("entityId", entity.id);
    form.append("secure", false);
    form.append("entityType", "video_creatives");
    form.append("subEntityType", "");

    if (entity.manual_vendors) {
      form.append("manualVendors", JSON.stringify(entity.manual_vendors));
    }

    const params = {
      channel: VIDEO.toLowerCase(),
    };
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `creatives/${entity.id}?${qs.stringify(params)}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...headers,
          },
          signal,
        }
      );

      return response;
    } catch (error) {
      return error.response;
    }
  };

  getCreativeGroupWithAssignments = async ({ entityId, signal }) => {
    const headers = await this.getHeaders();
    const response = await this.instance.get(`creatives/groups/${entityId}`, {
      signal,
      headers,
    });
    const data = get(response, "data", {});
    return data;
  };

  saveCreativeGroupWithAssignments = async ({ entity, signal }) => {
    const headers = await this.getHeaders();
    try {
      const response = await this.instance.post(
        `creatives/groups/creation-assignment`,
        {
          ...entity,
        },
        { headers, signal }
      );
      return response;
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        return error.response;
      }
      return error.response;
    }
  };

  updateCreativeGroupWithAssignments = async ({ entity, entityId, signal }) => {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `creatives/groups/update-assignment`,
      {
        ...entity,
        entityId,
      },
      { headers, signal }
    );

    return response;
  };

  getMarvellEntity = async (ent) => {
    return this.getEntity(ent);
  };

  orgSearch = async ({
    name,
    limit = 50,
    activeOnly,
    only,
    signal,
    terminated,
  }) => {
    const params = {
      name,
      limit,
      only,
      active_only: activeOnly,
      terminated,
    };

    const headers = await this.getHeaders();
    const response = await this.instance.get("search", {
      params,
      headers,
      signal,
    });
    const data = get(response, "data.data", {});
    return data;
  };

  runPlanningForecast = async ({ entityId, entity, shouldSave, signal }) => {
    const headers = await this.getHeaders();
    const url =
      shouldSave && entityId
        ? `strategy_plans/${entityId}/forecast`
        : `strategy_plans/forecast`;
    const response = await this.instance.post(
      url,
      {
        ...entity,
        entityId,
      },
      {
        headers,
        signal,
      }
    );
    return response;
  };

  getStrategyForecast = async ({ params, signal }) => {
    const headers = await this.getHeaders();
    const response = await this.instance.post("strategy_forecast", params, {
      headers,
      signal,
    });
    return response;
  };

  getLookerEmbedURL = async (user) => {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      "looker_embed_url",
      {
        user,
      },
      {
        headers,
        // signal,
      }
    );

    return response;
  };

  getPathsReporting = async ({
    pageOffset,
    timeWindow,
    startDate,
    endDate,
    supplyType,
    pageLimit,
    signal,
    sortBy,
    sortDescending,
    filters,
  }) => {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `paths_reporting`,
      {
        pagination: { pageIndex: pageOffset, pageSize: pageLimit },
        timeWindow,
        startDate,
        endDate,
        supplyType,
        order: { field: sortBy, desc: sortDescending },
        filters,
      },
      {
        headers,
        signal,
      }
    );
    const data = get(response, "data", {});
    return data;
  };

  searchEntities = async ({
    entityType,
    limit,
    search,
    organizationId,
    signal,
  }) => {
    const headers = await this.getHeaders();
    const response = await this.instance.post(
      `/supply_path_management/search_entities`,
      {
        entityType,
        limit,
        search,
        organizationId,
      },
      {
        headers,
        signal,
      }
    );

    const data = get(response, "data", {});
    return data;
  };
}

let connection;

const getConnection = () => connection;

export const useInitGetConnection = ({ getAccessToken } = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  connection = new Connection(
    getAccessToken
      ? {
          getAccessTokenSilently: getAccessToken,
        }
      : { getAccessTokenSilently }
  );
};

export default getConnection;
