// TODO(team): aggregate filter helper extraction
// add more here as needed
import DateTime from "core/pattern/micro/presenter/DateTime";

export const FILTER_GREATER_THAN = 0;
export const FILTER_LESS_THAN = 1;
export const FILTER_EQUAL_TO = 2;
export const SYMBOLS = {
  [FILTER_GREATER_THAN]: ">",
  [FILTER_LESS_THAN]: "<",
  [FILTER_EQUAL_TO]: "=",
};

export const ratioToPercent = (ratio) => {
  return parseFloat((Number(ratio) * 100).toFixed(2));
};

export const numericCompare = ({ operator, filterVal, dataVal, isRate }) => {
  const filterValNum = Number(filterVal);
  const dataValNum = isRate
    ? ratioToPercent(dataVal)
    : Number(Number(dataVal).toFixed(3));

  switch (operator) {
    case FILTER_LESS_THAN:
      return dataValNum < filterValNum;
    case FILTER_GREATER_THAN:
      return dataValNum > filterValNum;
    case FILTER_EQUAL_TO:
      return dataValNum === filterValNum;
    default:
      return true;
  }
};

export const stringCompare = ({ filterVal, dataVal }) =>
  String(filterVal).toUpperCase().includes(String(dataVal).toUpperCase());

export const formatNumericFilter = ({ operator, value, isRate } = {}) => {
  if (typeof operator === "undefined" || typeof value === "undefined") {
    return "";
  }

  return `${SYMBOLS[operator]} ${value}${isRate ? "%" : ""}`;
};

export const formatDateFilter = ({
  operator,
  value,
  messages = {},
  formatDate,
} = {}) => {
  if (
    typeof operator === "undefined" ||
    typeof value === "undefined" ||
    value === ""
  ) {
    return "";
  }
  const prefix = {
    [FILTER_GREATER_THAN]: messages.after,
    [FILTER_LESS_THAN]: messages.before,
    [FILTER_EQUAL_TO]: messages.equalTo,
  };
  const dateStr = DateTime({
    value: value?.toISOString(),
    formatter: formatDate,
  });

  return `${prefix[operator]} ${dateStr}`;
};
