import React, { Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Colors, Icon } from "@mediamath/design-system-eng";
import SuspenseLoader from "../presenter/SuspenseLoader";

const ENTERING = "entering";
const EXITING = "exiting";
const EXITED = "exited";

export const directionType = {
  LEFT: "left",
  RIGHT: "right",
};

// const PANEL_STATUS = { ENTERING, EXITING, EXITED };

const SidebarManagerContext = React.createContext({});

/**
 * Stack sidebar hook
 * @param {"left"|"right"} direction
 * @returns {{pop: *, toggleFullscreen: *, push: *}}
 */
const useStackedSidebar = (options = {}) => {
  const { direction = directionType.RIGHT } = options;
  const { push, pop, toggleFullscreen, setDirection } = React.useContext(
    SidebarManagerContext
  );

  useEffect(() => {
    if (setDirection && direction) {
      setDirection(direction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  return React.useMemo(() => {
    return { push, pop, toggleFullscreen, setDirection };
  }, [push, pop, toggleFullscreen, setDirection]);
};

const reducer = (lastState = {}, action) => {
  switch (action.type) {
    case "onToggleFullscreen": {
      const nextState = {
        ...lastState,
        fullscreen: !lastState.fullscreen,
      };

      return nextState;
    }
    case "setDirection": {
      const { direction } = action;
      const nextState = {
        ...lastState,
        direction,
      };
      return nextState;
    }
    case "onPush": {
      const {
        key,
        node,
        contentWidth,
        minWidth,
        headerConfig,
        onPopped,
      } = action;
      const nextState = {
        ...lastState,
        stackSize: 1 + lastState.stackSize,
        stack: lastState.stack.slice(),
      };
      nextState.stack[lastState.stackSize] = {
        key,
        node,
        minWidth,
        maxWidth: 0,
        contentWidth,
        status: ENTERING,
        headerConfig,
        onPopped,
      };

      // duplicated in onPush/onTruncate
      let maxWidth = 0;
      for (let index = nextState.stack.length - 1; index >= 0; index -= 1) {
        const prev = nextState.stack[index];
        maxWidth =
          prev.status === EXITING || prev.status === EXITED
            ? maxWidth
            : Math.max(prev.contentWidth, prev.minWidth + maxWidth);
        nextState.stack[index] = { ...prev, maxWidth };
      }

      return nextState;
    }

    case "onTruncate": {
      if (lastState.stackSize < 1) {
        return lastState;
      }

      const { limit = lastState.stackSize - 1 } = action;
      const nextSize = Math.max(
        0,
        Math.min(lastState.stackSize, Math.floor(limit))
      );
      const nextState = {
        ...lastState,
        fullscreen: false,
        stackSize: nextSize,
        stack: lastState.stack.slice(),
      };

      if (nextSize >= lastState.stackSize) {
        return lastState;
      }

      for (let index = lastState.stackSize - 1; index >= nextSize; index -= 1) {
        const { onPopped, status } = lastState.stack[index];
        if (status !== EXITING && status !== EXITED) {
          if (typeof onPopped === "function" && onPopped() === false) {
            nextState.stackSize = index + 1;
            break;
          }
        }

        nextState.stack[index] = {
          ...lastState.stack[index],
          status: EXITING,
        };
      }

      // duplicated in onPush/onTruncate
      let maxWidth = 0;
      for (let index = nextState.stack.length - 1; index >= 0; index -= 1) {
        const prev = nextState.stack[index];
        maxWidth =
          prev.status === EXITING || prev.status === EXITED
            ? maxWidth
            : Math.max(prev.contentWidth, prev.minWidth + maxWidth);
        nextState.stack[index] = { ...prev, maxWidth };
      }

      return nextState;
    }

    case "onExit": {
      const { panelIndex } = action;

      if (panelIndex < lastState.stackSize) {
        return lastState;
      }

      if (panelIndex >= lastState.stack.length) {
        return lastState;
      }

      if (lastState.stack[panelIndex].status === EXITED) {
        return lastState;
      }

      const nextState = {
        ...lastState,
        stack: lastState.stack.slice(),
      };
      nextState.stack[panelIndex] = {
        ...lastState.stack[panelIndex],
        status: EXITED,
      };

      let index = nextState.stack.length - 1;
      while (index > -1 && nextState.stack[index].status === EXITED) {
        nextState.stack.pop();
        index -= 1;
      }

      return nextState;
    }

    default: {
      return lastState;
    }
  }
};

const TRANSITION_TIME_MS = 300;
const OVERLAY_OPACITY = 0.15;
const BACKGROUND_COLOR = "#000000";
const PANEL_Z_INDEX = 111;
const TRANSITION = `opacity ${TRANSITION_TIME_MS}ms`;

const StSidebarPanel = styled("div")`
  transition: right ${TRANSITION_TIME_MS}ms;
  position: ${({ fullscreen }) => (fullscreen ? "fixed" : "absolute")};
  left: ${({ fullscreen }) => (fullscreen ? 0 : "auto")};
  right: ${({ fullscreen, status, right, contentWidth }) => {
    if (fullscreen) return 0;
    return status === EXITING || status === EXITED
      ? `${-contentWidth}px`
      : `${right}px`;
  }};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ fullscreen, contentWidth }) => {
    if (fullscreen) return "100%";
    return `${contentWidth}px` || "800px";
  }};
  background: ${Colors.ColorWhite};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
  z-index: ${PANEL_Z_INDEX};
`;

const StSidebarHeader = styled("div")`
  padding: 12px;
  border-bottom: 1px solid ${Colors.ColorGrey200};
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0;

  & i {
    margin: 0 0 0 6px !important;
    padding: 3px !important;
    font-size: 16px !important;
    line-height: 16px !important;
    cursor: pointer;
  }
`;

const StAbsoluteCover = styled("div")`
  position: absolute;
  top: ${({ top }) => top || undefined};
  right: ${({ right }) => right || undefined};
  bottom: ${({ bottom }) => bottom || undefined};
  left: ${({ left }) => left || undefined};
  background: ${({ background }) => background || undefined};
  opacity: ${({ opacity }) => opacity || undefined};
  transition: ${({ transition }) => transition || undefined};
  pointer-events: ${({ pointerEvents }) => pointerEvents || undefined};
  z-index: ${PANEL_Z_INDEX - 1};
`;

const StSidebarTitle = styled("div")`
  display: flex;
  align-items: center;
  > i {
    padding: 0px !important;
  }
`;

const SidebarPanel = (props) => {
  const {
    children,
    status,
    contentWidth,
    maxWidth,
    fullscreen,
    panelIndex,
    truncate,
    pop,
    toggleFullscreen,
    dispatch,
    stackSize,
    headerConfig,
    direction,
  } = props;

  const [distance, setDistance] = React.useState(-maxWidth);
  const ref = React.useRef(null);
  const { header, hearderToolTip, onFullScreenClick, onCloseClick } =
    headerConfig || {};
  const isRight = direction === directionType.RIGHT;

  React.useEffect(() => {
    if (ref.current) {
      // hack - forcing paint to make layout/metrics consistent for animation
      const x = ref.current.offsetWidth; // eslint-disable-line
      setDistance(maxWidth - contentWidth);
    }
  }, [setDistance, maxWidth, contentWidth, ref.current]); // eslint-disable-line

  React.useEffect(() => {
    setTimeout(() => {
      if (status === EXITING) {
        dispatch({ type: "onExit", panelIndex });
      }
    }, TRANSITION_TIME_MS);
  }, [dispatch, panelIndex, status, TRANSITION_TIME_MS]); // eslint-disable-line

  const directionParams = {
    [isRight ? directionType.RIGHT : directionType.LEFT]: distance,
  };

  return (
    <StSidebarPanel
      ref={ref}
      contentWidth={contentWidth}
      status={status}
      fullscreen={fullscreen}
      {...directionParams}
    >
      <Suspense fallback={<SuspenseLoader />}>
        <StSidebarHeader data-testid="sidebar-header">
          <StSidebarTitle>
            {header}
            {hearderToolTip}
          </StSidebarTitle>
          <div>
            <Icon
              data-testid="sidebar-fullscreen-btn"
              name={fullscreen ? "contract-fill" : "expand-fill"}
              onClick={onFullScreenClick || (() => toggleFullscreen())}
            />
            <Icon
              data-testid="sidebar-close-btn"
              name="delete-fill"
              onClick={onCloseClick || (() => pop())}
            />
          </div>
        </StSidebarHeader>
        {children}
        <StAbsoluteCover
          background={BACKGROUND_COLOR}
          left="0"
          right="0"
          top="0"
          bottom="0"
          opacity={stackSize > panelIndex + 1 ? OVERLAY_OPACITY : "0"}
          pointerEvents={stackSize > panelIndex + 1 ? "all" : "none"}
          transition={TRANSITION}
          onClick={() => truncate(panelIndex + 1)}
        />
      </Suspense>
    </StSidebarPanel>
  );
};

SidebarPanel.propTypes = {
  children: PropTypes.node,
  status: PropTypes.oneOf([EXITED, EXITING, ENTERING]),
  contentWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  fullscreen: PropTypes.bool,
  panelIndex: PropTypes.number,
  truncate: PropTypes.func,
  pop: PropTypes.func,
  toggleFullscreen: PropTypes.func,
  dispatch: PropTypes.func,
  stackSize: PropTypes.number,
  headerConfig: PropTypes.shape({
    header: PropTypes.node,
    onFullScreenClick: PropTypes.func,
    onCloseClick: PropTypes.func,
  }),
  direction: PropTypes.oneOf([directionType.LEFT, directionType.RIGHT]),
};

SidebarPanel.defaultProps = {
  children: undefined,
  status: undefined,
  contentWidth: 0,
  maxWidth: 0,
  fullscreen: false,
  panelIndex: 0,
  truncate: undefined,
  pop: undefined,
  toggleFullscreen: undefined,
  dispatch: undefined,
  stackSize: 0,
  headerConfig: undefined,
  direction: directionType.RIGHT,
};

const SidebarManagerProvider = (props) => {
  const { children } = props;
  const [
    { fullscreen, stackSize, stack, direction },
    setState,
  ] = React.useState({
    fullscreen: false,
    stackSize: 0,
    stack: [],
    direction: directionType.RIGHT,
  });
  const isRight = direction === directionType.RIGHT;

  const dispatch = React.useCallback(
    (action) => {
      setState((lastState) => {
        return reducer(lastState, action);
      });
    },
    [setState]
  );

  const value = React.useMemo(() => {
    return {
      toggleFullscreen: () => {
        dispatch({ type: "onToggleFullscreen" });
      },
      push: ({
        key,
        node,
        contentWidth = 800,
        minWidth = 100,
        headerConfig,
        onPopped,
      }) => {
        dispatch({
          type: "onPush",
          key,
          node,
          contentWidth,
          minWidth,
          headerConfig,
          onPopped,
        });
      },
      pop: () => {
        dispatch({ type: "onTruncate" });
      },
      setDirection: (dir) => {
        dispatch({ type: "setDirection", direction: dir });
      },
      truncate: (limit) => {
        dispatch({ type: "onTruncate", limit });
      },
    };
  }, [dispatch]);

  const { truncate, toggleFullscreen, pop } = value;

  const coverDirectionParams = {
    [isRight ? directionType.RIGHT : directionType.LEFT]: "0",
  };

  return (
    <SidebarManagerContext.Provider value={value}>
      <div>
        {children}
        <StAbsoluteCover
          background={BACKGROUND_COLOR}
          left="0"
          right="0"
          top="0"
          bottom="0"
          pointerEvents={stackSize > 0 ? "all" : "none"}
          opacity={stackSize > 0 ? OVERLAY_OPACITY : "0"}
          transition={TRANSITION}
          onClick={() => truncate(0)}
        />
        <StAbsoluteCover
          opacity="1"
          top="0"
          bottom="0"
          {...coverDirectionParams}
        >
          {stack.map(
            (
              { key, node, status, maxWidth, contentWidth, headerConfig },
              panelIndex
            ) => (
              <SidebarPanel
                key={key || panelIndex}
                panelIndex={panelIndex}
                truncate={truncate}
                pop={pop}
                toggleFullscreen={toggleFullscreen}
                dispatch={dispatch}
                status={status}
                maxWidth={maxWidth}
                contentWidth={contentWidth}
                fullscreen={fullscreen && panelIndex === stackSize - 1}
                stackSize={stackSize}
                headerConfig={headerConfig}
                direction={direction}
              >
                {node}
              </SidebarPanel>
            )
          )}
        </StAbsoluteCover>
      </div>
    </SidebarManagerContext.Provider>
  );
};

SidebarManagerProvider.propTypes = {
  children: PropTypes.node,
};

SidebarManagerProvider.defaultProps = {
  children: undefined,
};

export default SidebarManagerProvider;

export { useStackedSidebar };

/*
// -- -- -- -- -- -- -- -- -- --
// Sidebar Usage Example (to be used in a presenter)
// Define Sidebar Content
const SidebarContent = (props) => {
  const { index } = props;
  return (
    <div
      style={{
        padding: "12px",
        height: "100%",
        width: "100%",
      }}
    >
      {`${index} Panel content belongs here`}
    </div>
  );
};

SidebarContent.propTypes = {
  index: PropTypes.number,
};

SidebarContent.defaultProps = {
  index: 0,
};

// Example using "Push" or "Pop" to add or remove sidebars
let panelCount = 0;

const ActionButtons = () => {
  const { push, pop, toggleFullscreen } = useStackedSidebar();

  const onPushClick = React.useCallback(() => {
    const minWidth = 100;
    const contentWidth = 800;

    push({
      contentWidth,
      minWidth,
      node: <SidebarContent index={panelCount} />,
      headerConfig: {
        header: <span>{`${panelCount} - Some Header Title Here`}</span>,
        onFullScreenClick: () => {
          toggleFullscreen();
        },
        onCloseClick: () => {
          pop();
        },
      },
    });
    panelCount += 1;
  }, [push, toggleFullscreen, pop]);

  const onPopClick = React.useCallback(() => {
    pop();
  }, [pop]);

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        top: "0px",
        left: "0px",
        zIndex: "100",
      }}
    >
      <Button onClick={onPushClick}>Push</Button>
      <Button onClick={onPopClick}>Pop</Button>
    </div>
  );
};
// End Sidebar temp demo
// -- -- -- -- -- -- -- -- -- --
*/
