import { useEffect, useState } from "react";

const initializer = (key, defaultValue, deserialize) => {
  const valueInLocalStorage = localStorage.getItem(key);

  if (valueInLocalStorage) {
    return deserialize(valueInLocalStorage);
  }

  return typeof defaultValue === "function" ? defaultValue() : defaultValue;
};

// courtesy of Kent C. Dodds
const useLocalStorage = (
  key,
  defaultValue = "",
  { serialize = JSON.stringify, deserialize = JSON.parse } = {} // use custom parser if needed
) => {
  const [prevKey, setPrevKey] = useState(key);
  const [state, setState] = useState(() =>
    initializer(key, defaultValue, deserialize)
  );

  useEffect(() => {
    localStorage.setItem(key, serialize(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, serialize]);

  // Hook must set state when cache key changes
  useEffect(() => {
    if (key !== prevKey) {
      const newState = initializer(key, defaultValue, deserialize);
      setState(newState);
      setPrevKey(key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return [state, setState];
};

export default useLocalStorage;
