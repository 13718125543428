const Entities = {
  ATOMIC_CREATIVE: "atomic_creative",
  AD_SERVER: "ad_server",
  ADVERTISER: "advertiser",
  AUDIENCE_SEGMENT: "audience_segment",
  AGENCY: "agency",
  CAMPAIGN: "campaign",
  CAMPAIGNS: "campaigns",
  INLINE_CAMPAIGN_EDIT: "inline_campaign",
  INLINE_STRATEGY_EDIT: "inline_strategy",
  CONCEPT: "concept",
  CREATIVE: "creative",
  CREATIVE_APPROVAL: "creative_approval",
  DEAL: "deal",
  DEAL_GROUPS: "deal_groups",
  ENTERPRISE_CONTROLS: "enterprise_controls",
  FIRST_PARTY_DATA: "first_party_data",
  ORGANIZATION: "organization",
  PIXEL: "pixel",
  PIXEL_ATTRIBUTE: "pixel_attribute",
  PIXEL_BUNDLE: "pixel_bundle",
  PIXEL_PROVIDER: "pixel_provider",
  PLACEMENT_SLOT: "placement_slot",
  PUBLISHER: "publisher",
  PUBLISHER_SITE: "publisher_site",
  SITE_LIST: "site_list",
  SITE_PLACEMENT: "site_placement",
  STRATEGY: "strategy",
  STRATEGIES: "strategies",
  STRATEGY_CONCEPT: "strategy_concept",
  STRATEGY_DAY_PART: "strategy_day_part",
  STRATEGY_DOMAIN: "strategy_domain",
  STRATEGY_GROUP: "strategy_group",
  STRATEGY_SUPPLY_SOURCE: "strategy_supply_source",
  TARGET_DIMENSION: "target_dimension",
  TARGET_VALUE: "target_value",
  TARGETING_VENDOR: "targeting_vendor",
  USER: "user",
  VENDOR: "vendor",
  VENDOR_CONTRACT: "vendor_contract",
  VENDOR_DOMAIN: "vendor_domain",
  VENDOR_PIXEL: "vendor_pixel",
  VENDOR_PIXEL_DOMAIN: "vendor_pixel_domain",
  VERTICAL: "vertical",
  SUPPLY_PATHS: "supply_paths",

  // Subentities
  EXCHANGE_SEAT: "seat",
  PERMISSION: "permission",
  SESSION: "session",

  // Others
  CONTRACT: "contract",
  SUPPLY_SOURCE: "supply_source",
};

export default Entities;

export const basePath = "/api/v2.0";

export const Subentities = {
  [Entities.EXCHANGE_SEAT]: {
    path: ({ parentId }) => `${basePath}/organizations/${parentId}/seats`,
    postformat: "formdata",
  },
  [Entities.PERMISSION]: {
    path: ({ parentId }) => `${basePath}/users/${parentId}/permissions`,
    postformat: "formdata",
  },
};

export const Others = {
  [Entities.SUPPLY_SOURCE]: {
    path: `${basePath}/supply_sources`,
    postformat: "formdata",
  },
  [Entities.USER]: { path: `${basePath}/users`, postformat: "formdata" },
  [Entities.CONTRACT]: {
    path: `${basePath}/contracts`,
    postformat: "formdata",
  },
}; // TODO(jmoore): uploading formdata not actually supported for individual "Others"

export const asId = (name) => `${name}_id`;

export const asPayload = (name) => ({
  entity: name,
});

export const asGuid = (...args) => `${args.join("/")}`;
