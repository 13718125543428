import NavRoutes from "core/app/util/NavRoutes";
import Entities from "core/shared/util/entities";
import createRoutePermission from "core/shared/util/navRoutesPermissionUtils";

const CampaignNavRoutesPermissions = {
  ...createRoutePermission(
    [
      { path: NavRoutes.campaigns.new, isCreateMode: true },
      NavRoutes.campaigns.editCampaign,
    ],
    Entities.CAMPAIGN
  ),
  ...createRoutePermission(
    [
      { path: NavRoutes.campaigns.createStrategy, isCreateMode: true },
      NavRoutes.campaigns.editStrategy,
    ],
    Entities.STRATEGY
  ),
};

export default CampaignNavRoutesPermissions;
