import React, { useState, useMemo, useContext } from "react";
import PropTypes from "prop-types";

const DocumentTitleContext = React.createContext({});

const { Provider } = DocumentTitleContext;

export function getPageTitle(partial = "") {
  return `MediaMath${partial ? ` - ${partial}` : ""}`;
}

/**
 * Hook that allows fetching and setting the document title
 */
export function useDocumentTitle() {
  const { title, setTitle } = useContext(DocumentTitleContext);
  return { title, setTitle };
}

/**
 * Provider for document title
 */
export const DocumentTitleProvider = ({ children }) => {
  const [partialTitle, setTitle] = useState();

  const title = useMemo(() => {
    if (partialTitle) {
      const final = getPageTitle(partialTitle);
      document.title = final;
      return final;
    }
    return null;
  }, [partialTitle]);
  const value = { title, setTitle };

  return <Provider value={value}>{children}</Provider>;
};

DocumentTitleProvider.propTypes = {
  children: PropTypes.node,
};
DocumentTitleProvider.defaultProps = {
  children: null,
};
