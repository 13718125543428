import CampaignConstants from "campaign/util/CampaignConstants";
import { isEmpty } from "lodash";

const { FAVORITE_CAMPAIGN, FILTER_STATUS_ARCHIVED } = CampaignConstants;

// Mapping fields for Campaign Pagination, Strategy Search, Filter fields for strategies pagination
export const mapFields = {
  "goalValue.value": "goal_value",
  "healthCheck.spendRatio": "spend_ratio",
  "maxBid.value": "max_bid",
  "minBid.value": "min_bid",
  "pacingAmount.value": "pacing_amount",
  [FAVORITE_CAMPAIGN]: "favorite_campaign",
  campaignArchived: "campaign_archived",
  campaignCreatedOn: "campaign_created_on",
  campaignDailyPacingAt: "campaign_daily_pacing_at",
  campaignDaysLeft: "campaign_days_left",
  campaignEndDate: "campaign_end_date",
  campaignFlightPacingAtPercent: "campaign_flight_pacing_at_percent",
  campaignFlightPacingRemaining: "campaign_flight_pacing_remaining",
  campaignFrequencyAmount: "campaign_frequency_type",
  campaignGoalType: "campaign_goal_type",
  campaignGoalValue: "campaign_goal_value",
  campaignId: "campaign_id",
  campaignImpressionsRemaining: "campaign_impressions_remaining",
  campaignImpressionsToPace: "campaign_impressions_to_pace",
  campaignInFlight: "campaign_in_flight",
  campaignName: "campaign_name",
  campaignStartDate: "campaign_start_date",
  campaignStatus: "campaign_status",
  campaignTotalBudget: "campaign_total_budget",
  campaignTotalImpressionBudget: "campaign_total_impression_budget",
  campaignUpdatedOn: "campaign_updated_on",
  clicks: "clicks",
  ctc: "ctc",
  ctr: "ctr",
  dynamicBudgetAllocationState: "campaign_dynamic_budget_allocation_type",
  endDate: "strategy_end_date",
  frequencyAmount: "frequency_amount",
  frequencyTypeFilter: "strategy_frequency_type",
  goalType: "goal_type",
  id: "strategy_id",
  impressions: "impressions",
  impressionPacingTypeFilter: "strategy_impression_pacing_type",
  impressionsYesterday: "impressions_yesterday",
  margin: "margin",
  marginPct: "margin_pct",
  measurabilityRate: "measurability_rate",
  pacingInterval: "pacing_interval",
  pacingType: "pacing_type",
  postClickConversions: "post_click_conversions",
  postClickRevenue: "post_click_revenue",
  postClickTotalSpendRoi: "post_click_total_spend_roi",
  postViewConversions: "post_view_conversions",
  postViewRevenue: "post_view_revenue",
  postViewTotalSpendRoi: "post_view_total_spend_roi",
  startDate: "strategy_start_date",
  status: "strategy_status",
  strategyArchived: "strategy_archived",
  strategyGoalType: "strategy_goal_type",
  strategyGoalValue: "strategy_goal_value",
  strategyInFlight: "strategy_in_flight",
  strategyName: "strategy_name",
  strategyPacingAmount: "strategy_pacing_amount",
  strategyPacingInterval: "strategy_pacing_interval",
  strategyPacingType: "strategy_pacing_type",
  totalConversions: "total_conversions",
  totalRevenue: "total_revenue",
  totalSpend: "total_spend",
  totalSpendCpa: "total_spend_cpa",
  totalSpendCpc: "total_spend_cpc",
  totalSpendCpm: "total_spend_cpm",
  totalSpendRoi: "total_spend_roi",
  totalSpendVcpm: "total_spend_vcpm",
  totalSpendYesterday: "total_spend_yesterday",
  videoCompleteRate: "video_complete_rate",
  videoCompleteRateImpressionBased: "video_complete_rate_impression_based",
  videoFirstQuartileRate: "video_first_quartile_rate",
  videoMidpointRate: "video_midpoint_rate",
  videoPlayRate: "video_play_rate",
  videoThirdQuartileRate: "video_third_quartile_rate",
  viewabilityRate: "viewability_rate",
  viewabilityRate_100Percent: "viewability_rate_100_percent",
};

// Mapping fields for Strategies Pagination for Sorting
// Creating this to avoid messing up the above mapFields const.
export const mapStrategyFields = {
  clicks: "clicks",
  ctc: "ctc",
  ctr: "ctr",
  daysLeft: "strategy_days_left",
  endDate: "strategy_end_date",
  flatRateCpm: "strategy_flat_rate_cpm",
  frequencyAmount: "strategy_frequency_type",
  goalActual: "strategy_goal_actual",
  goalActualFlightToDate: "strategy_goal_actual_ftd",
  goalActualLastSevenDays: "strategy_goal_actual_7_days",
  goalType: "strategy_goal_type",
  goalValue: "strategy_goal_value",
  id: "strategy_id",
  impressionPacingAmount: "strategy_impression_pacing_type",
  impressions: "impressions",
  impressionsYesterday: "impressions_yesterday",
  margin: "margin",
  marginPct: "margin_pct",
  maxBid: "strategy_max_bid",
  measurabilityRate: "measurability_rate",
  minBid: "strategy_min_bid",
  name: "strategy_name",
  pacingAmount: "strategy_pacing_amount",
  pacingInterval: "strategy_pacing_interval",
  pacingType: "strategy_pacing_type",
  postClickConversions: "post_click_conversions",
  postClickRevenue: "post_click_revenue",
  postClickTotalSpendRoi: "post_click_total_spend_roi",
  postViewConversions: "post_view_conversions",
  postViewRevenue: "post_view_revenue",
  postViewTotalSpendRoi: "post_view_total_spend_roi",
  potentialSpend14days: "potential_spend_14_days",
  potentialSpend14daysUncapped: "potential_spend_14_days_uncapped",
  potentialSpend30days: "potential_spend_30_days",
  potentialSpend30daysUncapped: "potential_spend_30_days_uncapped",
  potentialSpend7days: "potential_spend_7_days",
  potentialSpend7daysUncapped: "potential_spend_7_days_uncapped",
  potentialSpendToday: "potential_spend_today",
  potentialSpendTodayUncapped: "potential_spend_today_uncapped",
  potentialSpendYesterdayUncapped: "potential_spend_yesterday_uncapped",
  potentialSpendYesterday: "potential_spend_yesterday",
  remainingBudget: "strategy_flight_pacing_remaining",
  startDate: "strategy_start_date",
  status: "strategy_status",
  totalBudget: "strategy_budget",
  totalConversions: "total_conversions",
  totalRevenue: "total_revenue",
  totalSpend: "total_spend",
  totalSpendCpa: "total_spend_cpa",
  totalSpendCpc: "total_spend_cpc",
  totalSpendCpm: "total_spend_cpm",
  totalSpendRoi: "total_spend_roi",
  totalSpendVcpm: "total_spend_vcpm",
  totalSpendYesterday: "total_spend_yesterday",
  videoCompleteRate: "video_complete_rate",
  videoCompleteRateImpressionBased: "video_complete_rate_impression_based",
  videoFirstQuartileRate: "video_first_quartile_rate",
  videoMidpointRate: "video_midpoint_rate",
  videoPlayRate: "video_play_rate",
  videoThirdQuartileRate: "video_third_quartile_rate",
  viewabilityRate_100Percent: "viewability_rate_100_percent",
  viewabilityRate: "viewability_rate",
  winRate14Days: "win_rate_14_days",
  winRate30Days: "win_rate_30_days",
  winRate7Days: "win_rate_7_days",
  winRateToday: "win_rate_today",
  winRateYesterday: "win_rate_yesterday",
};

export const numericOperators = {
  0: ">",
  1: "<",
  2: "=",
  3: "=~",
};

export const parseFilters = ({ filter }) => {
  return Object.keys(filter)
    .map((key) => {
      const filterItem = filter[key];
      const { type, value, operator } = filterItem;
      switch (type) {
        case "dropdown":
          if (key === "campaignStatus" && value === "running") {
            return {
              op: numericOperators[2],
              field: mapFields.campaignInFlight,
              value: true,
            };
          }
          if (key === "status" && value === "running") {
            return {
              op: numericOperators[2],
              field: mapFields.strategyInFlight,
              value: true,
            };
          }
          if (key === "campaignStatus" && value === FILTER_STATUS_ARCHIVED) {
            return {
              op: numericOperators[2],
              field: mapFields.campaignArchived,
              value: true,
            };
          }
          if (key === "status" && value === FILTER_STATUS_ARCHIVED) {
            return {
              op: numericOperators[2],
              field: mapFields.strategyArchived,
              value: true,
            };
          }
          if (key === "campaignStatus" || key === "status") {
            return {
              op: numericOperators[2],
              field: mapFields[key],
              value: value === "active",
            };
          }
          return {
            op: numericOperators[2],
            field: mapFields[key],
            value,
          };
        case "string":
          return {
            op: numericOperators[3],
            field: mapFields[key],
            value,
          };
        case "date":
          if (value)
            return {
              op: numericOperators[operator],
              field: mapFields[key],
              value,
            };
          return {};
        case "numeric":
          return {
            op: numericOperators[operator],
            field: mapFields[key],
            value,
          };
        case FAVORITE_CAMPAIGN:
          return {
            op: numericOperators[2],
            field: mapFields[key],
            value,
          };
        default:
          return {};
      }
    })
    .filter((item) => {
      return !isEmpty(item);
    });
};

export const strategyOps = {
  0: "gt",
  1: "ls",
  2: "eq",
};

export const parseStrategyFilters = ({ filter }) => {
  return Object.keys(filter).map((key) => {
    const filterItem = filter[key];
    const { type, value, operator } = filterItem;
    switch (type) {
      case "dropdown":
        if (key === "status" && value === "running") {
          return {
            operator: strategyOps[2],
            property: mapFields[key],
            value: true,
          };
        }
        if (key === "status" && value === FILTER_STATUS_ARCHIVED) {
          return {
            operator: numericOperators[2],
            property: mapFields[key],
            value: true,
          };
        }
        if (key === "status") {
          return {
            operator: strategyOps[2],
            property: mapFields[key],
            value: value === "active",
          };
        }
        return {
          operator: strategyOps[2],
          property: mapFields[key],
          value,
        };
      case "string":
        return {
          operator: strategyOps[2],
          property: mapFields[key],
          value,
        };
      case "date":
        return {
          operator: strategyOps[operator],
          property: mapFields[key],
          value,
        };
      case "numeric":
        return {
          operator: strategyOps[operator],
          property: mapFields[key],
          value,
        };
      default:
        return {};
    }
  });
};
