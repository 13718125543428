import { Colors } from "@mediamath/design-system-eng";

const CampaignConstants = {
  ACME_ORG: 100048,
  ADMIN: "ADMIN",
  AGENCY: "AGENCY",
  ALLOW_ONLY_NUMBERS: /[^0-9]*/gi,
  ANY: "ANY",
  AUTHORIZED_DIRECT_ONLY: "AUTHORIZED_DIRECT_ONLY",
  AUTHORIZED_ONLY: "AUTHORIZED_ONLY",
  AUDIENCE_IN_BRAIN: "Audience in Brain",
  API_ERROR_FLAT_RATE_CPM_VALUE:
    "Flat Rate CPM must be a number between 0.01 and 9999999.99",
  API_ERROR_GOAL_VALUE_MAX: "goal_value must be at most 9_999_999.9999",
  API_ERROR_GOAL_VALUE_PERCENTAGE: "goal_value cannot be more than 100%",
  API_ERROR_MINIMUM_MAX_BID_VALUE:
    "Max Bid must be greater than or equal to 0.01.",
  API_ERROR_MIN_PACING_AMOUNT:
    "Pacing Amount must be greater than or equal to 0.01.",
  AUTO_TAG: "AUTO_TAG",
  CAMPAIGN_PACE: "campaign_pace",
  CAMPAIGN_PREFIX: "campaign",
  CAMPAIGN_UNDERPACE: "campaign_underpace",
  CAMPAIGNS_BULK_UPDATE: "bulk_update",
  CAMPAIGNS_PREFIX_KEY: "campaigns-range",
  CHANGELOG_DATEFORMAT: "LL-dd-yyyy",
  CHANGELOG_DATE_TIME_FORMAT: "yyyy-MM-dd' 'HH:mm:ss",
  CHANGELOG_INDEX_TAB_CAMPAIGNS: 0,
  CHANGELOG_INDEX_TAB_STRATEGIES: 1,
  CURRENCY_CODE_USD: "USD",
  CURRENCY_REGEX: /^\d*(\.\d{0,2})?$/, // "only numbers, must have less than 2 digits after decimal"
  CUSTOM_VIEW_NAMESPACE: "t1next.campaign.campaigns.columnChooser.viewList",
  CUSTOMIZE_COLUMNS_TABLE_VIEW_VALUE: "customizeColumns",
  DATA_TYPE_CURRENCY: "currency",
  DATA_TYPE_PERCENT: "percent",
  DATE_AND_FULL_TIME_FORMAT: "yyyy-MM-dd'T'HH:mm:ss",
  DATE_MULTIPLE_VALUE: "(Multiple)",
  DATE_START_OF_DAY: "day",
  DATE_START_OF_HOUR: "hour",
  DATE_START_OF_WEEK: "week",
  DAY_OF_WEEK_MONTH_DAY_YEAR: "ccc LLL dd yyyy",
  DBA_ALWAYS: "always",
  DBA_AMOUNT_0: 0,
  DBA_CHANGE_LOG_TYPE: { DBA_AUTO: "DBA-Auto", DBA_MANUAL: "DBA-Manual" },
  DBA_COLUMN_KEY: "dynamicBudgetAllocationState",
  DBA_CUSTOM: "custom",
  DBA_ITEM_TYPE: {
    CURRENT: "current",
    CUSTOM: "custom",
    RECOMMENDED: "recommended",
  },
  DBA_NEVER: "never",
  DBA_STATES: {
    CAMPAIGNS_DBA_DISABLED: 3,
    CAMPAIGNS_DBA_INACTIVE: 2,
    CAMPAIGNS_DBA_INITIAL_STATE: 4,
    CAMPAIGNS_DBA_RECOMENDATIONS_APPLIED: 1,
    CAMPAIGNS_DBA_WITH_RECOMENDATIONS: 0,
  },
  DEFAULT_DATE_FORMAT: "yyyy-LL-dd",
  DEFAULT_METRIC: "total_spend",
  DEFAULT_METRIC_2: "impressions",
  DETERMINISTIC_FIRST: "DETERMINISTIC_FIRST",
  DETERMINISTIC_ONLY: "DETERMINISTIC_ONLY",
  EDIT_MARGIN_HEADER_DATE_FORMAT: "MM/dd/yyyy",
  ENTITY_NAME_CAMPAIGNS: "campaigns",
  ENTITY_NAME_STRATEGIES: "strategies",
  ERROR_MESSAGE_FAIL: "fail",
  EVERY: "every",
  FAVORITE_CAMPAIGN: "favorite_campaign",
  FAVORITE_CAMPAIGN_ADD: "add",
  FAVORITE_CAMPAIGN_ADD_WARNING: "add_warning",
  FAVORITE_CAMPAIGN_NAMESPACE_BY_ORG: "ui.organization.ORG_ID.favorites",
  FAVORITE_CAMPAIGN_ORG_WILDCARD: "ORG_ID",
  FAVORITE_CAMPAIGN_REMOVE: "remove",
  FAVORITE_CAMPAIGN_REMOVE_WARNING: "remove_warning",
  FILTER_STATUS_ALL: "all",
  FILTER_STATUS_RUNNING: "running",
  FILTER_STATUS_ACTIVE: "active",
  FILTER_STATUS_ARCHIVED: "archived",
  FILTER_STATUS_INACTIVE: "inactive",
  FORM_DATE_PICKER_NAME: "FormDatePicker",
  FREQUENCY_CAP_AMOUNT_0: 0,
  FREQUENCY_CAP_ASAP: "asap",
  FREQUENCY_CAP_EVEN: "even",
  FREQUENCY_CAP_GOAL_REACH: "reach",
  FREQUENCY_CAP_GOAL_SPEND: "spend",
  FREQUENCY_CAP_INTERVAL_HOUR: "hour",
  FREQUENCY_CAP_INTERVAL_NOT_APPLICABLE: "not-applicable",
  FREQUENCY_CAP_MAX_AMOUNT: 9999999,
  FREQUENCY_CAP_MIN_AMOUNT: 1,
  FREQUENCY_CAP_MULTIPLE_AMOUNTS: "(Multiple Amounts)",
  FREQUENCY_CAP_MULTIPLE_INTERVALS: "(Multiple Intervals)",
  FREQUENCY_CAP_MULTIPLE_TYPES: "(Multiple Types)",
  FREQUENCY_CAP_NO_CAP: "noCap",
  FREQUENCY_CAP_NO_LIMIT: "no-limit",
  FREQUENCY_CAP_OFF: "off",
  FREQUENCY_CAP_ON: "on",
  FREQUENCY_CAP_SORTING_COLUMN_KEYS: "frequencyCapSorting",
  FREQUENCY_CAP_T1_OPTIMIZED: "t1Optimized",
  GOAL_TYPE_ROI: "roi",
  GROUP_ID_UNMODIFIABLE_COLUMNS_CC: "UM",
  HEALTH_CHECK_STATUS_ERROR: "error",
  HEALTH_CHECK_STATUS_ISSUE: "issue",
  HEALTH_CHECK_STATUS_OK: "ok",
  HOUR_AND_DATE_FORMAT: "yyyy-LL-dd HH",
  HOUR_AND_DATE_ZERO_MINUTES_FORMAT: "yyyy-LL-dd HH:00",
  HOUR_MINUTE_SECOND_DATEFORMAT: "HH:mm:ss",
  IMPRESSION_COLUMNS: "impressionColumns",
  IMPRESSIONS_TABLE_VIEW_VALUE: "byImpressions",
  IMPRESSIONS_VIEW: "impressions",
  IMPRESSION_PACING_MAX_VALUE: 99000000000,
  INDEXED_FILTERS_PREFIX: "filters",
  INHERITED: "INHERITED",
  INLINE_READ_ONLY_FORMAT: "M-dd-yyyy",
  INTERNAL: "INTERNAL",
  IS_NEW_T1_WORKFLOW: "workflow",
  ISSUE_TYPE: "issueType",
  LOADING_INFORMATION: "--",
  LOCAL_STORAGE_CHECKED_VIEW_COLUMNS: "checked-view-columns", // specfic to checked columns
  LOCAL_STORAGE_KEY_COLUMNS_ORDERING: "columns-sort-saved",
  LOCAL_STORAGE_KEY_COLUMNS_SORT: "columns-sort",
  LOCAL_STORAGE_KEY_COLUMNS_SORT_TEXT: "columns-sort-text",
  LOCAL_STORAGE_KEY_COLUMNS_SORT_VALUE: "columns-sort-value",
  LOCAL_STORAGE_KEY_COLUMNS_UNCHECKED: "columns-unchecked",
  LOCAL_STORAGE_KEY_NO_DATA_INDICATOR: "campaigns-chart-showNoDataIndicator",
  LOCAL_STORAGE_TABLE_VIEW_VALUE: "table-view-value", // specfic to table view selected
  MANAGER: "MANAGER",
  MANAGED: "MANAGED",
  MAX_SIZE: 100,
  MESSAGE_SUBTYPE_BODY: "message",
  MESSAGE_SUBTYPE_HEADER: "headerMsg",
  MESSAGE_SUBTYPE_SINGLE: "single",
  MESSAGE_TYPE_CHART_ERROR: "error",
  MESSAGE_TYPE_NO_DATA: "noData",
  MILLISECONDS_IN_A_DAY: 1000 * 60 * 60 * 24,
  MULTIPLE_CAMPAIGN: "multiple",
  MINIMUM_VALUE_BEFORE_MILLION_TO_BE_ROUNDED: 999999.5,
  NONE: "NONE",
  NOT_APPLICABLE_FIELD: "N/A",
  NOT_RUNNING: "NOT RUNNING",
  NULL: "Null",
  NUMBER_REGEX: /([^0][1-9]*[0-9]*)/,
  OFF: "OFF",
  PAGE_SIZE: 100,
  PERFORMANCE_COLUMNS: "performanceColumns",
  PERFORMANCE_DATE_LIMIT: { DAY: 1, MONTH: 1, YEAR: 2013 },
  PERFORMANCE_TABLE_VIEW_VALUE: "byPerformance",
  PERFORMANCE_TOTALSPEND: "performance.totalSpend",
  PERFORMANCE_VIEW: "performance",
  REPORT_TYPE_HOUR: "by_hour",
  REPORT_TYPE_PERFORMANCE: "performance",
  REPORT_TYPE_WINLOSS: "win_loss",
  REPORTER: "REPORTER",
  REQUEST_STATUS_OK: 200,
  RESPONSE_BAD_REQUEST_ERROR: 400,
  RESPONSE_ERROR: "error",
  RESPONSE_INTERNAL_SERVER_ERROR: 500,
  RESPONSE_MULTI_STATUS: "multi-status",
  RESPONSE_PARTIAL: "partial success",
  RESPONSE_SUCCESS: "success",
  RESPONSE_SUCCESS_USER_PREFERENCES: "ok",
  SELF: "SELF",
  SBA_AUTO_ALLOCATION: "SBA_AUTO_ALLOCATION",
  SBA_RECOMMENDATIONS_ONLY: "SBA_RECOMMENDATIONS_ONLY",
  SEARCH_DATE_FORMAT: "yyyy-MM-dd",
  SPEND_COLUMNS: "spendColumns",
  SPEND_ISSUE: "spend_issue",
  SPEND_TABLE_VIEW: "bySpend",
  SPEND_VIEW: "spend",
  STRATEGIES_BULK_UPDATE: "bulk_update_strategies",
  STRATEGIES_URL: "/strategies",
  STRATEGY_ADD_EXCESS_BUDGET: "strategy_add_excess_budget",
  STRATEGY_CAMPAIGN_ROW_COLOR: Colors.ColorGrey600,
  STRATEGY_FLAT_RATE_CPM_MIN_AMOUNT: 0.01,
  STRATEGY_FLAT_RATE_CPM_MAX_AMOUNT: 9999999.99,
  STRATEGY_GROUP_PREFIX: "strategy-group",
  STRATEGY_MAX_BID_MAX_AMOUNT: 9999999.99,
  STRATEGY_MAX_BID_MIN_AMOUNT: 0.01,
  STRATEGY_MIN_BID_MAX_AMOUNT: 9999999.99,
  STRATEGY_MIN_BID_MIN_AMOUNT: 0,
  STRATEGY_MONETARY_PACING_MAX_AMOUNT: 9999999.99,
  STRATEGY_MONETARY_PACING_MIN_AMOUNT: 0.01,
  STRATEGY_PERFORMANCE_ALIGNMENT: "strategy_performance_alignment",
  STRATEGY_PAGING_PREFIX: "strategy-paging",
  STRATEGY_PREFIX: "strategy",
  STRATEGY_PREFIX_KEY: "strategy-range",
  STRATEGY_REMOVE_EXCESS_BUDGET: "strategy_remove_excess_budget",
  STRATEGY_VIEW_GROUP_DEFAULT_SORT_KEY:
    "performance.totalSpendYesterday.yesterday.value",
  STRATEGY_VIEW_GROUP_PREFIX: "strategy-view-group",
  TABLE_ITEMS_BY_PAGE: 100,
  TIME_BOX_DEBOUNCE: 1500,
  TIME_ROLLUP_DAY: "by_day",
  TIME_ROLLUP_HOUR: "by_hour",
  TIME_ROLLUP_WEEK: "by_week",
  TIME_WINDOW_CAMPAIGN_TO_DATE: "campaign_to_date",
  TIME_WINDOW_CURRENT_DAY: "current_day",
  TIME_WINDOW_CUSTOM: "custom",
  TIME_WINDOW_FLIGHT_TO_DATE: "flight_to_date",
  TIME_WINDOW_LAST_24HRS: "last_24_hours_inclusive",
  TIME_WINDOW_LAST_48HRS: "last_48_hours_inclusive",
  TIME_WINDOW_LAST_168HRS: "last_168_hours_inclusive",
  TIME_WINDOW_LAST7: "last_7_days",
  TIME_WINDOW_LAST14: "last_14_days",
  TIME_WINDOW_LAST30: "last_30_days",
  // TODO: Refactor TIME_WINDOW_PERFORMANCE_GOAL array to make it consistent with BFF
  TIME_WINDOW_PERFORMANCE_GOAL:
    '["yesterday", "today", "campaignToDate", "last14Days", "last30Days", "flightToDate", "last7Days"]',
  TIME_WINDOW_TODAY: "today",
  TIME_WINDOW_YESTERDAY: "yesterday",
  TIME_WINDOWS: {
    campaignToDate: "campaignToDate",
    custom: "custom",
    flightToDate: "flightToDate",
    last7Days: "last7Days",
    last14Days: "last14Days",
    last30Days: "last30Days",
    today: "currentDay",
    yesterday: "yesterday",
  },
  TOTAL_BUDGET_CURRENCY_CODE_NAME: "totalBudget.currencyCode",
  TOTAL_BUDGET_FIELD_NAME: "totalBudget",
  TOTAL_IMPRESSION_BUDGET_FIELD_NAME: "totalImpressionBudget",
  TOTAL_IMPRESSION_BUDGET_MAX_VALUE: 99000000000,
  VARIABLE: "variable",
  VIEWABILITY_TYPE_AUTO_TAG: "AUTO_TAG",
  VIEWABILITY_TYPE_BYOT: "BYOV",
  WEEK_DATE_FORMAT: "W",

  //--------------------------------------------------------------------------
  // THINKING ABOUT ADDING A NEW CONSTANT?
  // Add it in alphabetical order to prevent duplication.
  //--------------------------------------------------------------------------
};

export default CampaignConstants;
