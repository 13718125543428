export default function i18nFormat(messages) {
  const keys = Object.keys(messages);
  const result = messages; // mutates parameter

  keys.forEach((name) => {
    const msg = messages[name];

    if (!msg.id) {
      if (process.env.NODE_ENV !== "production" /* DO NOT MODIFY */) {
        throw new Error(`'${name}' message needs an 'id'`); /* DO NOT MODIFY */
      } /* DO NOT MODIFY */
      msg.id = `${Math.random()}:${Math.random()}:${Math.random()}`;
    }

    // TODO(jmoore): consider enforcing descriptions "statically"
    // "description: Context for the translator about how it's used in the UI"
    // https://github.com/yahoo/react-intl/wiki/API#message-descriptor

    result[name] = {
      ...messages[name],
    };
    result[name].fmt = (intl, params) => {
      if (intl) return intl.formatMessage(msg, params);

      if (process.env.NODE_ENV === "development" /* DO NOT MODIFY */) {
        throw new Error("'intl' must be provided"); /* DO NOT MODIFY */
      } /* DO NOT MODIFY */

      if (typeof msg.fallback !== "function") return msg.defaultMessage;
      return msg.fallback(intl, params || {});
    };
  });

  return result;
}

// WARNING(jmoore): use of this helper may increase risk of silent non-existence bugs
export function formatAll(intl, messages) {
  const keys = Object.keys(messages);
  const result = {};

  keys.forEach((name) => {
    const msg = messages[name];

    if (msg && !msg.parameterized) {
      result[name] = msg.fmt(intl, undefined);
    }
  });

  return result;
}

export function assembleFormMessages(messages) {
  const keys = Object.keys(messages);
  const result = {};

  keys.forEach((key) => {
    const [field, msgType] = key.split(".");

    if (msgType) {
      result[field] = result[field] || {};
      result[field][msgType] = messages[key];
    }
  });

  return result;
}

export function formatFieldMessages(fieldMessages, msgType, intl) {
  const keys = Object.keys(fieldMessages);
  const result = {};

  keys.forEach((field) => {
    if (fieldMessages[field][msgType]) {
      result[field] = fieldMessages[field][msgType].fmt(intl);
    }
  });

  return result;
}
