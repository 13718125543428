import { getIdParameters } from "core/shared/util/accountUtils";
import { useLocation } from "core/shared/hooks/useStableRouter";
import { useMemo } from "react";

const useUrlEntities = () => {
  const location = useLocation();
  return useMemo(() => getIdParameters(location), [location]);
};

export default useUrlEntities;
