import AppFeatureFlags from "core/app/util/AppFeatureFlags";
import NavRoutes from "core/app/util/NavRoutes";
import msgs from "core/shared/intl/SectionNameMessages";

const SideNavConfig = [
  {
    flag: AppFeatureFlags.CAMPAIGNS_MODULE,
    intlKey: msgs.campaigns,
    key: "campaignsnewt1",
    link: NavRoutes.campaigns.default,
    matches: [
      NavRoutes.campaigns.default,
      NavRoutes.campaigns.strategies,
      NavRoutes.campaigns.hybridCreateCampaign,
      NavRoutes.campaigns.hybridCreateStrategy,
    ],
    subMenu: [
      {
        flag: AppFeatureFlags.CAMPAIGNS_MODULE,
        intlKey: msgs.manage,
        key: "managenewt",
        link: NavRoutes.campaigns.default,
        matches: [
          NavRoutes.campaigns.default,
          NavRoutes.campaigns.strategies,
          NavRoutes.campaigns.hybridCreateCampaign,
          NavRoutes.campaigns.hybridCreateStrategy,
        ],
      },
      {
        intlKey: msgs.bulkManage,
        isExternal: true,
        key: "bulk",
        link: "https://bulkmanager.mediamath.com/",
        matches: ["https://bulkmanager.mediamath.com/"],
      },
    ],
  },
  {
    flag: AppFeatureFlags.CAMPAIGNS_MODULE_COMPASS,
    intlKey: msgs.campaigns,
    key: "campaigns",
    legacy: true,
    link: NavRoutes.compass.campaigns,
    matches: [NavRoutes.compass.campaigns],
    subMenu: [
      {
        flag: AppFeatureFlags.CAMPAIGNS_MODULE_COMPASS,
        intlKey: msgs.manage,
        key: "manage",
        legacy: true,
        link: NavRoutes.compass.campaigns,
        matches: [NavRoutes.compass.campaigns],
      },
      {
        intlKey: msgs.bulkManage,
        isExternal: true,
        key: "bulk",
        link: "https://bulkmanager.mediamath.com/",
        matches: ["https://bulkmanager.mediamath.com/"],
      },
    ],
  },
  {
    flag: AppFeatureFlags.REPORTS,
    intlKey: msgs.reports,
    key: "reports",
    link: NavRoutes.compass.reports,
    matches: [NavRoutes.compass.reports, NavRoutes.reports.hybridReports],
  },
  {
    flag: AppFeatureFlags.REPORTS_MODULE_POC,
    intlKey: msgs.reports,
    key: "reportspoc",
    link: NavRoutes.reports.poc,
    matches: [NavRoutes.reports.poc],
    poc: true,
  },
  {
    intlKey: msgs.inventory,
    isSmartLink: true,
    key: "inventorynewt",
    link: NavRoutes.inventory.deals,
    matches: [NavRoutes.inventory.deals],
  },
  {
    flag: AppFeatureFlags.MEDIA_MODULE_COMPASS,
    intlKey: msgs.inventory,
    key: "inventory",
    legacy: true,
    link: NavRoutes.compass.media,
    matches: [NavRoutes.compass.media],
  },
  {
    beta: true,
    flag: AppFeatureFlags.AUDIENCE_MODULE,
    intlKey: msgs.audiences,
    isSmartLink: true,
    key: "audiencesnewt",
    link: NavRoutes.audiences.firstpartydata,
    matches: [NavRoutes.audiences.firstpartydata],
  },
  {
    flag: AppFeatureFlags.AUDIENCE_MODULE_COMPASS,
    intlKey: msgs.audiences,
    key: "audiences",
    link: NavRoutes.compass.audiences,
    matches: [NavRoutes.compass.audiences],
  },
  {
    flag: AppFeatureFlags.CREATIVES_MODULE,
    intlKey: msgs.creativesVideo,
    key: "creativesnewt",
    link: NavRoutes.creatives.default,
    matches: [NavRoutes.creatives.default],
  },
  {
    flag: AppFeatureFlags.CREATIVES_MODULE_COMPASS,
    intlKey: msgs.creatives,
    key: "creatives",
    legacy: true,
    link: NavRoutes.compass.creatives,
    matches: [NavRoutes.compass.creatives],
  },
  {
    beta: true,
    flag: AppFeatureFlags.PLANS_MODULE,
    intlKey: msgs.plans,
    key: "plansnewt",
    link: NavRoutes.plans.default,
    matches: [NavRoutes.plans.default],
  },
  {
    beta: true,
    flag: AppFeatureFlags.PLANS_MODULE_V2,
    intlKey: msgs.plansV2,
    key: "plansnewt1v2",
    link: NavRoutes.strategy_plans.default,
    matches: [NavRoutes.strategy_plans.default],
  },
  {
    flag: AppFeatureFlags.ADMIN_MODULE,
    intlKey: msgs.admin,
    isSmartLink: true,
    key: "adminnewt",
    link: NavRoutes.admin.default,
    matches: [NavRoutes.admin.default],
  },
  {
    flag: AppFeatureFlags.ADMIN_MODULE_COMPASS,
    intlKey: msgs.admin,
    isSmartLink: false,
    key: "admin",
    legacy: true,
    link: NavRoutes.compass.admin,
    matches: [NavRoutes.compass.admin],
  },
  {
    flag: null,
    intlKey: msgs.appStore,
    key: "appstore",
    link: NavRoutes.compass.appstore,
    matches: [NavRoutes.compass.appstore],
  },
  /**
   * TODO(AK): Here are examples of how nested nav items will appear.
      When we've got nested sections, they should be implemented as follows:
  {
    key: "nested",
    link: "/nested",
    flag: null,
    subMenu: [
      {
        key: "first-child",
        link: "/nested/first-child",
        flag: null
      },
      {
        key: "second-child",
        link: "/nested/second-child",
        flag: null
      },
      {
        key: "third-child",
        link: "/nested/third-child",
        flag: null
      }
    ]
  },
  TODO(AK): Placeholder for "Help" section as per UX design
  {
    key: "help",
    link: "/help",
    flag: null
  }
  TODO(JD): example if you didin't want the navigation to use SmarlLink
  {
    key: "help",
    link: "/help",
    flag: null,
    isSmartLink: false
  }
  */
];

export default SideNavConfig;
