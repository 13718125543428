const InventoryConstants = {
  CENTURY_END_YEAR_VALUE: 2999,
  CUSTOM_TIME_WINDOW_TEXT: "custom",
  DEFAULT_DATE_FORMAT: "yyyy-LL-dd",
  DEFAULT_SPM_DISPLAY_LIMIT: 100,
  DEFAULT_SPM_ENTITY_LIMIT: 100,
  DEFAULT_SPM_PATH_LIMIT: 200,
  DEFAULT_SPOT_GRID_PAGE_OFFSET: 0,
  DEFAULT_SPOT_PAGE_SIZE: 100,
  DEFAULT_TEXT_FOR_NULL_VALUE: "NULL",
  ENTITY_SEARCH_MORE_RESULT_LIMIT: 20,
  ENTITY_SELECTOR_API_PAGE_LIMIT: 20,
  ENTITY_SELECTOR_CACHE_KEY: "entitySelectorSavedPrefs",
  ENTITY_SELECTOR_DEFAULT_VALUE: [],
  ENTITY_SELECTOR_INITIAL_PIN: 0,
  ENTITY_SELECTOR_INITIAL_UNCHECKED_COLUMNS: [],
  ENTITY_SELECTOR_ORG_DISPLAY_TEXT: "Organization",
  ENTITY_SELECTOR_PREF_KEY: "entitySelector",
  ENTITY_SELECTOR_SORT_KEY: "name",
  ENTITY_TYPE_ADVERTISER: "advertiser",
  ENTITY_TYPE_AGENCY: "agency",
  ENTITY_TYPE_CAMPAIGN: "campaign",
  ENTITY_TYPE_STRATEGY: "strategy",
  FIELD_TYPE_SEARCH_DROPDOWN: "searchDropDown",
  FIELD_TYPE_TEXT_AREA: "textArea",
  INVENTORY_NUMERIC_OPERATOR: { 0: ">", 1: "<", 2: "=", 3: "=~" },
  PO_GRAPH_FILTERS_KEY: "inventory-path-optimizer-graph-filters",
  REGEX_IF_STRING_IS_INTEGER: "^[0-9]+$",
  SPM_ACTION_BAR_SOURCE_KEY: "spmList",
  SPM_COLUMN_INITIAL_PIN: 0,
  SPM_ENTITY_COLUMN_CACHE_KEY: "entityColumnsCache",
  SPM_PATH_COLUMN_CACHE_KEY: "pathColumnsCache",
  SPM_TYPE_ENTITY: "entity",
  SPM_TYPE_PATH: "path",
  SPOT_ACTION_BAR_SOURCE_KEY: "pathOptimizer",
  SPOT_ADVERTISER_ID_PARAM_TEXT: "advertiserId",
  SPOT_AGENCY_ID_PARAM_TEXT: "agencyId",
  SPOT_APP_NAME_KEY: "appName",
  SPOT_CACHE_ENTITY_TYPE: "pathOptimizer",
  SPOT_CACHE_KEY: "spotGrid",
  SPOT_CACHE_MAX_AGE: 900000,
  SPOT_CAMPAIGN_ID_PARAM_TEXT: "campaignId",
  SPOT_DOMAIN_APP_COLUMN_KEY: "domainApp",
  SPOT_GRID_DEFAULT_SORT_KEY: "totalSpend",
  SPOT_GRID_FILTER_CACHE_KEY: "filters.spotPresenter.",
  SPOT_GRID_FILTER_COLUMN_CACHE_KEY: "spotPresenter.",
  SPOT_GRID_INITIAL_PIN: 0,
  SPOT_GRID_INITIAL_UNCHECKED_COLUMNS: [],
  SPOT_GRID_PREF_KEY: "spotGridColumns",
  SPOT_OPTIMIZER_ROUTE_TEXT: "pathoptimizer",
  SPOT_ORG_ID_PARAM_TEXT: "organizationId",
  SPOT_ROW_LIMIT_CACHE_KEY: "spotPresenterRowLimit",
  SPOT_SHOW_LIMIT_FIFTY: 50,
  SPOT_SHOW_LIMIT_HUNDRED: 100,
  SPOT_SHOW_LIMIT_TWO_HUNDRED: 200,
  SPOT_SITE_DOMAIN_KEY: "siteDomain",
  SPOT_SPENDING_TYPE_APP: "app",
  SPOT_SPENDING_TYPE_CACHE_KEY: "spotPresenterSpendingType",
  SPOT_SPENDING_TYPE_DOMAIN: "domain",
  SPOT_SPM_ROUTE_TEXT: "spm",
  SPOT_STRATEGY_ID_PARAM_TEXT: "strategyId",
  SPOT_TABLE_CHART_VIEW_PARAM: "showTable",
  SPOT_TABLE_SPENDING_PARAM_TEXT: "spending",
  SPOT_TABLE_TOP_ROWS_PARAM_TEXT: "topRows",
};

export default InventoryConstants;
