import React, { useEffect } from "react";
import { Route as ReactRouterRoute } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useDocumentTitle } from "core/app/hooks/useDocumentTitle";
import PermissionRouteWrapper from "core/shared/presenter/PermissionRouteWrapper";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Colors, Loader } from "@mediamath/design-system-eng";
import messages from "core/shared/intl/NoMatchMessages";
import styled from "@emotion/styled";

const StAuthRedirect = styled("div")`
  background: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StRedirectModal = styled("div")`
  background: ${Colors.ColorWhite};
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2),
    1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 14px;
  display: flex;
  align-items: center;
`;

const StInlineLoader = styled("div")`
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const AuthRoute = withAuthenticationRequired(ReactRouterRoute, {
  onRedirecting: () => (
    <StAuthRedirect>
      <StRedirectModal>
        <StInlineLoader>
          <Loader active size="tiny" />
        </StInlineLoader>
        <FormattedMessage {...messages.login} />
      </StRedirectModal>
    </StAuthRedirect>
  ),
});

/**
 * Wrapper component for react router route -- handles setting document title
 */
const Route = ({
  title: propTitle,
  component,
  children,
  render,
  auth,
  flag,
  followCampaignId,
  ...rest
}) => {
  const intl = useIntl();
  const { setTitle } = useDocumentTitle();
  const RouteComponent = auth ? AuthRoute : ReactRouterRoute;

  useEffect(() => {
    const title = propTitle ? `${intl.formatMessage(propTitle)}` : null;
    if (title) {
      setTitle(title);
    }
  }, [intl, propTitle, setTitle]);

  return (
    <RouteComponent
      {...rest}
      render={(routeProps) => (
        <PermissionRouteWrapper
          followCampaignId={followCampaignId}
          component={component}
          render={render}
          routeProps={routeProps}
          auth={auth}
          flag={flag}
        >
          {children}
        </PermissionRouteWrapper>
      )}
    />
  );
};

Route.propTypes = {
  /** Document title - message object for reactIntl formatMessage */
  title: PropTypes.shape({}),
  /**  should this route require authentication */
  auth: PropTypes.bool,
  flag: PropTypes.string,
  followCampaignId: PropTypes.bool,
  ...ReactRouterRoute.propTypes,
};
Route.defaultProps = {
  title: undefined,
  auth: false,
  flag: undefined,
  followCampaignId: true,
  ...ReactRouterRoute.defaultProps,
};

export default Route;
