import { defineMessages } from "react-intl";

const RootMessages = defineMessages({
  error: {
    id: "root.error",
    defaultMessage: "Oops... Something went wrong.",
  },
  try: {
    id: "root.try",
    defaultMessage: "Try reloading the app to continue.",
  },
  accessDenied: {
    id: "root.permission",
    defaultMessage: "Permission Denied",
  },
  permissionError: {
    id: "root.contactadmin",
    defaultMessage: "Please contact an administrator to be granted access.",
  },
  loading: {
    id: "root.loading",
    defaultMessage: "Loading...",
  },
  noSearchResults: {
    id: "root.noSearchResults",
    defaultMessage: "No results for your search",
  },
  AdvertiserDropDownAll: {
    id: `root.advertiserdropdownall`,
    defaultMessage: `All Advertisers`,
  },
});

export default RootMessages;
