import useIds from "core/shared/hooks/useIds";
import useStreamList from "core/shared/hooks/useStreamList";
import getConnection from "core/shared/util/marvellConnection";
import axios from "axios";
import { getAgenciesList } from "core/shared/util/marvellStreamingConnection";
import { getFilterBy } from "core/shared/util/filters";

const useAgenciesList = ({ filters, organizationId }) => {
  const connection = getConnection();
  const filterBy = getFilterBy(filters);
  const { isLoading, isStreaming, data, shouldUpdate } = useStreamList({
    streamFactory: getAgenciesList,
    dataEvent: "agencies",
    params: { filterBy, organizationId },
    requiredParams: { organizationId: true },
  });

  const changeStatus = async ({ agencyId, newStatus, version }) => {
    const abortController = new AbortController();
    try {
      await connection.writeEntity({
        entity: { status: newStatus, version },
        entityType: "agencies",
        entityId: agencyId,
        signal: abortController?.signal,
      });
      shouldUpdate();
    } catch (e) {
      if (axios.isCancel(e)) {
        // continue regardless of errors
      }
    }
  };

  return {
    isLoading,
    isStreaming,
    agencies: data,
    changeStatus,
    // TODO: ids don't match server order
    agenciesIds: useIds(data),
  };
};

export default useAgenciesList;
