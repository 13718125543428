import { useState, useEffect, useCallback } from "react";
import { get, keyBy } from "lodash";
import useStableObj from "core/shared/hooks/useStableObj";

const defaultInitial = {};

const checkRequired = (params, requiredParams) => {
  if (typeof requiredParams === "function") {
    return requiredParams(params);
  }
  if (typeof requiredParams === "object") {
    return Object.keys(requiredParams)
      .map((key) => !!params[key])
      .reduce((prev, curr) => prev && curr, true);
  }
  return true;
};

const useStreamList = ({
  streamFactory,
  dataEvent,
  dataPath = "",
  initialValue = defaultInitial,
  params,
  requiredParams,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [data, setData] = useState(initialValue);
  const [updateCounter, setUpdateCounter] = useState(0);

  const stableParams = useStableObj(params);
  const stableReqParams = useStableObj(requiredParams);

  const shouldUpdate = useCallback(() => {
    setUpdateCounter((count) => count + 1);
  }, [setUpdateCounter]);

  const updateItem = useCallback(
    (datum) => {
      const isArray = Array.isArray(datum);
      const storeArray = Array.isArray(initialValue);

      if (storeArray && isArray) {
        // array into array
        setData((old) => [...old, ...datum]);
      }
      if (storeArray && !isArray) {
        // assume we are getting id keyed objects here so flatten
        setData((old) => [...old, ...Object.entries(datum)]);
      }
      if (!storeArray && isArray) {
        // array to object (assume obj in array contain id prop)
        const objDatum = keyBy(datum, "id");
        setData((old) => ({ ...old, ...objDatum }));
      }
      if (!storeArray && !isArray) {
        // obj into obj
        setData((old) => ({ ...old, ...datum }));
      }
    },
    [setData, initialValue]
  );

  useEffect(() => {
    let es;
    let mounted = true;

    const updateData = (msg) => {
      setIsLoading(false);
      const response = JSON.parse(msg.data);
      const datum = dataPath ? get(response, dataPath) : response;
      updateItem(datum);
    };

    const handleDone = () => {
      if (mounted) {
        setIsStreaming(false);
        setIsLoading(false);
      }
    };

    if (checkRequired(stableParams, stableReqParams)) {
      (async () => {
        es = await streamFactory({
          ...stableParams,
          callbacks: {
            [dataEvent]: updateData,
            done: handleDone,
          },
        });
        if (mounted) {
          setData(initialValue);
          setIsStreaming(true);
          setIsLoading(true);
        }
      })();
    }

    return () => {
      mounted = false;
      if (es) es.close();
    };
  }, [
    dataEvent,
    dataPath,
    stableParams,
    stableReqParams,
    streamFactory,
    initialValue,
    updateItem,
    updateCounter,
  ]);

  return {
    isLoading,
    isStreaming,
    data,
    updateItem,
    shouldUpdate,
  };
};

export default useStreamList;
