import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";

const TabSectionMessages = defineMessages({
  vendors: {
    id: "admin.vendors.tab.vendors",
    defaultMessage: "Vendors",
  },
  users: {
    id: "admin.vendors.tab.users",
    defaultMessage: "Users",
  },
  organizations: {
    id: "admin.vendors.tab.organizations",
    defaultMessage: "Organizations",
  },
  ecosystemCostsTool: {
    id: "admin.vendors.tab.ecosystemCostsTool",
    defaultMessage: "Ecosystem Costs",
  },
  siteLists: {
    id: "admin.tab.siteLists",
    defaultMessage: "Site Lists",
  },
  admin: {
    id: "admin.tabs.title",
    defaultMessage: "Admin",
  },
  enterpriseControls: {
    id: "admin.tab.enterpriseControls",
    defaultMessage: "Enterprise Controls",
  },
  IdError: {
    id: "iderror",
    defaultMessage: "Error retrieving the requested ID",
  },
});

i18nFormat(TabSectionMessages);

export default TabSectionMessages;
