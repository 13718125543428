import EventSourcePolyfill from "eventsource";
import qs from "query-string";
import addPolyfills from "core/shared/polyfills/index";

export default class StreamSource {
  constructor({
    url,
    withCredentials = "include",
    callbacks,
    EventSource = EventSourcePolyfill,
    headers,
    params,
  }) {
    addPolyfills();
    const query = qs.stringify(params);
    this.url = query ? `${url}?${query}` : url;
    this.withCredentials = withCredentials;
    this.callbacks = {
      complete: this.close.bind(this),
      error: (e) => {
        // eslint-disable-next-line no-console
        console.error("Stream Error:", this.url, e);
        this.close();
      },
      ...callbacks,
    };
    this.es = new EventSource(this.url, { withCredentials, headers });
    Object.entries(this.callbacks).forEach(([key, value]) => {
      this.es.addEventListener(key, value);
    });
  }

  close() {
    if (typeof this?.callbacks?.done === "function") {
      this.callbacks.done(this);
    }
    if (this?.es?.readyState !== 2) {
      this.es.close();
    }
    Object.entries(this.callbacks).forEach(([key, value]) => {
      this.es.removeEventListener(key, value);
    });
  }
}
