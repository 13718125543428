import React, { useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import getIsProd from "core/shared/util/getIsProd";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccountSelectorEntities } from "core/app/hooks/useAccountSelectorEntities";
import { userType } from "core/shared/util/users";
import { API_URL } from "core/shared/presenter/AuthProvider";

const isProd = getIsProd();

const getVisitorData = (user) => {
  const { email } = user;
  const { id, nickname, role, scope, type } = user[API_URL];
  const typeMap = {
    [userType.agency]: "Agency",
    [userType.internal]: "MediaMath",
  };
  return {
    id,
    full_name: nickname,
    email,
    role,
    scope,
    type,
    userType: typeMap[type] || "",
  };
};

export const trackEvent = ({ evtName, evtObj }) => {
  if (window.pendo) {
    window.pendo.track(evtName, evtObj);
  }
};

const Analytics = () => {
  const { isAuthenticated, user } = useAuth0();
  const shouldSendPendoData = isProd && isAuthenticated;
  const {
    organizationId,
    organizationName,
    agencyId,
    agencyName,
    advertiserId,
    advertiserName,
  } = useAccountSelectorEntities();
  const isPartialOrgData = organizationId && !organizationName;
  const isPartialAgyData = agencyId && !agencyName;
  const isPartialAdvData = advertiserId && !advertiserName;
  const isPartialAny = isPartialOrgData || isPartialAgyData || isPartialAdvData;
  const entityData = useMemo(() => {
    return isPartialAny
      ? {}
      : {
          organizationId,
          organizationName,
          agencyId,
          agencyName,
          advertiserId,
          advertiserName,
        };
  }, [
    advertiserId,
    advertiserName,
    agencyId,
    agencyName,
    isPartialAny,
    organizationId,
    organizationName,
  ]);

  // Report screen size
  useEffect(() => {
    if (window.pendo && shouldSendPendoData) {
      const evtObj = {
        height: window?.screen?.height,
        width: window?.screen?.width,
        "available height": window?.screen?.availHeight,
        "available width": window?.screen?.availWidth,
      };
      trackEvent({ evtName: "Screen Size", evtObj });
    }
  }, [shouldSendPendoData]);

  // Update visitor object as url entities update
  useEffect(() => {
    // Do not update if we're in a situation where we just have IDs and no names (yet)
    if (window.pendo && !isPartialAny && shouldSendPendoData) {
      const visitorData = getVisitorData(user);
      const visitor = { ...visitorData, ...entityData };

      window.pendo.updateOptions({
        visitor,
        account: { id: organizationId, name: organizationName },
      });
    }
  }, [
    entityData,
    isPartialAny,
    organizationId,
    organizationName,
    shouldSendPendoData,
    user,
  ]);

  return (
    <React.Fragment>
      {shouldSendPendoData ? (
        <Helmet
          // TODO: (dlasky) remove when this is resolved: https://github.com/nfl/react-helmet/pull/299
          // TODO: (elewis) Helmet has been abandoned, find replacement instead
          onChangeClientState={(newState, { scriptTags }) => {
            if (scriptTags) {
              const [scriptTag] = scriptTags;

              scriptTag.addEventListener("load", () => {
                if (window && window.pendo && isAuthenticated) {
                  const visitorData = getVisitorData(user);
                  const visitor = { ...visitorData, ...entityData };

                  window.pendo.initialize({ visitor });
                }
              });
            }
          }}
        >
          <script src="https://cdn.pendo.io/agent/static/67305bc6-d2b3-4c82-4d89-023678e15711/pendo.js" />
        </Helmet>
      ) : null}
    </React.Fragment>
  );
};

export default Analytics;
