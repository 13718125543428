import React from "react";
import { StFlexDiv } from "@mediamath/design-system-eng";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import RootMessages from "app/intl/RootMessages ";

const StError = styled(StFlexDiv)`
  align-items: center;
  justify-content: center;
`;

export const ErrorPresenter = () => (
  <StError>
    <h1>
      <FormattedMessage {...RootMessages.error} />
    </h1>
    <FormattedMessage {...RootMessages.try} />
  </StError>
);

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPresenter />;
    }
    return children;
  }
}

export default ErrorBoundary;
