import NavRoutes from "core/app/util/NavRoutes";
import Entities from "core/shared/util/entities";
import createRoutePermission from "core/shared/util/navRoutesPermissionUtils";

const AdminNavRoutesPermissions = {
  ...createRoutePermission(
    [
      { path: NavRoutes.admin.createUser, isCreateMode: true },
      NavRoutes.admin.editUser,
      NavRoutes.admin.editUserDetails,
      NavRoutes.admin.editUserAccess,
      NavRoutes.admin.editUserRoles,
      NavRoutes.admin.editUserPermissions,
    ],
    Entities.USER
  ),
  ...createRoutePermission(
    [
      { path: NavRoutes.admin.createOrg, isCreateMode: true },
      NavRoutes.admin.editOrgBase,
      NavRoutes.admin.editOrgDetails,
      NavRoutes.admin.editOrgSettings,
      NavRoutes.admin.editOrgExchangeSeats,
    ],
    Entities.ORGANIZATION
  ),
  ...createRoutePermission(
    [NavRoutes.admin.editOrgContracts],
    Entities.CONTRACT
  ),
  ...createRoutePermission(
    [
      { path: NavRoutes.admin.createAgency, isCreateMode: true },
      NavRoutes.admin.editAgency,
      NavRoutes.admin.editAgencyDetails,
      NavRoutes.admin.editAgencySettings,
      NavRoutes.admin.editAgencyAdvertisers,
      NavRoutes.admin.editAgencyUsers,
    ],
    Entities.AGENCY
  ),
  ...createRoutePermission(
    [
      { path: NavRoutes.admin.createAdv, isCreateMode: true },
      NavRoutes.admin.editAdv,
      NavRoutes.admin.editAdvDetails,
      NavRoutes.admin.editAdvSettings,
      NavRoutes.admin.editAdvUsers,
    ],
    Entities.ADVERTISER
  ),
  ...createRoutePermission(
    [
      { path: NavRoutes.admin.createVendor, isCreateMode: true },
      NavRoutes.admin.vendorsGridView,
      NavRoutes.admin.editVendor,
      NavRoutes.admin.editVendorDetails,
      NavRoutes.admin.editVendorSettings,
    ],
    Entities.VENDOR
  ),
};

export default AdminNavRoutesPermissions;
