import React, { useState, useMemo, useContext, useCallback } from "react";
import { StFlexDiv } from "@mediamath/design-system-eng";
import ToastMessage from "core/shared/presenter/ToastMessage";
import PropTypes from "prop-types";
import { pull } from "lodash";
import styled from "@emotion/styled";

const NotifierContext = React.createContext({});

const AbsFlexDiv = styled(StFlexDiv)`
  position: absolute;
  pointer-events: none;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 222; /* to beat the sidebar */

  > * {
    pointer-events: all;
  }
`;

export const useNotifier = () => {
  const { push, pop } = useContext(NotifierContext);
  return useMemo(() => ({ push, pop }), [push, pop]);
};

const useNotifierContext = () => {
  const [messages, setMessages] = useState([]);

  const push = useCallback(
    (msg) => {
      const message = msg;
      if (!msg.key) {
        message.key = Date.now() + Math.random();
      }
      setMessages((prev) => [...prev, message]);
    },
    [setMessages]
  );
  const pop = useCallback(
    (msgOrIndex) => {
      const type = typeof msgOrIndex;
      if (type === "number") {
        setMessages((prev) => {
          const next = prev.slice();
          next.splice(msgOrIndex, 1);
          return next;
        });
      } else if (type === "object") {
        setMessages((prev) => pull(prev.slice(), [msgOrIndex]));
      }
    },
    [setMessages]
  );

  return useMemo(() => ({ messages, push, pop }), [messages, pop, push]);
};

const NotifierProvider = ({ children }) => {
  const { messages, push, pop } = useNotifierContext();
  return (
    <NotifierContext.Provider value={{ push, pop }}>
      {children}
      <AbsFlexDiv>
        {messages.map((msg, index) => (
          <ToastMessage {...msg} onExit={pop} index={index} />
        ))}
      </AbsFlexDiv>
    </NotifierContext.Provider>
  );
};

NotifierProvider.propTypes = {
  children: PropTypes.node,
};

NotifierProvider.defaultProps = {
  children: null,
};

export default NotifierProvider;
