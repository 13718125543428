/* eslint-disable react/no-array-index-key */

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { StFlexDiv, StHeadline, Colors } from "@mediamath/design-system-eng";
import Highlighter from "react-highlight-words";
import messages from "core/app/intl/AppMessages";
import { useIntl } from "react-intl";

const StListView = styled(StFlexDiv, {
  shouldForwardProp: () => true,
})`
  border-top: 2px solid ${Colors.ColorGrey000};
  overflow: auto;
`;
const StListItem = styled("div")`
  background: white;
  border-bottom: 2px solid ${Colors.ColorGrey000};
  padding: 18px;
  cursor: pointer;

  &:hover {
    background: ${Colors.ColorBlue000};
  }
`;

const StHierarchyItem = styled("div")`
  margin-top: 6px;
`;

const ListItem = (props) => {
  const { item, hierarchy, onSelect, searchTerm } = props;
  const { name, id } = item;
  const itemSelectHandler = () => {
    onSelect(item);
  };

  return (
    <StListItem
      onClick={itemSelectHandler}
      data-testid="search-result"
      className="red"
    >
      <StHeadline tag="h3" margin="0 0 0 0">
        <Highlighter
          highlightStyle={{
            backgroundColor: Colors.ColorBlue100,
          }}
          searchWords={[searchTerm]}
          autoEscape
          textToHighlight={name}
        />
      </StHeadline>
      <StHeadline tag="h3" margin="6px 0 0 0">
        <Highlighter
          highlightStyle={{
            backgroundColor: Colors.ColorBlue100,
          }}
          searchWords={[searchTerm]}
          autoEscape
          textToHighlight={`${id}`}
        />
      </StHeadline>
      <StHierarchyItem>
        <Highlighter
          highlightStyle={{
            backgroundColor: Colors.ColorBlue100,
          }}
          searchWords={[searchTerm]}
          autoEscape
          textToHighlight={hierarchy}
        />
      </StHierarchyItem>
    </StListItem>
  );
};

/**
 * Compose the full hierarchy string
 *
 * @param {string} self - the name of the item whose hierarchy we are printing -- if only thing available, we are looking at an org
 * @param {string} [agyName] - the name of the agency if any -- if available, we are looking at an advertiser
 * @param {string} [orgName] - the name of the org if any -- if available, we are looking at an agency or advertiser
 * @returns string
 */
const getHierarchy = (...args) => {
  return args
    .filter((arg) => !!arg)
    .reverse()
    .join(" > ");
};

/**
 * Organizations, agencies and advertiesers
 */
const ListView = (props) => {
  const intl = useIntl();
  const {
    data,
    onSelect,
    searchTerm,
    noMatchMessage = intl.formatMessage(messages.noMatch),
  } = props;

  const selectHandler = (item) => {
    onSelect(item);
  };

  return (
    <StListView data-id="miller-search-results">
      {!data.length && (
        <StListItem>
          <StHeadline tag="h3" margin="0 0 0 0">
            {noMatchMessage}
          </StHeadline>
        </StListItem>
      )}
      {data.map((item, index) => {
        const { name, organization, agency } = item;
        const orgName = organization && organization.name;
        const agyName = agency && agency.name;
        return (
          <ListItem
            key={index}
            item={item}
            hierarchy={getHierarchy(name, agyName, orgName)}
            onSelect={selectHandler}
            searchTerm={searchTerm}
          />
        );
      })}
    </StListView>
  );
};

ListItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  hierarchy: PropTypes.string,
  onSelect: PropTypes.func,
  searchTerm: PropTypes.string,
};

ListItem.defaultProps = {
  item: undefined,
  hierarchy: undefined,
  searchTerm: undefined,
  onSelect: () => {},
};

ListView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      organizations: PropTypes.shape([]),
      agencies: PropTypes.shape([]),
      advertisers: PropTypes.shape([]),
    })
  ),
  onSelect: PropTypes.func,
  searchTerm: PropTypes.string,
  noMatchMessage: PropTypes.string,
};

ListView.defaultProps = {
  data: undefined,
  searchTerm: undefined,
  onSelect: () => {},
  noMatchMessage: undefined,
};

export default ListView;
