const userScope = {
  global: "GLOBAL",
  select: "SELECT",
};

const userType = {
  internal: "INTERNAL",
  agency: "AGENCY",
};

const userRole = {
  admin: "ADMIN",
  manager: "MANAGER",
  reporter: "REPORTER",
};

const permissionSettings = {
  CAMPAIGNS_STRATEGIES: "campaignsStrategies",
  SITE_LISTS: "siteLists",
  PIXELS_CREATIVES: "pixelsCreatives",
  CAMPAIGN_REPORTS: "campaignReports",
  SEGMENTS: "segments",
  DATA_ATTRIBUTE_MAPPING: "dataAttributeMapping",
  SEGMENTS_REPORTS: "segmentReports",
  MARGIN: "margin",
  USERS: "users",
  ORGANIZATION: "organization",
  CONTRACT: "contract",
};
export { userRole, userScope, userType, permissionSettings };
