import getConnection from "core/shared/util/marvellConnection";
import useGetData from "core/shared/hooks/useGetData";

const useAdvertiser = (advertiserId) => {
  const connection = getConnection();
  const { data: advertiser, loading: isLoading } = useGetData({
    dependencies: [advertiserId],
    getResponseData: (r) => r,
    dataPromise: () => {
      if (!advertiserId) {
        return Promise.resolve({});
      }

      return connection.getAdvertiserById({
        advertiserId,
      });
    },
  });

  return {
    advertiser,
    isLoading,
  };
};

export default useAdvertiser;
