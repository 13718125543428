import { defineMessages } from "react-intl";

const InventoryMessages = defineMessages({
  DealHeader: {
    id: "deal.module.header.name",
    defaultMessage: "Inventory",
  },
  MediaMathId: {
    id: "deal.module.column.MediaMathId",
    defaultMessage: "MediaMath ID",
  },
  InventoryTabPublisher: {
    id: "inventory.tab.Publisher",
    defaultMessage: "Publishers",
  },
  InventoryTabDealGroups: {
    id: "inventory.tab.DealGroups",
    defaultMessage: "Deal Groups",
  },
  InventoryTabSpm: {
    id: "inventory.tab.spm",
    defaultMessage: "Supply Paths",
  },
  InventoryTabSupplyControls: {
    id: "inventory.tab.supplyControls",
    defaultMessage: "Supply Controls",
  },
  SupplyControlTabSupplyPaths: {
    id: "inventory.supplyControl.tab.supplyPaths",
    defaultMessage: "Supply Paths",
  },
  SupplyControlTabPathOptimizer: {
    id: "inventory.supplyControl.tab.pathOptimizer",
    defaultMessage: "Path Optimizer",
  },
  InventoryTabsSiteLists: {
    id: "inventory.tab.site",
    defaultMessage: "Site Lists",
  },
  PublisherName: {
    id: "publisher.module.column.name",
    defaultMessage: "Publisher Name",
  },
  NewPublisherBtn: {
    id: "publisher.new.Publisher_btn",
    defaultMessage: "Add Publishers",
  },
  NoPublishers: {
    id: "publisher.module.nodata",
    defaultMessage: "You have no publishers under this organization",
  },
  InventoryTabDeal: {
    id: "inventory.tab.deals",
    defaultMessage: "Deals",
  },
  SPMLoading: {
    id: "deal.module.spmloading",
    defaultMessage: "Supply Paths loading",
  },
  DealsLoading: {
    id: "deal.module.dealsloading",
    defaultMessage: "Deals loading",
  },
  DealName: {
    id: "deal.module.column.name",
    defaultMessage: "Deal Name",
  },
  DealID: {
    id: "deal.module.column.deal_id",
    defaultMessage: "Deal ID",
  },
  StrategyCount: {
    id: "deal.module.column.strategy_count",
    defaultMessage: "Strategies Targeted",
  },
  TotalSpend: {
    id: "deal.module.column.total_spend",
    defaultMessage: "Total Spend",
  },
  BidRequests: {
    id: "deal.module.column.bid_requests",
    defaultMessage: "Bid Requests",
  },
  Matches: {
    id: "deal.module.column.matches",
    defaultMessage: "Matches",
  },
  MatchRate: {
    id: "deal.module.column.match_rate",
    defaultMessage: "Match Rate",
  },
  Bids: {
    id: "deal.module.column.bids",
    defaultMessage: "Bids",
  },
  BidRate: {
    id: "deal.module.column.bid_rate",
    defaultMessage: "Bid Rate",
  },
  Wins: {
    id: "deal.module.column.wins",
    defaultMessage: "Wins",
  },
  WinRate: {
    id: "deal.module.column.win_rate",
    defaultMessage: "Win Rate",
  },
  AvWinCpm: {
    id: "deal.module.column.av_win_cpm",
    defaultMessage: "Av. Win CPM",
  },
  Impressions: {
    id: "deal.module.column.impressions",
    defaultMessage: "Impressions",
  },
  vCPM: {
    id: "deal.module.column.vCPM",
    defaultMessage: "vCPM",
  },
  Reach: {
    id: "deal.module.column.reach",
    defaultMessage: "Reach",
  },
  Frequency: {
    id: "deal.module.column.frequency",
    defaultMessage: "Frequency",
  },
  Channels: {
    id: "deal.module.column.channels",
    defaultMessage: "Channels",
  },
  Publishers: {
    id: "deal.module.column.publishers",
    defaultMessage: "Publisher",
  },
  DealPrice: {
    id: "deal.module.column.deal_price",
    defaultMessage: "Deal Price",
  },
  MediaSpend: {
    id: "deal.module.column.media_spend",
    defaultMessage: "Media Spend",
  },
  MediaSpendECPM: {
    id: "deal.module.column.media_spend_ecpm",
    defaultMessage: "Media Spend eCPM",
  },
  ViewabilityRate: {
    id: "deal.module.column.viewability_rate",
    defaultMessage: "Viewability Rate",
  },
  VideoCompletionRate: {
    id: "deal.module.column.video_completion_rate",
    defaultMessage: "Video Completion Rate",
  },
  ExchangeName: {
    id: "deal.module.column.exchange_name",
    defaultMessage: "Exchange Name",
  },
  ExchangeId: {
    id: "deal.module.column.exchange_id",
    defaultMessage: "Exchange ID",
  },
  StartDate: {
    id: "deal.module.column.startDate",
    defaultMessage: "Start Date",
  },
  EndDate: {
    id: "deal.module.column.endDate",
    defaultMessage: "End Date",
  },
  DateCreated: {
    id: "deal.module.column.dateCreated",
    defaultMessage: "Date Created",
  },
  eCPA: {
    id: "deal.module.column.eCPA",
    defaultMessage: "eCPA",
  },
  eCPM: {
    id: "deal.module.column.eCPM",
    defaultMessage: "eCPM",
  },
  Clicks: {
    id: "deal.module.column.clicks",
    defaultMessage: "Clicks",
  },
  CTR: {
    id: "deal.module.column.ctr",
    defaultMessage: "CTR",
  },
  ResponseRate: {
    id: "deal.module.column.responseRate",
    defaultMessage: "Response Rate/1K Imps",
  },
  DealType: {
    id: "deal.module.column.dealType",
    defaultMessage: "Deal Type",
  },
  DealStatus: {
    id: "deal.module.column.status",
    defaultMessage: "Deal Status",
  },
  Description: {
    id: "deal.module.column.description",
    defaultMessage: "Description",
  },
  NoData: {
    id: "deal.module.nodata",
    defaultMessage: "You have no deals under this organization",
  },
  NoSearchData: {
    id: "deal.module.nosearchdata",
    defaultMessage: "No deals match what you are looking for",
  },
  NoOrganization: {
    id: "deal.module.noorganization",
    defaultMessage: "Please select an organization",
  },
  Last7Days: {
    id: "deal.module.last7Days",
    defaultMessage: "Last 7 Days",
  },
  Last30Days: {
    id: "deal.module.last30Days",
    defaultMessage: "Last 30 Days",
  },
  MonthToDate: {
    id: "deal.module.monthToDate",
    defaultMessage: "Month To Date",
  },
  Yesterday: {
    id: "deal.module.yesterday",
    defaultMessage: "Yesterday",
  },
  Custom: {
    id: "deal.module.custom",
    defaultMessage: "Custom",
  },
  PriceType: {
    id: "deal.module.price_type",
    defaultMessage: "Price Type",
  },
  Currency: {
    id: "deal.module.currency",
    defaultMessage: "Currency",
  },
  Export: {
    id: "deal.module.button.export",
    defaultMessage: "EXPORT",
  },
  InlineEditSuccess: {
    id: "deal.module.inline-edit.success",
    defaultMessage: "Successfully Updated",
  },
  ViewStrategies: {
    id: "deal.module.view_strategies",
    defaultMessage: "View Strategies",
  },
  StrategiesTargetingDeal: {
    id: "deal.module.strategies_targeting_deal",
    defaultMessage: "Deal Health",
  },
  AddToFavorites: {
    id: "deal.module.addToFavorites",
    defaultMessage: "Add To Favorites",
  },
  RemoveFromFavorite: {
    id: "deal.module.removeFromFavorite",
    defaultMessage: "Remove From Favorites",
  },
  DealAddToFavoriteSuccess: {
    defaultMessage: `{counter, plural, one {# deal} other {# deals}} added to Favorites`,
    id: "deals.addToFavorite.success",
    parameterized: true,
  },
  DealRemovedFromFavoriteSuccess: {
    defaultMessage: `{counter, plural, one {# deal} other {# deals}} removed from Favorites`,
    id: "deals.removeFromFavorite.success",
    parameterized: true,
  },
  DealFavoriteError: {
    defaultMessage: `Error while updating favorite`,
    id: "deals.Favorite.error",
  },
  FavoriteDealsOnly: {
    defaultMessage: `Show favorites`,
    id: "deals.favoriteDealsOnly",
  },
  DealHealthHeader: {
    id: "deal.module.deal_health_header",
    defaultMessage: "Deal Health ({timeWindow})",
  },
  OverviewTab: {
    id: "deal.module.overview_tab",
    defaultMessage: "Overview",
  },
  StrategiesTab: {
    id: "deal.module.strategies_tab",
    defaultMessage: "Active Strategies in Flight",
  },
  CompositionTab: {
    id: "deal.module.composition_tab",
    defaultMessage: "Composition",
  },
  PGSetupStatusTab: {
    id: "deal.module.pg_setup_status_tab",
    defaultMessage: "Setup Status",
  },
  DimensionsFilter: {
    id: "deal.module.dimensions_Filter",
    defaultMessage: "Dimensions:",
  },
  DimensionsFilterCreatives: {
    id: "deal.module.dimensions_Filter_Creatives",
    defaultMessage: "Creatives",
  },
  DimensionsFilterGeography: {
    id: "deal.module.dimensions_Filter_Geography",
    defaultMessage: "Geography",
  },
  DimensionsFilterInventory: {
    id: "deal.module.dimensions_Filter_Inventory",
    defaultMessage: "Inventory",
  },
  DimensionsFilterTechnology: {
    id: "deal.module.dimensions_Filter_Technology",
    defaultMessage: "Technology",
  },
  DimensionsFilterColumnDimCount: {
    id: "deal.module.dimensions_Filter_Column_DimCount",
    defaultMessage: "Bid requests",
  },
  DimensionsFilterColumnBidRequestPct: {
    id: "deal.module.dimensions_Filter_Column_BidRequestPct",
    defaultMessage: "% Bid requests",
  },
  DimensionsFilterCreativesColumnSecure: {
    id: "deal.module.dimensions_Filter_Creatives_Column_Secure",
    defaultMessage: "Secure",
  },
  DimensionsFilterCreativesColumnAdSize: {
    id: "deal.module.dimensions_Filter_Creatives_Column_AdSize",
    defaultMessage: "Ad size",
  },
  DimensionsFilterCreativesColumnAdPosition: {
    id: "deal.module.dimensions_Filter_Creatives_Column_AdPosition",
    defaultMessage: "Ad position",
  },
  DimensionsFilterCreativesColumnExpandableDirection: {
    id: "deal.module.dimensions_Filter_Creatives_Column_Expandable_Direction",
    defaultMessage: "Expandable direction",
  },
  DimensionsFilterCreativesColumnCreativeAttribute: {
    id: "deal.module.dimensions_Filter_Creatives_Column_Creative_Attribute",
    defaultMessage: "Creative attribute",
  },
  DimensionsFilterCreativesColumnMRAID: {
    id: "deal.module.dimensions_Filter_Creatives_Column_MRAID",
    defaultMessage: "MRAID",
  },
  DimensionsFilterCreativesColumnVideoSize: {
    id: "deal.module.dimensions_Filter_Creatives_Column_VideoSize",
    defaultMessage: "Video size",
  },
  DimensionsFilterCreativesColumnVideoSkip: {
    id: "deal.module.dimensions_Filter_Creatives_Column_VideoSkip",
    defaultMessage: "Video skip",
  },
  DimensionsFilterCreativesColumnVideoLinearity: {
    id: "deal.module.dimensions_Filter_Creatives_Column_Video_Linearity",
    defaultMessage: "Video linearity",
  },
  DimensionsFilterTechnologyColumnDeviceType: {
    id: "deal.module.dimensions_Filter_Technology_Column_Device_Type",
    defaultMessage: "Device Type",
  },
  DimensionsFilterTechnologyColumnBrowser: {
    id: "deal.module.dimensions_Filter_Technology_Column_Browser",
    defaultMessage: "Browser",
  },
  DimensionsFilterTechnologyColumnDeviceOS: {
    id: "deal.module.dimensions_Filter_Technology_Column_Device_OS",
    defaultMessage: "Device OS",
  },
  DimensionsFilterInventoryColumnSiteDomain: {
    id: "deal.module.dimensions_Filter_Inventory_Column_Site_Domain",
    defaultMessage: "Site Domain",
  },
  DimensionsFilterInventoryColumnAppDomain: {
    id: "deal.module.dimensions_Filter_Inventory_Column_App_Domain",
    defaultMessage: "App Domain",
  },
  DimensionsFilterInventoryColumnCountry: {
    id: "deal.module.dimensions_Filter_Inventory_Column_Country",
    defaultMessage: "Country",
  },
  DimensionsFilterInventoryColumnRegion: {
    id: "deal.module.dimensions_Filter_Inventory_Column_Region",
    defaultMessage: "Region",
  },
  DimensionsFilterInventoryColumnDMA: {
    id: "deal.module.dimensions_Filter_Inventory_Column_DMA",
    defaultMessage: "DMA",
  },
  DimensionsFilterInventoryColumnZipCode: {
    id: "deal.module.dimensions_Filter_Inventory_Column_Zip_Code",
    defaultMessage: "Zip Code",
  },
  DimensionsFilterInventoryColumnCountryCode: {
    id: "deal.module.dimensions_Filter_Inventory_Column_Country_Code",
    defaultMessage: "Country Code",
  },
  NoIssues: {
    id: "deal.module.overview_tab.no_issues",
    defaultMessage: "No Issues",
  },
  Issues: {
    id: "deal.module.pgsetup_tab.issues",
    defaultMessage: "Issues",
  },
  DealGroupSidebar: {
    id: "deal.module.tab.DealGroup.sidebar",
    defaultMessage: "Deal Group",
  },
  StrategiesTargetingDealGroup: {
    id: "deal.module.strategies_targeting_deal_group",
    defaultMessage: "Strategies Targeting Deal Group",
  },
  StrategyHealthSidebar: {
    id: "deal.module.strategy.health.sidebar",
    defaultMessage: "Strategy Health",
  },
  DealsDropDownTitle: {
    id: "deal.module.strategy.health.sidebar.type",
    defaultMessage: "Type: ",
  },
  StrategyHealthSidebarInventoryTab: {
    id: "deal.module.strategy_health_sidebar_inventory_tab",
    defaultMessage: "Inventory",
  },
  ContextMenuEdit: {
    id: "deal.contextmenu.edit",
    defaultMessage: "Edit Deal",
  },
  ContextMenuDealHealth: {
    id: "deal.contextmenu.dealHealth",
    defaultMessage: "View Deal Health",
  },
  ContextMenuRemoveFromGroup: {
    id: "deal.contextmenu.remove_from_group",
    defaultMessage: "Remove from group",
  },
  DealRemoveFromDealGroupSuccess: {
    id: "deal.contextmenu.remove_from_group.success",
    defaultMessage: "Deal removed successfully",
  },
  LastDealRemoveError: {
    id: "deal.last.remove_from_group.error",
    defaultMessage: "The last deal can't be removed from the deal group",
  },
  NewDeal: {
    id: "deal.new.deal",
    defaultMessage: "Deal",
  },
  NewDealBtn: {
    id: "deal.new.deal_btn",
    defaultMessage: "New Deal",
  },
  NewDealSingle: {
    id: "deal.new.deal_btn.single",
    defaultMessage: "Single Deal",
  },
  NewDealBulk: {
    id: "deal.new.deal_btn.bulk",
    defaultMessage: "Bulk Deals",
  },
  FooterCancel: {
    id: "deal.footer.cancel",
    defaultMessage: "Cancel",
  },
  FooterSelected: {
    id: "deal.footer.selected",
    defaultMessage: "Selected",
  },
  FooterGroups: {
    id: "deal.footer.groups",
    defaultMessage: "Group",
  },
  FooterGroupsPlaceHolder: {
    id: "deal.footer.groups.placeholder",
    defaultMessage: "Select Deal Group",
  },
  PopupNewGroup: {
    id: "deal.footer.newGroup",
    defaultMessage: "New Group",
  },
  PopupSave: {
    id: "deal.footer.save",
    defaultMessage: "Save",
  },
  DealGroupInlineEditSuccess: {
    id: "deal.sidebar.inline-edit.success",
    defaultMessage: "Deal group name successfully updated",
  },
  AddToDealGroupSuccess: {
    id: "deal.footer.saveToExistingDealsGroupSuccess",
    defaultMessage: "Deals saved to Deal Group",
  },
  AddToDealGroupError: {
    id: "deal.footer.saveToExistingDealsGroupError",
    defaultMessage:
      "The selected deal(s) could not be added to the deal groups. Please try again",
  },
  AddToDealGroupInternalServerError: {
    id: "deal.footer.saveToExistingDealsGroupError.server500",
    defaultMessage: "Internal server error",
  },
  AddToDealGroupDealExistsError: {
    id: "deal.footer.saveToExistingDealsGroupError.already",
    defaultMessage: "Selected deal(s) already exist in the deal group",
  },
  AddToDealGroupDealOwnerError: {
    id: "deal.footer.saveToExistingDealsGroupError.sameowner",
    defaultMessage:
      "Selected deal(s) should have the same owner as the deal group",
  },
  DealGroupName: {
    id: "deal.group.module.column.name",
    defaultMessage: "Deal Group Name",
  },
  DealGroupId: {
    id: "deal.group.module.column.id",
    defaultMessage: "Deal Group ID",
  },
  DealGroupCreated: {
    id: "deal.group.module.column.dealgroupcreated",
    defaultMessage: "Group Created",
  },
  DealGroupCount: {
    id: "deal.group.module.column.count",
    defaultMessage: "Deals in Group",
  },
  NoDealGroups: {
    id: "deal.group.module.nodata",
    defaultMessage: "You have no deal groups under this organization",
  },
  GroupSidebarHeader: {
    id: `deal.group.module.sidebar.newgroup.header`,
    defaultMessage: `New group of {rowsChecked, number} {rowsChecked, plural,
             one { deal }
             other { deals }
            }`,
  },
  DealGroupNameInputError: {
    id: `deal.group.module.sidebar.newgroup.titleInputError`,
    defaultMessage: "Group name is required",
  },
  DealsInGroup: {
    id: "deal.group.module.dealsInGroup",
    defaultMessage: "Deals in Group",
  },
  DealGroupsStrategiesTab: {
    id: "deal.group.module.strategies_tab",
    defaultMessage: "Active Strategies in Flight",
  },
  ViewDeals: {
    id: "deal.group.module.view_deals",
    defaultMessage: "View Deals",
  },
  CPMTopline: {
    id: "deal.module.topline.CPM",
    defaultMessage: "CPM",
  },
  CPATopline: {
    id: "deal.module.topline.CPA",
    defaultMessage: "CPA",
  },
  DealBulkEditButton: {
    id: `deal.group.module.bulkedit.button`,
    defaultMessage: "Edit Deal Details",
  },
  DealBulkEditSidebarHeader: {
    id: `deal.group.module.sidebar.bulkedit.header`,
    defaultMessage: `Edit details for {rowsChecked, number} {rowsChecked, plural,
             one { deal }
             other { deals }
            }`,
  },
  NoBiddablesError: {
    id: `deal.module.column.issues.noBiddablesdError`,
    defaultMessage:
      "There have been no bid requests in the past 24h for this deal.",
  },
  NoMatchesError: {
    id: `deal.module.column.issues.noMatchesError`,
    defaultMessage:
      "No bid opportunities have matched your strategy targeting settings in the past 24h. See strategies for more details.",
  },
  NoBidsError: {
    id: `deal.module.column.issues.noBidsError`,
    defaultMessage:
      "No bids have been submitted for this deal in the past 24h. See strategies for more details.",
  },
  NoWinsError: {
    id: `deal.module.column.issues.noWinsError`,
    defaultMessage:
      "No bids submitted for this deal have won the external auction in the past 24h. Try bidding higher.",
  },
  NoBiddables: {
    id: `deal.module.column.issues.noBiddables`,
    defaultMessage: "No Bid Requests",
  },
  NoMatches: {
    id: `deal.module.column.issues.noMatches`,
    defaultMessage: "No Matches",
  },
  NoBids: {
    id: `deal.module.column.issues.noBids`,
    defaultMessage: "No Bids",
  },
  NoWins: {
    id: `deal.module.column.issues.NoWins`,
    defaultMessage: "No Wins",
  },
  Underpacing: {
    id: `deal.module.column.issues.underpacing`,
    defaultMessage: "You have underpacing strategies",
  },
  noRequests: {
    id: `deal.module.column.issues.noRequests`,
    defaultMessage: "No requests",
  },
  campaignEndDateError: {
    id: `deal.module.column.issues.campaignEndDateError`,
    defaultMessage: "Campaign end date error",
  },
  campaignStartDateError: {
    id: `deal.module.column.issues.campaignStartDateError`,
    defaultMessage: "Campaign start date error",
  },
  sspStatusMismatch: {
    id: `deal.module.column.issues.sspStatusMismatch`,
    defaultMessage: "ssp status mismatch",
  },
  sspStartDateMismatch: {
    id: `deal.module.column.issues.sspStartDateMismatch`,
    defaultMessage: "ssp start date mismatch",
  },
  sspEndDateMismatch: {
    id: `deal.module.column.issues.sspEndDateMismatch`,
    defaultMessage: "ssp end date mismatch",
  },
  organizationContractStartDateError: {
    id: `deal.module.column.issues.organizationContractStartDateError`,
    defaultMessage: "Organization contract start date error",
  },
  organizationContractEndDateError: {
    id: `deal.module.column.issues.organizationContractEndDateError`,
    defaultMessage: "Organization contract end date error",
  },
  organizationInactive: {
    id: `deal.module.column.issues.organizationInactive`,
    defaultMessage: "Organization Inactive",
  },
  agencyInactive: {
    id: `deal.module.column.issues.agencyInactive`,
    defaultMessage: "Agency Inactive",
  },
  advertiserInactive: {
    id: `deal.module.column.issues.advertiserInactive`,
    defaultMessage: "Advertiser Inactive",
  },
  campaignInactive: {
    id: `deal.module.column.issues.campaignInactive`,
    defaultMessage: "Campaign inactive",
  },
  campaignPgFlagError: {
    id: `deal.module.column.issues.campaignPgFlagError`,
    defaultMessage: "CampaignPG flag error",
  },
  strategyInactive: {
    id: `deal.module.column.issues.strategyInactive`,
    defaultMessage: "Strategy inactive",
  },
  strategyStartDateError: {
    id: `deal.module.column.issues.strategyStartDateError`,
    defaultMessage: "Strategy start date error",
  },
  strategyEndDateError: {
    id: `deal.module.column.issues.strategyEndDateError`,
    defaultMessage: "Strategy end date error",
  },
  strategyPgFlagError: {
    id: `deal.module.column.issues.strategyPgFlagError`,
    defaultMessage: "Strategy PG flag error",
  },
  strategyConceptInactive: {
    id: `deal.module.column.issues.strategyConceptInactive`,
    defaultMessage: "Strategy concept inactive",
  },
  creativeInactive: {
    id: `deal.module.column.issues.creativeInactive`,
    defaultMessage: "Creative inactive",
  },
  dealStatusInactive: {
    id: `deal.module.column.issues.dealStatusInactive`,
    defaultMessage: "Deal status inactive",
  },
  dealStartDateError: {
    id: `deal.module.column.issues.dealStartDateError`,
    defaultMessage: "Deal start date error",
  },
  dealEndDateError: {
    id: `deal.module.column.issues.dealEndDateError`,
    defaultMessage: "Deal end date error",
  },
  pgIncomplete: {
    id: `deal.module.column.issues.pg_incomplete`,
    defaultMessage: "PG setup is incomplete",
  },
  multipleIssues: {
    id: `deal.module.column.issues.multiple_issues`,
    defaultMessage: "{totalCount} errors",
  },
  StrategyNoRequests: {
    id: `strategy.module.column.issues.StrategyNoRequests`,
    defaultMessage: "This strategy had no bid requests in the past 24h",
  },
  StrategyNoMatches: {
    id: `strategy.module.column.issues.strategyNoMatches`,
    defaultMessage: "This strategy had no matches in the past 24h",
  },
  StrategyNoBids: {
    id: `strategy.module.column.issues.strategyNoBids`,
    defaultMessage: "This strategy has not bid in the past 24h",
  },
  StrategyNoWins: {
    id: `strategy.module.column.issues.strategyNoWins`,
    defaultMessage:
      "This strategy has not won the external auction in the past 24h",
  },
  StrategyUnderpacing: {
    id: `strategy.module.column.issues.strategyUnderpacing`,
    defaultMessage: "This strategy is underpacing",
  },
  StrategyHealthBidRequestsTooltip: {
    id: `strategyhealth.module.inventory.strategyHealthBidRequestsTooltip`,
    defaultMessage:
      "The total count of opportunities associated with this deal before targeting for the past 24hr.",
  },
  StrategyHealthMatchesTooltip: {
    id: `strategyhealth.module.inventory.strategyHealthMatchesTooltip`,
    defaultMessage:
      "The total count of opportunities associated with this deal after targeting for the past 24hr.",
  },
  StrategyHealthBidsTooltip: {
    id: `strategyhealth.module.inventory.strategyHealthBidsTooltip`,
    defaultMessage:
      "The total count of bids submitted for this inventory for the past 24hr.",
  },
  StrategyHealthImpressionsTooltip: {
    id: `strategyhealth.module.inventory.strategyHealthImpressionsTooltip`,
    defaultMessage: "The total count of impressions served for the past 24hr.",
  },
  StrategyHealthNoBidRequests: {
    id: `strategyhealth.module.inventory.strategyHealthNoBidRequests`,
    defaultMessage:
      "There have been no bid requests in the past 24h for this deal.",
  },
  StrategyHealthNoBidRequestsTooltip: {
    id: `strategyhealth.module.inventory.strategyHealthNoBidRequestsTooltip`,
    defaultMessage: "Possible reasons for no bid requests:",
  },
  StrategyHealthNoBidRequestsTooltipList1: {
    id: `strategyhealth.module.inventory.strategyHealthNoBidRequestsTooltipList1`,
    defaultMessage: "The SSP or publisher are not sending bid requests.",
  },
  StrategyHealthNoBidRequestsTooltipList2: {
    id: `strategyhealth.module.inventory.strategyHealthNoBidRequestsTooltipList2`,
    defaultMessage: "Requests are being sent to the wrong seat.",
  },
  StrategyHealthNoBidRequestsTooltipList3: {
    id: `strategyhealth.module.inventory.strategyHealthNoBidRequestsTooltipList3`,
    defaultMessage: "Deal ID is entered incorrectly.",
  },
  StrategyHealthNoMatches: {
    id: `strategyhealth.module.inventory.strategyHealthNoMatches`,
    defaultMessage:
      "One or more targeted dimensions had no matches in the past 24h.",
  },
  StrategyHealthNoBidsBiddingBelowFloor: {
    id: `strategyhealth.module.inventory.strategyHealthNoBidsBiddingBelowFloor`,
    defaultMessage: "This strategy is bidding below the deal floor.",
  },
  StrategyHealthNoBidsLosingAuction: {
    id: `strategyhealth.module.inventory.strategyHealthNoBidsLosingAuction`,
    defaultMessage:
      "This strategy is being outbid by another in the MediaMath auction.",
  },
  StrategyHealthLowBidsBiddingBelowFloor: {
    id: `strategyhealth.module.inventory.strategyHealthLowBidsBiddingBelowFloor`,
    defaultMessage: "This strategy is bidding below the deal floor.",
  },
  StrategyHealthLowBidsLosingAuction: {
    id: `strategyhealth.module.inventory.strategyHealthLowBidsLosingAuction`,
    defaultMessage:
      "This strategy is being outbid by another in the MediaMath auction.",
  },
  StrategyHealthNoImpressions: {
    id: `strategyhealth.module.inventory.strategyHealthNoImpressions`,
    defaultMessage: `This strategy is frequently losing the auctions it enters. Try increasing strategy bids, or check for creative rejections.`,
  },
  StrategyHealthLowMatches: {
    id: `strategyhealth.module.inventory.strategyHealthLowMatches`,
    defaultMessage:
      "One or more targeted dimensions had low matches in the past 24h.",
  },
  Dimensions: {
    id: `strategyhealth.module.inventory.dimensions`,
    defaultMessage: "Dimensions",
  },
  MatchedPercentage: {
    id: `strategyhealth.module.inventory.matchedPercentage`,
    defaultMessage: "% Matched",
  },
  TotalCountMatches: {
    id: `strategyhealth.module.inventory.TotalCountMatches`,
    defaultMessage: "Total matches",
  },
  ValuesTargeted: {
    id: `strategyhealth.module.inventory.valuesTargeted`,
    defaultMessage: "Values Targeted",
  },
  BiddingBreakdown: {
    id: `deal.module.sidebar.dealHealth.biddingBreakdown`,
    defaultMessage: "Bidding Breakdown",
  },
  NoBiddingBreakdown: {
    id: `deal.module.sidebar.dealHealth.noBiddingBreakdown`,
    defaultMessage:
      "There was a problem retrieving bidding breakdown information. Please try again later.",
  },
  NoPGSetupStatus: {
    id: `deal.module.sidebar.dealHealth.noPGSetupStatus`,
    defaultMessage: "Programmatic guarantee setup checklist",
  },
  Field: {
    id: `deal.module.sidebar.dealHealth.field`,
    defaultMessage: "Field",
  },
  Status: {
    id: `deal.module.sidebar.dealHealth.status`,
    defaultMessage: "Status",
  },
  Value: {
    id: `deal.module.sidebar.dealHealth.value`,
    defaultMessage: "Value",
  },
  Issue: {
    id: `deal.module.sidebar.dealHealth.issue`,
    defaultMessage: "Issue",
  },
  TotalCount: {
    id: `deal.module.sidebar.dealHealth.totalCount`,
    defaultMessage: "Total Count (Up to Last 24 Hours)",
  },
  InlineStrategies: {
    id: `deal.module.sidebar.dealHealth.strategies`,
    defaultMessage: "strategies",
  },
  BidRequestTooltip: {
    id: `deal.module.sidebar.dealHealth.bidRequestTooltip`,
    defaultMessage: "Possible reasons for no bid requests: {reasonList}",
  },
  BidRequestTooltipReasonOne: {
    id: `deal.module.sidebar.dealHealth.bidRequestTooltipReasonOne`,
    defaultMessage: "The SSP or publisher are not sending bid requests.",
  },
  BidRequestTooltipReasonTwo: {
    id: `deal.module.sidebar.dealHealth.bidRequestTooltipReasonTwo`,
    defaultMessage: "Requests are being sent to the wrong seat.",
  },
  BidRequestTooltipReasonThree: {
    id: `deal.module.sidebar.dealHealth.bidRequestTooltipReasonThree`,
    defaultMessage: "Deal ID is entered incorrectly.",
  },
  BidTooltip: {
    id: `deal.module.sidebar.dealHealth.bidTooltip`,
    defaultMessage: "Possible reasons for no bids: {reasonList}",
  },
  BidTooltipReasonOne: {
    id: `deal.module.sidebar.dealHealth.bidTooltipReasonOne`,
    defaultMessage: "Not bidding above the floor.",
  },
  RemainingSpend: {
    id: `deal.module.remainingSpend`,
    defaultMessage: "Remaining Spend",
  },
  WinCpm: {
    id: `deal.module.winCPM`,
    defaultMessage: "Av. Win CPM",
  },
  BidCpm: {
    id: `deal.module.bidCPM`,
    defaultMessage: "Bid CPM (Min/Max)",
  },
  NoComposition: {
    id: `deal.module.sidebar.dealHealth.NoComposition`,
    defaultMessage:
      "Currently no composition data available. It can take up to 24 hours to show.",
  },
  OpenAuction: {
    id: `deal.module.sidebar.dealHealth.OpenAuction`,
    defaultMessage: "Open Auction",
  },
  ProgrammaticGuarantee: {
    id: `deal.module.sidebar.dealHealth.ProgrammaticGuarantee`,
    defaultMessage: "Programmatic Guarantee",
  },
  NonGuaranteed: {
    id: `deal.module.sidebar.dealHealth.NonGuaranteed`,
    defaultMessage: "Non Guaranteed",
  },
  ProgressBarTargetingInitialMessage: {
    id: "deal.module.sidebar.dealHealth.ProgressBarTargetingInitialMessage",
    defaultMessage:
      "Inventory details may take about 10 seconds. Please do not close this panel or switch tabs.",
  },
});

export default InventoryMessages;
