import React from "react";
import getIsProd from "core/shared/util/getIsProd";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

const isProd = getIsProd();

const GoogleAnalytics = () => {
  const history = useHistory();

  if (!isProd) return null;
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
  ReactGA.initialize("UA-20146284-3");
  ReactGA.pageview(window.location.pathname + window.location.search);

  return <React.Fragment />;
};

export default GoogleAnalytics;
