/* eslint-disable no-param-reassign */
import { create } from "zustand";
import { shallow } from "zustand/shallow";

export const useSideNavStore = create((set) => ({
  collapsed: true,
  theme: "light",
  acctSelectorCollapsed: true,
  campaignId: null,
  followCampaignId: null,
  setTheme: (theme) => {
    set((state) => ({ ...state, theme }));
  },
  toggleSideNav: () =>
    set((state) => ({
      collapsed: !state.collapsed,
      acctSelectorCollapsed: true,
    })),
  toggleAcctSel: () => {
    set((state) => ({
      acctSelectorCollapsed: !state.acctSelectorCollapsed,
      collapsed: true,
    }));
  },
  closeAcctSel: () => {
    set((state) => ({
      ...state,
      acctSelectorCollapsed: true,
    }));
  },
  setCampaignId: (campaignId) => {
    set((state) => ({
      ...state,
      campaignId,
    }));
  },
  setFollowCampaignId: (followCampaignId) => {
    set((state) => ({
      ...state,
      followCampaignId,
    }));
  },
}));

const acctSelector = (state) => ({
  acctSelectorCollapsed: state.acctSelectorCollapsed,
  toggleAcctSel: state.toggleAcctSel,
  closeAcctSel: state.closeAcctSel,
  campaignId: state.campaignId,
  setCampaignId: state.setCampaignId,
  followCampaignId: state.followCampaignId,
  setFollowCampaignId: state.setFollowCampaignId,
});

const sideSelectorAll = (state) => ({
  toggleSideNav: state.toggleSideNav,
  collapsed: state.collapsed,
  setTheme: state.setTheme,
  theme: state.theme,
});

export const useAcctSelector = () => {
  return useSideNavStore(acctSelector, shallow);
};

export const useSideNav = () => {
  return useSideNavStore(sideSelectorAll, shallow);
};

export default useSideNav;
