import Entities from "core/shared/util/entities";

export const entityIndexMap = {
  [Entities.ORGANIZATION]: 0,
  [Entities.AGENCY]: 1,
  [Entities.ADVERTISER]: 2,
};

/**
 * Is the entity with id $id selected?
 * @param id
 * @param columnKey
 * @param selectedOrg
 * @param selectedAgency
 * @param selectedAdvertiser
 * @returns {boolean}
 */
export function isEntitySelected({
  id,
  columnKey,
  selectedOrg,
  selectedAgency,
  selectedAdvertiser,
}) {
  const selectedEntityId = Number(id);
  const isOrg = columnKey === Entities.ORGANIZATION;
  const isAgy = columnKey === Entities.AGENCY;
  const isAdv = columnKey === Entities.ADVERTISER;
  const isSelectedOrg = isOrg && Number(selectedOrg) === selectedEntityId;
  const isSelectedAgy = isAgy && Number(selectedAgency) === selectedEntityId;
  const isSelectedAdv =
    isAdv && Number(selectedAdvertiser) === selectedEntityId;

  return isSelectedOrg || isSelectedAgy || isSelectedAdv;
}

/**
 * What is the name for entity key
 * @param entityIndex
 * @param valueMaps
 * @param key
 * @returns {string}
 */
export function getEntityName({ entityIndex, valueMaps, key }) {
  if (valueMaps[entityIndex] && valueMaps[entityIndex][key]) {
    return valueMaps[entityIndex][key].name || "";
  }
  return "";
}

/**
 * Get the sort method to sort alphabetically with selected items on top
 * @param override - object that contains potential overrides for the sort
 * e.g. supply {selectedAdvertiser, selectedAgency, selectedOrg} to bubble those to the top of the lists
 * @returns {function} sortMethod
 */
export const getSortMillerColumnBySelected = (override = {}) => ({
  columnKey,
  selectionMaps,
  valueMaps,
}) => {
  return (entityA, entityB) => {
    const propSelectedOrg = Object.keys(selectionMaps[0] || {})[0];
    const propSelectedAgency = Object.keys(selectionMaps[1] || {})[0];
    const propSelectedAdvertiser = Object.keys(selectionMaps[2] || {})[0];
    const entityIndex = entityIndexMap[columnKey];
    const nameA = getEntityName({ entityIndex, valueMaps, key: entityA });
    const nameB = getEntityName({ entityIndex, valueMaps, key: entityB });
    const selectedOrg =
      "selectedOrg" in override ? override.selectedOrg : propSelectedOrg;
    const selectedAgency =
      "selectedAgency" in override
        ? override.selectedAgency
        : propSelectedAgency;
    const selectedAdvertiser =
      "selectedAdvertiser" in override
        ? override.selectedAdvertiser
        : propSelectedAdvertiser;
    const entityASelected = isEntitySelected({
      selectedOrg,
      selectedAgency,
      selectedAdvertiser,
      columnKey,
      id: entityA,
    });
    const entityBSelected = isEntitySelected({
      selectedOrg,
      selectedAgency,
      selectedAdvertiser,
      columnKey,
      id: entityB,
    });

    if (entityASelected) {
      return -1;
    }
    if (entityBSelected) {
      return 1;
    }
    return nameA.localeCompare(nameB);
  };
};

/**
 * Get the sort method to sort alphabetically with checked items on top
 * @param override - object that contains potential overrides for the sort
 * e.g. supply {markMaps} to bubble those items to the top of the lists
 * @returns {function} sortMethod
 */
export const getSortMillerColumnByChecked = (override = {}) => ({
  columnKey,
  valueMaps,
  markMaps,
}) => {
  return (entityA, entityB) => {
    const usedMarkMaps = override.markMaps ? override.markMaps : markMaps;

    const entityIndex = entityIndexMap[columnKey];
    const nameA = getEntityName({ entityIndex, valueMaps, key: entityA });
    const nameB = getEntityName({ entityIndex, valueMaps, key: entityB });
    const isCheckedA = usedMarkMaps[entityIndex][entityA];
    const isCheckedB = usedMarkMaps[entityIndex][entityB];

    // If the IDs were contained in the original checked items, push them to the top
    if (isCheckedA && !isCheckedB) {
      return -1;
    }
    if (isCheckedB && !isCheckedA) {
      return 1;
    }

    return nameA.localeCompare(nameB);
  };
};
