import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

const getLinkProps = ({ history, match, staticContext, ...props }) => props;

const getToAsObj = ({ to = {}, location = {} }) => {
  const { pathname } = to;
  const { hash, search, state } = location;
  return {
    pathname: typeof to === "string" ? to : pathname,
    search,
    hash,
    state,
  };
};

const SmartLink = ({ children, to, location, ...rest }) => (
  <Link to={getToAsObj({ to, location })} {...getLinkProps(rest)}>
    {children}
  </Link>
);

SmartLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  location: PropTypes.shape({}),
};

SmartLink.defaultProps = {
  children: undefined,
  to: "",
  location: {},
};

export default withRouter(SmartLink);
