import { defineMessages } from "react-intl";
import i18nFormat from "core/shared/util/i18nFormat";

const EnterpriseControlsMessages = defineMessages({
  EnterpriseControlsHeader: {
    id: "enterprise_controls.module.new.header.name",
    defaultMessage: "Enterprise Controls",
  },
  GroupsTabName: {
    id: "GroupsTabName.Tab.Title",
    defaultMessage: "Entity Groups",
  },
  NewGroupButton: {
    id: "GroupsTab.Button.Name",
    defaultMessage: "New Group",
  },
  TabGroupDetails: {
    id: "GroupsTab.Tab.GroupDetails",
    defaultMessage: "Group Details",
  },
  TabCampaignSettings: {
    id: "GroupsTab.Tab.CampaignSettings",
    defaultMessage: "Campaign Settings",
  },
  TabStrategySettings: {
    id: "GroupsTab.Tab.StrategySettings",
    defaultMessage: "Strategy Settings",
  },
  TabCreativeSettings: {
    id: "GroupsTab.Tab.CreativeSettings",
    defaultMessage: "Creative Settings",
  },
  TabDealSettings: {
    id: "GroupsTab.Tab.DealSettings",
    defaultMessage: "Deal Settings",
  },
  TabSummary: {
    id: "GroupsTab.Tab.Summary",
    defaultMessage: "Summary",
  },
  BreadcrumbEnterpriseControls: {
    id: "CreateEntityGroup.Breadcrumb.EnterpriseControls",
    defaultMessage: "Enterprise Controls",
  },
  BreadcrumbGroups: {
    id: "CreateEntityGroup.Breadcrumb.Groups",
    defaultMessage: "Entity Groups",
  },
  BreadcrumbNewEntityGroup: {
    id: "CreateEntityGroup.Breadcrumb.NewEntityGroup",
    defaultMessage: "New Entity Group",
  },
  BreadcrumbEditEntityGroup: {
    id: "EditEntityGroup.Breadcrumb.EditEntityGroup",
    defaultMessage: "Edit Entity Group",
  },
  NewEntityGroupHeader: {
    id: "CreateEntityGroup.Header.Title",
    defaultMessage: "New Entity Group",
  },
  "groupName.label": {
    id: "CreateEntityGroup.groupName.label",
    defaultMessage: "Group Name",
  },
  NewEntityGroupNameInputRequiredValidation: {
    id: "CreateEntityGroup.GroupName.RequiredValidation",
    defaultMessage: "Group Name is required",
  },
  NewEntityGroupNameInputLengthValidation: {
    id: "CreateEntityGroup.GroupName.LengthValidation",
    defaultMessage: "Must be less than 255 characters",
  },
  NewEntityGroupNameInputLengthMillerValidation: {
    id: "CreateEntityGroup.GroupName.MillerColumnsValidation",
    defaultMessage:
      "You must select at least one organization, agency or advertiser",
  },
  "groupName.placeholder": {
    id: "CreateEntityGroup.GroupName.Placeholder",
    defaultMessage: "Enter group name",
  },
  "millerColumnsField.label": {
    id: "CreateEntityGroup.millerColumnsField.label",
    defaultMessage: "Select Entities",
  },
  GroupListEmpty: {
    id: "GroupList.DataTable.EmptyMsg",
    defaultMessage: "No Entity Groups Found",
  },
  GroupListError: {
    id: "GroupList.DataTable.ErrorMsg",
    defaultMessage: "Error retrieving entity groups",
  },
  GroupListName: {
    id: "GroupList.DataTable.Name",
    defaultMessage: "Group Name",
  },
  GroupListID: {
    id: "GroupList.DataTable.ID",
    defaultMessage: "Group ID",
  },
  GroupListNumOrgs: {
    id: "GroupList.DataTable.NumOrgs",
    defaultMessage: "Organizations",
  },
  GroupListNumAgs: {
    id: "GroupList.DataTable.NumAgs",
    defaultMessage: "Agencies",
  },
  GroupListNumAdvs: {
    id: "GroupList.DataTable.NumAdvs",
    defaultMessage: "Advertisers",
  },
  GroupListControls: {
    id: "GroupList.DataTable.Controls",
    defaultMessage: "Controls",
  },
  GroupListDeleteAction: {
    id: "GroupList.DataTable.DeleteAction",
    defaultMessage: "Delete",
  },
  GroupListEditAction: {
    id: "GroupList.DataTable.EditAction",
    defaultMessage: "Edit",
  },
  GroupListRemoveControlsAction: {
    id: "GroupList.DataTable.RemoveControlsAction",
    defaultMessage: "Remove Controls",
  },
  GroupListSuccessDeletion: {
    id: "GroupList.Notifier.Success",
    defaultMessage: "Entity Group Deleted",
  },
  GroupListSuccessAssignmentsRemoval: {
    id: "GroupList.Notifier.SuccessRemoval",
    defaultMessage: "Entity Group Assignments were removed",
  },
  ModalDeleteActionYes: {
    id: "GroupList.Modal.DeleteAction.Yes",
    defaultMessage: "Yes",
  },
  ModalDeleteActionNo: {
    id: "GroupList.Modal.DeleteAction.No",
    defaultMessage: "No",
  },
  ModalDeleteActionContent: {
    id: "GroupList.Modal.DeleteAction.Content",
    defaultMessage: "Are you sure you want to delete",
  },
  ModalRemoveAssignmentsActionContent: {
    id: "GroupList.Modal.RemoveAction.Content",
    defaultMessage:
      "Are you sure you want to permanently remove the Entity Group's controls?",
  },
  ModalDeleteActionHeader: {
    id: "GroupList.Modal.DeleteAction.Header",
    defaultMessage: "Delete Entity Group",
  },
  ModalRemoveActionHeader: {
    id: "GroupList.Modal.RemoveAction.Header",
    defaultMessage: "Remove Controls",
  },
  cancel: {
    id: "enterprise-controls.form.cancel",
    defaultMessage: "Cancel",
  },
  save: {
    id: "enterprise-controls.form.save",
    defaultMessage: "Save",
  },
  continue: {
    id: "enterprise-controls.form.continue",
    defaultMessage: "Continue",
  },
  "save.continue": {
    id: "enterprise-controls.form.save-continue",
    defaultMessage: "Save and Continue",
  },
  "valueType.fixed.label": {
    id: `enterprise-controls.valueType.fixed.label.name`,
    defaultMessage: "Fixed Value",
  },
  "valueType.default.label": {
    id: `enterprise-controls.valueType.default.label.name`,
    defaultMessage: "Default Value",
  },
  EcSettingsCreateSubmitSuccess: {
    id: "enterprise-controls.admin.settings.submit.success",
    defaultMessage: "The Entity Group was successfully created",
  },
  EcSettingsCreateSubmitFailure: {
    id: "enterprise-controls.admin.group.settings.submit.failure",
    defaultMessage: "The creation of the Entity Group was not successful",
  },
  EcSettingsEditSubmitSuccess: {
    id: "enterprise-controls.admin.group.settings.submit.success",
    defaultMessage: "The Entity Group was successfully updated",
  },
  EcSettingsEditSubmitFailure: {
    id: "enterprise-controls.admin.settings.submit.failure",
    defaultMessage: "The update to the Entity Group was not successful",
  },
  DoneButton: {
    id: "enterprise-controls.done.button",
    defaultMessage: "Done",
  },
});

i18nFormat(EnterpriseControlsMessages);

export default EnterpriseControlsMessages;
